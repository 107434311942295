import { getFunctions, httpsCallable } from 'firebase/functions';
import movieArgs from 'routes/app/utils/movieArgs.js';

const getMid = httpsCallable(getFunctions(), 'getMid');

export const getData = async(email)=>{
    try{
        const res = await getMid({email: email});
        movieArgs.data = res.data; // {uid, mid, token, transactionId}
        console.log(movieArgs.data.uid, movieArgs.data.mid);    
        movieArgs.data.email = email;
        return Promise.resolve(movieArgs.data.token);
    }
    catch(err){
        return Promise.reject(err);
    }
}

export const finish = (setView)=>{
    setView(1);
}