import { useEffect } from "react";
import { useLocation } from "react-router-dom";
const ScrollToTop = ({ children }) => {
    const location = useLocation();
    useEffect(() => {
        setTimeout(() => {
            document.documentElement.scrollTo(0, 0);
        }, 0);
    }, [location.pathname, location.search]);
    return children;
};
export default ScrollToTop