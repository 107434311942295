const movieArgs = {
    type: null,
    name: null,
    age: null,
    cake: null,
    prev: 0,
    head:{
        masterHeadSize:1200,
        image: null,
        eyes: null, 
        width: null,
        height: null,
        points: null,
        imageChanged: false
    },
    avatar:{
        avatars: null,
        colors: null,
        hair: null,
        hairColor: null,
        tops: null
    },
    invite:{
        day: null,
        month: null,
        hour: null,
        minute: null,
        ampm: null,
        adrsA: null,
        adrsB: null,
        comntA: null,
        comntB: null
    }
}

export default movieArgs;