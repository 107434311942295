import React from "react";
import { Link } from "react-router-dom";
import bg2 from "assets/images/footer-bg-mobile.webp";
import bg1 from "assets/images/footer-bg.webp";
import facebook from "assets/webp/footer-social/Facebook.webp";
import instagram from "assets/webp/footer-social/Instagram.webp";
import youtube from "assets/webp/footer-social/YouTube.webp";
import logo from "assets/webp/logo.webp";
import ContactModal from "components/ContactModal.jsx";
import LoginModal from "./LoginModal";
import WaveDivider2 from "./WaveDivider2";
import useScreen from "components/useScreen";

const Footer = () => {
	const [loginModalOpen, setLoginModalOpen] = React.useState(false);
	const [contactModalOpen, setContactModalOpen] = React.useState(false);
	const screen = useScreen();

	return (
		<footer
			className="relative pt-[100px] md:pt-[179px] xl:pt-[220px] pb-12"
			style={{
				background: `url(${
					screen >= 768 ? bg1 : bg2
				}) no-repeat bottom center / cover`,
			}}
		>
			<WaveDivider2 />
			<div className="container relative z-10">
				<div className="flex flex-wrap justify-between items-end gap-y-10">
					<div className="flex flex-col md:flex-row gap-[30px] lg:gap-[92px] justify-between md:items-center">
						<div className="block w-[129px] lg:w-[189px]">
							<img src={logo} alt="" />
						</div>
						<ul className="flex flex-col gap-4 lg:gap-5">
							{data.map((item, index) => (
								<li key={index}>
									<Link
										to={item.url}
										className="text-md md:text-[22px] leading-[36px] font-semibold text-white hover:text-blue-default uppercase tracking-[0.025em]"
										onClick={(e) => {
											if (item.name === "login") {
												e.preventDefault();
												setLoginModalOpen(true);
											} else if (item.name === "contact") {
												e.preventDefault();
												setContactModalOpen(true);
											}
										}}
									>
										{item.name}
									</Link>
								</li>
							))}
						</ul>
					</div>
					<div className="flex flex-col items-center justify-center text-center w-full md:w-auto gap-6">
						<ul className="flex justify-center flex-wrap gap-3">
							{social.map((item, index) => (
								<li key={index}>
									<Link
										to={item.url}
										className="flex w-[50px] lg:w-[56px]"
										target="_blank"
									>
										<img
											src={item.img}
											className="max-w-full"
											alt=""
										/>
									</Link>
								</li>
							))}
						</ul>
						<div className="text-md md:text-[22px] leading-[36px] font-semibold text-white">
							TOON KIDS ™
						</div>
					</div>
				</div>
			</div>
			{loginModalOpen && <LoginModal setOpen={setLoginModalOpen} />}
			{contactModalOpen && <ContactModal setOpen={setContactModalOpen} />}
		</footer>
	);
};
const data = [
	{
		name: "contact",
	},
	{
		name: "login",
	},
	{
		name: "terms of use",
		url: "/terms-of-use",
	},
	{
		name: "privacy policy",
		url: "/privacy",
	},
	{
		name: "Refund Policy",
		url: "/refund",
	},
];
const social = [
	{
		img: facebook,
		url: "https://www.facebook.com/toon.kids.official/",
	},
	{
		img: instagram,
		url: "https://www.instagram.com/toon.kids.official/",
	},
	{
		img: youtube,
		url: "https://www.youtube.com/@toon.kids.official",
	},
];
export default Footer;
