const Chosen = ({email, setChosen}) => {
  return (
    <div className="text-center">
        <h1 className="text-[30px] leading-[43px] sm:text-4xl md:text-6xl md:text-[40px] mx-auto sm:max-w-[700px]">
            Your email is:     
        </h1>
        <div className="text-xl md:text-2xl font-poppins mb-10">{email}</div>
        <button className="btn" onClick={()=>{setChosen(false)}}>
            Change email
        </button>
    </div>
  )
}

export default Chosen