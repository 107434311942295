import { useEffect, useState, useRef } from "react";
import Normal from "./maskComps/Normal";
import Overlay from "./maskComps/Overlay";

const MakeMask = ({ finalImg, selectedFace, setNextDisabled, setBackDisabled, setError }) => {    
    const [editMode, setEditMode] = useState(false);
    const resetFunctionRef = useRef(null);

    useEffect(() => {
        setError('Press the Save button first.');
        setNextDisabled(editMode);
        setBackDisabled(editMode);
    }, [editMode, setNextDisabled, setBackDisabled, setError]);

    return (
        <div className="text-center mb-10">
            {editMode?<Overlay finalImg={finalImg} selectedFace={selectedFace} editMode={editMode} setEditMode={setEditMode} resetFunctionRef={resetFunctionRef}/>:<Normal finalImg={finalImg} selectedFace={selectedFace} editMode={editMode} setEditMode={setEditMode}/>}
        </div>
    );
}

export default MakeMask;
