const init = async()=>{
    const loadImage = (path)=>{
        return new Promise((resolve,reject)=>{
            const img  = new Image();
            img.onload = ()=>{
                resolve(img)
            }
            img.onerror = (err)=>{
                reject(err);
            }
            img.src = path;
        })
    }
    const parseImage = async(path)=>{
        const img4src = (src)=>{
            return new Promise((resolve,reject)=>{
                const img  = new Image();
                img.onload = ()=>{
                    resolve(img)
                }
                img.onerror = (err)=>{
                    console.log(err);
                    reject(err);
                }
                img.src = src;
            })
        }
        const images = [];
        const img = await loadImage(path);
        const size = img.height;
        canvas.width = canvas.height = size;
        const ctx = canvas.getContext('2d');
        ctx.resetTransform();
        for (let i = 0; i < (img.width/size); i++) {
            ctx.clearRect(0,0,size,size);
            ctx.drawImage(img,i*size,0,size,size,0,0,size,size);
            images.push( await img4src( canvas.toDataURL() ) );
        }
        globals.count++;
        return Promise.resolve(images);
    }
    const globals = {}
    const canvas = document.createElement('canvas');
    globals.count = 0;
    const images = {}
    const prefix = "/images/editor";
    //skin
    images.skin = await parseImage(prefix+"/general/skin.png");
    //ears
    images.earsL = await parseImage(prefix+"/general/earsL.png");
    images.earsR = await parseImage(prefix+"/general/earsR.png");
    //tops
    images.top = await parseImage(prefix+"/general/tops.png");
    //hair
    images.hair = {}
    images.hair.colors = await parseImage(prefix+"/hair/colors.png");
    images.hair.head = await loadImage(prefix+"/hair/head.png");
    images.hair.head.style.width = "100%";
    images.hair.head.style.position = "absolute";
    images.hair.head.style.zIndex = "1";
    images.hair.back = [];
    images.hair.front = [];
    for (var i = 1; i < 5; i++){
        images.hair.back.push( await parseImage(prefix+"/hair/back/"+i+".png") );
        images.hair.front.push( await parseImage(prefix+"/hair/front/"+i+".png") );
    }
    //avatars
    const types = [
        "fairytale",
        "fantasy",
        "jungle"
    ]
    images.clothes = {}
    for (let index = 0; index < types.length; index++) {
        const type = types[index];
        images.clothes[type] = [];
        for (var i = 1; i < 7; i++) images.clothes[type].push( await parseImage(prefix+"/"+type+"/"+i+".png") );
    }
    // assign images
    globals.images = images;    
    console.log("Avatar images loaded.");
    return Promise.resolve(globals);
}

export default init;