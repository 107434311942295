import React from "react";
import Layout from "routes/website/components/Layout";

const TermsOfUse = () => {
	return (
		<Layout>
			<div className="pb-9 md:pb-[108px] pt-[161px] lg:pt-[223px]">
				<div className="container">
					<h1 className="text-center text-[42px] leading-[1.3] sm:text-6xl lg:text-7xl mb-[67px] lg:mb-[90px]">
						Terms of Use
					</h1>
					<div className="md:text-[22px] md:leading-[33px] max-w-[843px] mx-auto">
						<ul className="flex flex-col gap-5 md:gap-[30px]">
							<li>
								<h3 className="text-[30px] leading-[1.3] md:text-[38px] md:leading-[48px] mb-5 md:mb-[30px]">
									Introduction
								</h3>
								<p className="mb-6">
									Welcome to Toon Kids! The use of our website, ToonKids.com (the
									"Website" or the "Service"), is governed by these Terms of Use
									(the "Terms") and our Privacy Policy. By using the Website,
									you agree to comply with these Terms, so please read them
									carefully. If you disagree with any part of the Terms, please
									do not use our Service.
								</p>
                                <p className="mb-6">
									By accessing or using our Service, you consent to be bound by
									these Terms. If you do not agree with any part of these Terms,
									please refrain from using the Website and its services.
								</p>
							</li>

							<li>
								<h3 className="text-[30px] leading-[1.3] md:text-[38px] md:leading-[48px] mb-5 md:mb-[30px]">
									Eligibility
								</h3>
								<p className="mb-6">
									You must be legally qualified to use our Website. By using the
									Service, you confirm that you are either a legal adult or have
									the permission of a legal guardian.
								</p>
							</li>

							<li>
								<h3 className="text-[30px] leading-[1.3] md:text-[38px] md:leading-[48px] mb-5 md:mb-[30px]">
									Purpose of the Terms
								</h3>
								<p className="mb-6">
									The Terms outline the relationship between TOON KIDS LLC ("Toon
									Kids" or the "Operator") and anyone accessing or using the
									Website. If you do not comply with any of the Terms, we reserve
									the right to terminate your access to the Service.
								</p>
							</li>

							<li>
								<h3 className="text-[30px] leading-[1.3] md:text-[38px] md:leading-[48px] mb-5 md:mb-[30px]">
									Key Points of Use
								</h3>
								<ul className="flex flex-col gap-5 md:gap-[30px] text-grey pl-5">
									<li className="flex gap-2">
										<span className="w-2 h-2 bg-current rounded mr-1 translate-y-3"></span>
										<span className="w-0 flex-grow">
											<strong>Customized Content:</strong> As a user, you are
											responsible for ensuring the accuracy of all data (e.g.,
											names, photos) you provide during customization. Please
											double-check your inputs to ensure the final animation
											matches your intent.
										</span>
									</li>
									<li className="flex gap-2">
										<span className="w-2 h-2 bg-current rounded mr-1 translate-y-3"></span>
										<span className="w-0 flex-grow">
											<strong>Non-Commercial Use:</strong> The videos you create
											on Toon Kids are intended for personal, non-commercial use.
											If you want to use a video for commercial purposes, please
											contact us for permission.
										</span>
									</li>
									<li className="flex gap-2">
										<span className="w-2 h-2 bg-current rounded mr-1 translate-y-3"></span>
										<span className="w-0 flex-grow">
											<strong>Refunds:</strong> Our refund policy allows refunds
											within 30 days of purchase under specific conditions. Please
											see our Refund Policy for details.
										</span>
									</li>
								</ul>
							</li>

							<li>
								<h3 className="text-[30px] leading-[1.3] md:text-[38px] md:leading-[48px] mb-5 md:mb-[30px]">
									Video Access and Privacy
								</h3>
								<p className="mb-6">
									All videos created on Toon Kids are <strong>public but
									unlisted</strong>. This means that anyone with the direct link
									can access the video, but it is not searchable or publicly
									visible on the platform. Please be cautious when sharing the
									link, as anyone with access to it will be able to view the
									video. Toon Kids is not responsible for any unauthorized
									viewing of videos shared via direct links.
								</p>
							</li>

							<li>
								<h3 className="text-[30px] leading-[1.3] md:text-[38px] md:leading-[48px] mb-5 md:mb-[30px]">
									Data Protection and Privacy
								</h3>
								<p className="mb-6">
									Your privacy is important to us, and we are committed to
									handling your personal data in line with GDPR and other
									applicable laws. You are always in control of your information
									and may withdraw consent at any time. For details on how we
									handle your data, please refer to our Privacy Policy.
								</p>
							</li>

							<li>
								<h3 className="text-[30px] leading-[1.3] md:text-[38px] md:leading-[48px] mb-5 md:mb-[30px]">
									Limitation of Liability
								</h3>
								<p className="mb-6">
									Toon Kids provides tools to help you create personalized
									videos. While we strive to ensure a smooth experience, certain
									factors—such as internet connectivity or external disruptions—
									may cause delays or issues outside our control. Toon Kids is
									not liable for any indirect damages resulting from using the
									Website, and you agree to verify the final video before sharing
									it with others.
								</p>
							</li>

							<li>
								<h3 className="text-[30px] leading-[1.3] md:text-[38px] md:leading-[48px] mb-5 md:mb-[30px]">
									Account Security
								</h3>
								<p className="mb-6">
									Please keep your account details secure. Toon Kids is not
									responsible for any unauthorized use of your account due to
									sharing or accidental disclosure of your login credentials.
								</p>
							</li>

							<li>
								<h3 className="text-[30px] leading-[1.3] md:text-[38px] md:leading-[48px] mb-5 md:mb-[30px]">
									Intellectual Property and License to Use Videos
								</h3>
								<p className="mb-6">
									All content on the Website, including designs, animations, and
									text, is protected by copyright and intellectual property
									laws. Toon Kids retains all rights to the Website’s content.
									You may not reproduce, distribute, or commercially exploit any
									part of the Website or videos created on it without prior
									written consent from Toon Kids.
								</p>
							</li>

							<li>
								<h3 className="text-[30px] leading-[1.3] md:text-[38px] md:leading-[48px] mb-5 md:mb-[30px]">
									Links to Third-Party Websites
								</h3>
								<p className="mb-6">
									Toon Kids may contain links to third-party sites not governed
									by these Terms. We are not responsible for the content, privacy
									practices, or terms of these external sites. Please review
									their respective policies.
								</p>
							</li>

							<li>
								<h3 className="text-[30px] leading-[1.3] md:text-[38px] md:leading-[48px] mb-5 md:mb-[30px]">
									Refund Policy Summary
								</h3>
								<p className="mb-6">
									Refund requests are eligible within 30 days of purchase. After
									30 days, refunds may be granted in cases where the service was
									not delivered as described or had significant issues. See our
									Refund Policy for more information.
								</p>
							</li>

							<li>
								<h3 className="text-[30px] leading-[1.3] md:text-[38px] md:leading-[48px] mb-5 md:mb-[30px]">
									Minors' Use
								</h3>
								<p className="mb-6">
									Toon Kids does not knowingly collect personal information from
									children under 18 without the consent of a parent or legal
									guardian. If we learn we have collected information from a
									minor without permission, we will promptly delete it.
								</p>
							</li>

							<li>
								<h3 className="text-[30px] leading-[1.3] md:text-[38px] md:leading-[48px] mb-5 md:mb-[30px]">
									Accessibility Commitment
								</h3>
								<p className="mb-6">
									We are committed to making our Website accessible to all users,
									including those with disabilities. If you have any feedback or
									suggestions for improving accessibility, please reach out to us
									at contact@toonkids.com.
								</p>
							</li>

							<li>
								<h3 className="text-[30px] leading-[1.3] md:text-[38px] md:leading-[48px] mb-5 md:mb-[30px]">
									Amendments and “Last Updated” Notice
								</h3>
								<p className="mb-6">
									Toon Kids may amend these Terms at any time, with changes
									effective immediately upon posting. We encourage you to check
									for updates. The latest update to these Terms was on
									<strong> November 2, 2024</strong>.
								</p>
							</li>

							<li>
								<h3 className="text-[30px] leading-[1.3] md:text-[38px] md:leading-[48px] mb-5 md:mb-[30px]">
									Contact Us
								</h3>
								<p className="mb-6">
									If you have questions or need assistance, contact us at
									support@toonkids.com. We value your feedback and strive to
									make every interaction with Toon Kids a positive one.
								</p>
							</li>
						</ul>
					</div>
					<h2 className="text-center text-[42px] leading-[1.3] sm:text-6xl mt-[61px] lg:mt-[108px]">
						End of Terms of Use
					</h2>
				</div>
			</div>
		</Layout>
	);
};

export default TermsOfUse;
