import { useState } from "react";
import ProgressBar from "components/ProgressBar";
import Gnomes from "components/Gnomes";
import Lottie from 'lottie-react';
import animationData from 'assets/lottie/anim.json';
import WaveText from "./WaveText";

const Loader = ()=>{
    return <div className="w-20 h-20 border-[8px] border-white border-t-transparent rounded-full animate-spin"/>
}

const Count = ()=>{
    return(
        <>
            <Gnomes width={'50%'}/>
            <div className="w-[50%] mt-5">
                <ProgressBar duration={40} style={'dark'}/>
            </div>
        </>
    )
}

const Wait = () => {
    const [loader, setLoader] = useState(false);
    return (
        <div className="relative w-[700px] max-w-[95vw]">
                <div className="flex flex-row justify-center items-center mb-5">
                    <WaveText/>
                    <div className="ml-5 flex justify-center items-center w-20 h-20 overflow-hidden">
                        <Lottie
                            animationData={animationData}
                            loop={true}
                            style={{ 
                                width: 300,
                                height: 300,
                                position: "absolute"
                            }}
                        />
                    </div> 
                </div>
                
                <div className="p-[100px] w-full rounded-3xl aspect-[1920/1080] bg-blue-default border border-blue-default overflow-hidden flex justify-center items-center">
                    <div className="absolute flex flex-col justify-center items-center w-full">
                        {loader?<Loader/>:<Count setLoader={setLoader}/>}
                    </div> 
                </div>
        </div>
    )
}

export default Wait