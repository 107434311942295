import React from 'react';
import logo from 'assets/webp/logo.webp';

const Wait = () => {
  return (
    <>
    <style>{`
            @keyframes growShrink {
            0%, 100% {
                transform: scale(1);
            }
            50% {
                transform: scale(1.2);
            }
            }

            @keyframes shadowDepth {
            0%, 100% {
                filter: drop-shadow(0 10px 20px rgba(0, 0, 0, 0.2));
            }
            50% {
                filter: drop-shadow(0 20px 40px rgba(0, 0, 0, 0.3));
            }
            }
    `}</style>
    <div className="z-[100] fixed top-0 left-0 min-w-[100vw] flex flex-col justify-center items-center min-h-screen bg-sky-400">
        <img
            src={logo}
            alt="Logo"
            className="w-48 animate-pulse"
            style={{
              animation: 'growShrink 2s ease-in-out infinite, shadowDepth 2s ease-in-out infinite',
            }}
        />
        {/* <div className="mt-10 w-20 h-20 border-[10px] border-white border-t-transparent rounded-full animate-spin"></div> */}
    </div>
    </>
    
  );
};

export default Wait;
