import utils from "utils/utils"
import Movie from "routes/website/components/Movie"
import Footer from "routes/website/components/Footer";
import additionalPageBg from "assets/webp/additional-page-bg.webp";
import shape1 from "assets/webp/banner/shape1.webp";
import shape3 from "assets/webp/banner/shape3.webp";
import logo from "assets/webp/logo.webp";

const MoviePage = () => {
    const uid = utils.getParam('uid');
    const mid = utils.getParam('mid');

    return (
		<>
			<section
				className="relative pt-[66px] lg:pt-[70px] pb-[200px] md:pb-[230px] overflow-hidden"
				style={{
					background: `url(${additionalPageBg}) no-repeat bottom center / cover`,
				}}
			>
				<div className="flex flex-col justify-center items-center">
					<a
						href="/" target='_blank'
						className="block mx-auto w-[121px] md:w-[200px] mb-[54px] grow"
					>
						<img src={logo} className="w-full" alt="" />
					</a>
					<Movie uid={uid} mid={mid}/>
				</div>
				<img
					src={shape1}
					className="absolute top-0 left-0 md:left-6 w-[126px] lg:w-[497px]"
					alt=""
				/>
				<img
					src={shape3}
					className="absolute top-0 right-0 md:-right-1 w-[105px] lg:w-[272px]"
					alt=""
				/>
			</section>
			<Footer />
		</>
	)
}

export default MoviePage