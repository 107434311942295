import { useEffect, useRef } from "react";
import { cn } from "utils/cn";

const VideoPlayer = ({ open, setOpen, url, poster }) => {
    const videoRef = useRef()
    
    useEffect(()=>{
        if(open) videoRef.current.play()
        else videoRef.current.pause()
    },[open])

	return (
		<div
			className={cn(
				"fixed top-0 left-0 w-full h-full flex justify-center items-center p-6 z-[1000]",
				{
					"opacity-0 invisible": !open,
				}
			)}
		>
			<div
				className="absolute inset-0 z-10 bg-white bg-opacity-80"
				onClick={() => {
					setOpen(false);
				}}
			></div>
			<div
				className={cn(
					"w-full max-w-[826px] bg-white z-[11] scale-90 duration-300 rounded-[40px]",
					{
						"scale-100": open,
					}
				)}
			>
				<div className="rounded-[40px] overflow-hidden video">
                    <video
                        ref={videoRef}
                        poster={poster}
                        src={url}
                        controls
                        width="826"
						height="574"
                        preload="auto"
                    />
				</div>
			</div>
		</div>
	);
};

export default VideoPlayer;
