import {useRef, useState} from "react";
import { getFunctions, httpsCallable } from 'firebase/functions';
import newsletter1 from "assets/webp/newsletter1.webp";
import newsletter2 from "assets/webp/newsletter2.webp";
import newsletter3 from "assets/webp/newsletter3.webp";
import newsletter4 from "assets/webp/newsletter4.webp";
import Loader from "components/Loader";
import Error from "components/Error";

const Form = ({setSent})=>{
    const initSub = httpsCallable(getFunctions(), 'initSub');
    const emailRef = useRef();
    const [wait, setWait] = useState();
    const [err, setErr] = useState();

    const handleSubmit = async(e) => {
		e.preventDefault();
        setWait(true);
        setErr(null)
        try{
            await initSub({email: emailRef.current.value});
            setSent(true);
        }
        catch(err){
            setErr(err.message)
        }
        setWait(false);
	}
    
    return(
        <form onSubmit={handleSubmit} className="pt-5">
            <div className="flex flex-col md:flex-row justify-center items-center relative max-w-[783px] mx-auto sm:border-b border-[#676767] pb-6">
                <input
                    ref={emailRef}
                    type="email"
                    name='email'
                    placeholder="Enter your email address"
                    className="text-[14px] sm:text-[18px] placeholder:uppercase text-grey h-[60px] sm:h-[55px] w-full sm:pr-[210px] border-0 outline-none shadow-none border-b border-[#676767] bg-transparent sm:border-0"
                    required
                    disabled={wait}
                />
                <div className="relative w-full flex justify-center items-center mt-5 md:mt-0">
                    <button
                        type="submit"
                        className="btn h-[59px] w-full max-md:text-md"
                        disabled={wait}
                    >
                        Subscribe
                    </button>
                    {wait && <div className="absolute"><Loader/></div>}
                </div>
            </div>
            <Error err={err}/>
        </form>
    )
}
const Sent = ()=>{
    return(
        <div className="w-full text-center font-jua text-3xl">
            Thank you for joining the family! ❤️
        </div>
    )
}

const Newsletter = () => {
    const [sent, setSent] = useState();

	return (
		<section className="pt-[100px] md:pt-[67px] pb-20 sm:pb-10 md:pb-[153px] relative overflow-hidden z-10">
			<img
				src={newsletter1}
				alt=""
				className="absolute -top-8 left-0 max-w-1/2 hidden md:block"
			/>
			<img
				src={newsletter2}
				alt=""
				className="absolute right-0 -top-4 max-w-1/2 hidden md:block"
			/>
			<img
				src={newsletter3}
				alt=""
				className="absolute -top-3 left-0 max-w-1/2 md:hidden"
			/>
			<img
				src={newsletter4}
				alt=""
				className="absolute right-0 top-[160px] max-w-1/2 md:hidden"
			/>
			<div className="container relative z-[20]">
				<h2 className={(sent?'mb-5':'mb-[120px] lg:mb-[90px]')+" text-[8.85vw] leading-[1.26] sm:text-6xl lg:text-[56px] sm:leading-[65px] max-sm:max-w-[300px] mx-auto text-center"}>
                    Join the family! <br />
                    Subscribe to our newsletter :)
				</h2>
                {sent?<Sent/>:<Form setSent={setSent}/> }
			</div>
            <div className="flex justify-center mt-16 lg:mt-20">
                <a href="/birthday-movie-maker" target='_self'
                    className="btn uppercase btn-grow text-xl w-[90vw] max-w-[500px] text-center"
                >
                    Let's go!
                </a>
            </div>
		</section>
	);
};

export default Newsletter;
