import React from "react";
import { Link } from "react-router-dom";
import blueBalloon from "assets/webp/anime/blue-balloon.webp";
import shape1 from "assets/webp/banner/shape1.webp";
import shape3 from "assets/webp/banner/shape3.webp";

const PricingSection = () => {
	return (
		<>
			<section className="pt-[161px] lg:pt-[223px] overflow-hidden relative pb-[157px] lg:pb-[212px]">
				<div className="container relative z-10">
					<div className="max-w-[892px] px-6 mx-auto text-center pb-[55px] md:pb-[73px]">
						<h1 className="text-[42px] max-w-[480px] mx-auto sm:max-w-full leading-[1.3] sm:text-6xl lg:text-7xl underline">
							Pricing
						</h1>
					</div>
				</div>
				<div className="container relative z-10">
					<div className="grid grid-cols-1 xl:grid-cols-2 gap-4">
						{pricing.map((item, index) => (
							<div
								key={index}
								className="bg-white rounded-2xl lg:rounded-[40px] border border-blue-default pt-7 pb-12 sm:pb-7 px-8"
							>
								<div className="flex flex-col items-center sm:items-stretch sm:flex-row mb-11 md:mb-[76px]">
									<div className="w-[213px] md:w-[260px] xl:w-[213px] pb-6 sm:border-b-0 border-b border-dark text-center sm:text-start">
										<span className="uppercase font-jua text-lg leading-[35px] pt-1 rounded-xl border-2 border-dark inline-block min-w-[119px] text-center">
											{item.name}
										</span>
										<h4 className="mt-8 lg:mt-12 text-[40px] leading-[68px] sm:text-[60px] xl:text-[80px] xl:tracking-[-0.06em]">
											{item.price}
										</h4>
									</div>
									<div className="text-center sm:text-start flex-grow sm:w-0 sm:border-l sm:border-dark sm:pl-6 md:pl-10 xl:pl-6 sm:text-lg xl:text-[22px] xl:leading-[33px] pt-8 sm:pt-0">
										<div
											className="max-w-[336px] sm:max-w-full mb-5"
											dangerouslySetInnerHTML={{ __html: item.text }}
										></div>
										<div>{item.text2}</div>
									</div>
								</div>
								<a href="/birthday-movie-maker" target='_self'
									className="btn h-[56px] w-full"
								>
									START NOW
								</a>
							</div>
						))}
					</div>
				</div>
				<div className="container relative z-10 pt-[65px] lg:pt-[100px]">
					<div className="max-w-[892px] px-6 mx-auto text-center pb-[55px] md:pb-[73px] relative z-10">
						<h1 className="underline text-[42px] max-w-[480px] mx-auto sm:max-w-full leading-[1.3] sm:text-6xl lg:text-7xl">
							Details:
						</h1>
					</div>
					<div className="bg-white rounded-2xl lg:rounded-[40px] border border-blue-default py-10 md:py-14 md:px-8 text-center md:text-start sm:text-[22px] sm:leading-[33px]">
						<div className="grid grid-cols-1 md:grid-cols-2 gap-y-10">
							<div className="pb-10 md:pb-0 border-b md:border-b-0 md:border-r md:border-primary px-10">
								<div className="mb-10 md:mb-15">
									You can use our movie maker for free! Yes, you heard
									that right. No hidden costs, no strings attached.
									It's on the house!
								</div>
								<div>
									We know you're skeptical, so we've got your back. You
									can watch a short preview of the movie for free to
									make sure it's everything you've ever dreamed of. We
									don't want you to waste your hard-earned money on
									something that doesn't give you all the warm fuzzies.
								</div>
							</div>
							<div className="px-10">
								<div className="mb-8">
									If you decide that you're ready to take the plunge
									and unlock the full magic of your movie, it's only a
									small fee of $4.99. You'll get access to the full
									movie and all the download and share options that
									come with it.
								</div>
								<div className="mb-8">
									it's a pay-per-movie model. There are no
									subscriptions or recurring charges!
								</div>
								<div>
									Just in case you're not satisfied, we offer a full
									refund policy within 30 days of your purchase. No
									questions asked!
								</div>
							</div>
						</div>
					</div>
					<div className="pt-[117px] lg:pt-[125px]">
						<h2 className="m-0 text-[38px] leading-[48px] sm:text-6xl lg:text-[56px] lg:leading-[65px] text-center mx-auto max-w-[850px]">
							So, what are you waiting for? Let's make some movie magic!
						</h2>
						<div className="flex justify-center mt-12 lg:mt-16">
							<a href="/birthday-movie-maker" target='_self'
								className="btn uppercase w-full sm:max-w-[277px] xl:h-[70px] h-[60px]"
							>
								Start Now
							</a>
						</div>
					</div>
					<img
						src={blueBalloon}
						alt=""
						className="blue-balloon hidden lg:block absolute top-0 left-0 pointer-events-none"
					/>
				</div>
				<img
					src={shape1}
					className="absolute top-8 md:top-2 left-0 md:left-6 w-[170px] lg:w-[497px]"
					alt=""
				/>
				<img
					src={shape3}
					className="absolute top-[84px] lg:top-[128px] -right-16 md:-right-1 w-[175px] lg:w-[272px]"
					alt=""
				/>
			</section>
		</>
	);
};

const pricing = [
	{
		name: "Free",
		price: "$0.00",
		text: `Free Personalized Kids Birthday Party Invitation with Name, <br class="sm:hidden" /> Face & Age!`,
		text2: "Preview movie for Free",
	},
	{
		name: "Paid",
		price: "$4.99",
		text: "Full Movie for only $4.99 with Download & Share options.",
		text2: "30-Day Money Back Guarantee.",
	},
];

export default PricingSection;
