import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "assets/webp/logo.webp";
import ContactModal from "components/ContactModal";
import { cn } from "utils/cn";
import { errorToast } from "utils/toasts";
import editorUtils from 'routes/app/utils/editorUtils'
import movieArgs from "../utils/movieArgs";
import shape1 from "assets/webp/banner/shape1.webp";

let menu, stepsUrl;

const getSteps = (invite)=>{
    const baseUrl = invite?'/birthday-party-invitation-maker':'/birthday-movie-maker'
    const stepsUrl = [
        `${baseUrl}`,
        `${baseUrl}/choose-a-story`,
        `${baseUrl}/add-a-face`,
        `${baseUrl}/choose-an-avatar`,
        `${baseUrl}/name-age`,
        `${baseUrl}/preview`
    ]
    return stepsUrl;
}
const getMenu = (invite)=>{
    const menu = [
        {
            name: invite?'Choose a card':"Choose a story",
            link: stepsUrl[1],
        },
        {
            name: "Add a Face",
            link: stepsUrl[2]+"?view=5",
        },
        {
            name: "Choose an avatar",
            link: stepsUrl[3],
        },
        {
            name: invite?'Party Details':"Name & Age",
            link: stepsUrl[4],
        },
        {
            name: invite?'Download':"Preview",
            link: stepsUrl[5],
        },
        {
            name: invite?'Get a matching movie!':"Get Full Movie",
            link: null,
        },
    ]
    return menu;
}
const DesktopMenuItem = ({step, index, item})=>{
    const navigate = useNavigate();
    return(
        <div
           
            className={cn("nav-link flex-grow text-center", {
                "active cursor-pointer": step > index,
            })}
            onClick={
                (step > index && item.link) ? () => navigate(item.link) : null
            }
        >
            {item.name}
        </div>
    )
}
const MobileMenuItem = ({step, index})=>{
    return(
        <div
            className={cn(
                "h-1 w-1 bg-[#a8a8a8] rounded-full z-10",
                {
                    "bg-secondaryBg": step > index,
                }
            )}
        />
    )
}

const MovieMakerLayout = ({
	step,
    nextDisabled,
    backDisabled,
	handleNext,
    handleBack,
    vidprev,
    error,
	children,
}) => {
    const invite = movieArgs.invite;
    stepsUrl = getSteps(invite);
    menu = getMenu(invite);
    
	const [contactModalOpen, setContactModal] = useState(false);
	const navigate = useNavigate();

	const doNext = () => {
        if(nextDisabled) errorToast(error);
        else if(handleNext) handleNext();
        else if(step===5) navigate( editorUtils.getCheckoutQuery() )
        else navigate(stepsUrl[step+1]);
	}
    const doBack = () => {        
        if(backDisabled){
            if(error) errorToast(error);
            return true;
        }
        else if(handleBack) handleBack();
        else if(step===6) navigate( stepsUrl[5]+"?view=1" );
        else if(vidprev) navigate( stepsUrl[5]+"?view=0" );
        else navigate(stepsUrl[step-1]);
	}

	return (
		<main className="relative flex md:py-10 md:px-5 gap-6 min-h-[100dvh]">            
            {/* main */}
			<div className="relative flex-grow flex flex-col z-10">
                {/* header */}
                <div className="mb-20 md:mb-0">
                    {/* desktop */}
                    <div className="hidden md:flex fixed w-[100vw] bg-white z-[50] pt-5 pb-7 px-5 shadow-lg top-0 left-0 justify-evenly items-center text-base xl:text-md 2xl:text-lg font-medium">
                        <a href="/" target='_blank' className="w-16 mr-5">
                            <img src={logo} className="w-full" width={66} alt="" />
                        </a>
                        <nav className="w-full flex justify-evenly text-base xl:text-md 2xl:text-lg font-medium">
                            {menu.map((item, index) => <DesktopMenuItem  key={index} step={step} index={index} item={item}/>)}
                        </nav>
                    </div>

                    {/* mobile */}
                    <div className="md:hidden fixed top-0 left-0 bg-white z-[40] p-5 w-full shadow-lg">
                        <div className="flex flex-row justify-between items-center px-2 mb-2">
                            <a href="/" target='_blank'>
                                <img src={logo} className="w-[60px]" alt="logo" />
                            </a>
                            <div className="text-lg">
                                {step>0?menu[step-1].name:'Welcome!'}
                            </div>
                        </div> 
                        <div className="h-3 bg-[#E0E0E0] sm:mx-2 rounded-full flex justify-between items-center px-4 relative">
                            <div
                                className={cn(
                                    "absolute bg-blue-default rounded-full left-0 h-full max-w-full"
                                )}
                                style={{ width: `${(step / 6) * 100}%` }}
                            />
                            {menu.map((item, index) => <MobileMenuItem key={index} step={step} index={index}/>)}
                        </div>
                    </div>
                </div>
                
                {/* children */}
				<div className="w-[95%] mt-[50px] md:mt-[100px] mb-40 max-w-[1000px] m-auto">
                    {children}
                </div>

                {/* footer */}
				<div className="w-full md:px-20 lg:px-60 fixed bottom-0 left-0 flex flex-col-reverse justify-center items-center md:flex-row md:justify-between py-[20px] lg:pb-5 bg-white shadow-up z-50">
					<div className="text-sm leading-[1.43] md:text-md lg:text-md text-[#979797]">
						Have a problem or suggestion?{" "}
						<button
							type="button"
							onClick={() => setContactModal(true)}
							className="underline cursor-pointer"
						>
							Contact Us!
						</button>
						{contactModalOpen && <ContactModal setOpen={setContactModal}/>}
					</div>
					<div className="flex flex-row gap-20 md:gap-3 mb-2 md:mb-0">
						<button
							className={cn("btn font-medium", {
                                "back-btn disabled": backDisabled,
                            })}
							type="button"
							onClick={doBack}
						>
							Back
						</button>
						<button
							className={cn("btn font-medium", {
								"back-btn disabled": nextDisabled,
							})}
							type="button"
							onClick={doNext}
						>
									Next
						</button>
					</div>
				</div>          
			</div>
            {/* images */}
            <div className="w-full fixed top-24 md:top-10 left-0 flex flex-row justify-between opacity-20">
                <img
                    src={shape1}
                    className="w-[180px] md:w-[497px]"
                    alt="confetti_left"
                />
                <img
                    src={shape1}
                    className="w-[180px] md:w-[497px] -scale-x-100"
                    alt="confetti_right"
                />
            </div>
		</main>
	)
};

export default MovieMakerLayout;
