import movieArgs from "routes/app/utils/movieArgs";
import Button from "./Button";
const Overlay = ({over, data, sets, setOver, globals})=>{
    const type = over;
    const Buttons = ()=>{
        let images = globals.images[type];
        if(type=='clothes') images = globals.images[type][movieArgs.type][data.skin];
        else if(type=='hair') images = globals.images.hair.front[ data.hairColor ];
        return(
            <div className="flex flex-col justify-center items-center">
                {type=='hair' &&
                    <div className="flex flex-row flex-wrap justify-center items-center">
                        {globals.images.hair.colors.map((img, index)=>
                            <Button globals={globals} key={index} type={'hairColor'} number={index} data={data} action={()=>{sets['hairColor'](index)}}/>
                        )}
                    </div>
                }
                <div className="flex flex-row flex-wrap justify-center items-center">
                    {images.map((image, index)=>
                        <Button globals={globals} key={index} type={type} number={index} data={data} action={()=>{sets[type](index)}}/>
                    )}         
                </div>
            </div>
        )
    }
    return(
        <div className="z-30 absolute flex flex-col justify-center items-center w-full h-full bg-white rounded-[inherit] bg-opacity-10 backdrop-blur-[3px]"
            onClick={()=>{setOver(false)}}
        >
            <Buttons/>
        </div>
    )
}
export default Overlay;