import Dots from "components/Dots";
import Lottie from 'lottie-react';
import avocado from 'assets/lottie/avocado.json';
import brocoli from 'assets/lottie/brocoli.json';
import orange from 'assets/lottie/orange.json';

const Wait = ()=>{
    return(
        <div className="w-full flex flex-col justify-center items-center">
            <div className="flex flex-row">
                <h1 className="text-[30px] leading-[43px] sm:text-4xl md:text-6xl md:text-[40px] mx-auto sm:max-w-[700px]">
                    Connecting<Dots/>
                </h1>
            </div>
            <div className="flex">
                <div className="flex flex-col justify-center items-center overflow-hidden w-[100px] h-[100px]">
                    <Lottie animationData={avocado} loop={true} style={{width: 150}}/>
                </div>
                <div className="flex flex-col justify-center items-center overflow-hidden w-[100px] h-[100px]">
                    <Lottie animationData={orange} loop={true} style={{width: 150}} className="transform translate-y-3"/>
                </div>
                <div className="flex flex-col justify-center items-center overflow-hidden w-[100px] h-[100px]">
                    <Lottie animationData={brocoli} loop={true} style={{width: 150}} className="transform translate-y-1"/>
                </div> 
            </div>
            {/* <Lottie animationData={cloud} loop={true} style={{width: 150}}/> */}
        </div>      
    )
}

export default Wait