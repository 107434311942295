import rotIcon from 'assets/images/edit/rotate.webp';
import scaleIcon from 'assets/images/edit/scale.webp';

const RangeStyle = ({type}) => {
  return (
    <style>{`
        .icon-${type}::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 32px;
            height: 32px;
            background-color: #fff;
            cursor: pointer;
            border-radius: 50%;
            background: url(${type==1?rotIcon:scaleIcon}) white no-repeat center center / 18px;
            border: 1px solid #3975e3;
        }
        .icon-${type}::-moz-range-thumb {
            width: 32px;
            height: 32px;
            background-color: #fff;
            cursor: pointer;
            border-radius: 50%;
            background: url(${type==1?rotIcon:scaleIcon}) white no-repeat center center / 18px;
            border: 1px solid #3975e3;
        }
        @media (min-width: 768px){
            .icon-${type}::-webkit-slider-thumb {
                width: 50px;
                height: 50px;
                background-size: 28px;
            }
            .icon-${type}::-moz-range-thumb {
                width: 50px;
                height: 50px;
                background-size: 28px;
            }
        }
      `}</style>
  )
}

export default RangeStyle