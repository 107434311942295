import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Logo from './comps/Logo';
import Title from './comps/Title';
import Message from './comps/Message';
import Movie from "routes/website/components/Movie.jsx";
import Footer from "routes/website/components/Footer";

import additionalBg from "assets/webp/additional-page-bg.webp";
import shape1 from "assets/webp/banner/shape1.webp";
import shape3 from "assets/webp/banner/shape3.webp";

const Congrats = () => {
    const [waitState, setWaitState] = useState(true)
    const nav = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const uid = queryParams.get('uid');
    const mid = queryParams.get('mid');
    const email = queryParams.get('email');
    
    useEffect(()=>{
        if(!uid || !mid || !email) nav('/');
    },[])

	return (
		<>
			<div
				className="w-full relative flex flex-col justify-center items-center pt-20 pb-40"
				style={{
					background: `url(${additionalBg}) no-repeat bottom center / cover`,
				}}
			>
                <Logo/>
                <Title wait={waitState}/>
                {waitState && <Message email={email}/>}
                <Movie uid={uid} mid={mid} setWaitState={setWaitState}/>
				<img
					src={shape1}
					className="absolute top-0 left-0 md:left-6 w-[126px] lg:w-[497px]"
					alt=""
				/>
				<img
					src={shape3}
					className="absolute top-0 right-0 md:-right-1 w-[105px] lg:w-[272px]"
					alt=""
				/>
			</div>
			<Footer />
		</>
	)
};

export default Congrats;
