import React from "react";
import { cn } from "utils/cn";

const Input = ({
	className,
	textarea,
	value,
	options,
	placeholder,
	iconImg,
	type,
    refT,
	...rest
}) => {
	if (options) {
		return (
			<select
				className={cn(
					"bg-white rounded-full border border-blue-default shadow-lg w-full h-[50px] md:h-[63px] outline-none  px-5 md:px-9 form-select",
					{
						[className]: className,
					}
				)}
				{...rest}
                defaultValue={value}
                required
			>
				<option value={placeholder} disabled>
					{placeholder}
				</option>
				{options.map((option, index) => (
					<option key={index} value={option}>
						{option}
					</option>
				))}
			</select>
		);
	}
	if (textarea) {
		return (
			<textarea
				className={cn(
					"bg-white rounded-[20px] border border-blue-default shadow-lg w-full h-[180px] md:h-[200px] outline-none p-5 md:p-9",
					{
						[className]: className,
					}
				)}
				{...rest}
				placeholder={placeholder}
                defaultValue={value}
                required
			/>
		);
	}
	return (
		<label className="relative block m-0">
			<input
				className={cn(
					"bg-white rounded-full border border-blue-default shadow-lg w-full h-[50px] md:h-[63px] outline-none  px-5 md:px-9",
					{
						[className]: className,
						"pr-16": iconImg,
					}
				)}
				value={value}
				placeholder={placeholder}
				{...rest}
				type={type || "text"}
                required
                ref={refT}
			/>
			{iconImg && (
				<img
					src={iconImg}
					alt=""
					className="absolute right-5 top-1/2 transform -translate-y-1/2 max-sm:h-8"
				/>
			)}
		</label>
	);
};

export default Input;
