import Trailer from "./Trailer";
import bannerBg from "assets/webp/banner/banner-bg.webp";
import banner from "assets/webp/banner/banner.webp";
import shape1 from "assets/webp/banner/shape1.webp";
import shape2 from "assets/webp/banner/shape2.webp";
import shape3 from "assets/webp/banner/shape3.webp";

const Banner = () => {
	return (
		<>
			<section
				className="pt-[161px] lg:pt-[223px] overflow-hidden relative"
				style={{
					background: `url(${bannerBg}) no-repeat center center / cover`,
				}}
			>
				<div className="container max-w-[1200px] relative z-10">
					<div className="max-w-[782px] mx-auto text-center pb-5">
						<h1 className="text-[10.5vw] sm:text-6xl lg:text-7xl mb-[25px] md:mb-9">
							Personalized Animated Birthday eCards for Kids
						</h1>
						<h2 className="font-poppins text-md md:text-2xl mb-[25px] md:mb-9 max-sm:max-w-[338px] max-sm:mx-auto">
                            Easily Create a Custom Birthday Movie with Toon Kids' Simple and Fun Animated eCard Maker!
						</h2>
                        <a href="/birthday-movie-maker" target='_self' className="btn-grow btn px-10 py-5 text-lg md:text-xl">
                                Make Your Child The Star!
						</a>
					</div>
                    <div className="w-[95%] max-w-[700px] m-auto relative -bottom-6">
                        <Trailer/>
                    </div>
					<img
						src={banner}
						className="w-full min-h-[425px] rounded-t-[24px] md:rounded-[40px] object-cover"
						alt=""
					/>
				</div>
				<img
					src={shape1}
					className="absolute top-8 md:top-2 left-0 md:left-6 w-[170px] lg:w-[497px]"
					alt=""
				/>
				<img
					src={shape2}
					className="absolute top-[135px] lg:top-[254px] -left-7 lg:left-[110px] max-lg:w-[90px]"
					alt=""
				/>
				<img
					src={shape3}
					className="absolute top-[84px] lg:top-[128px] -right-16 md:-right-1 w-[175px] lg:w-[272px]"
					alt=""
				/>
			</section>
		</>
	);
};

export default Banner;
