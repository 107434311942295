import playImg from 'assets/images/play.svg';

const Poster = ({setPlay, thumbUrl})=>{
    return(
        <div className="absolute flex flex-col justify-center items-center w-full rounded-[inherit]">
            <img src={thumbUrl} className="z-10 w-full rounded-[inherit]"/>
            <img src={playImg} className="z-20 pointer-events-auto w-[20%] absolute cursor-pointer transform transition-transform duration-300 hover:scale-105"
                    onClick={()=>{setPlay(true)}}
            />
        </div>
    )
}
export default Poster