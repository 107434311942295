import Header from "./Header";
import EditControls from "./EditControls";
import MaskEditor from "./MaskEditor";

const Overlay = ({finalImg, selectedFace, editMode, setEditMode, resetFunctionRef})=>{
    return(
        <div className="fixed inset-0 bg-black bg-opacity-50 z-[100] overflow-auto flex justify-center items-start p-4">
            <div className="relative p-10 rounded-3xl bg-white border border-blue-default shadow-lg max-w-3xl w-full flex flex-col items-center">
                <Header editMode={editMode} />
                <div className="mb-4">
                    <EditControls
                        editMode={editMode}
                        setEditMode={setEditMode}
                        resetFunctionRef={resetFunctionRef}
                    />
                </div>
                <MaskEditor
                    resetFunctionRef={resetFunctionRef}
                    finalImg={finalImg}
                    selectedFace={selectedFace}
                    editMode={editMode}
                    setEditMode={setEditMode}
                />
            </div>
        </div>
    )
}

export default Overlay