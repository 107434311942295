import Mov from "./Mov";

const Movies = ({movies, uid})=>{
    return(
        <div className="w-full flex flex-row justify-center items-center flex-wrap">
            {movies.map((mid, index)=><Mov key={index} uid={uid} mid={mid}/>)}
        </div>
    )
}

export default Movies