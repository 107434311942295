import { Routes, Route, Navigate } from "react-router-dom";
import About from "./pages/about/About";
import Thanks from "./pages/thanks/Thanks";
import Congrats from "./pages/congrats/Congrats";
import Blog from "./pages/blog/Blog";
import BlogPost from "./pages/blog/BlogPost";
import Faqs from "./pages/faq/Faqs";
import Home from "./pages/home/Home";
import Invitations from "./pages/invitations/Invitations";
import Pricing from "./pages/pricing/Pricing";
import MyMovies from "./pages/myMovies/MyMovies";
import MoviePage from "./pages/movie/MoviePage";

import Privacy from "./pages/legal/Privacy";
import Refund from "./pages/legal/Refund";
import TermsOfUse from "./pages/legal/TermsOfUse";
import CookiePolicy from "./pages/legal/CookiePolicy";


const websiteRoutes = ()=>{
    return(
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/invitations" element={<Invitations />} />
            <Route path="/faqs" element={<Faqs />} />  
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:slug" element={<BlogPost />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/thanks" element={<Thanks />} />
            <Route path="/congrats" element={<Congrats />} />
            <Route path="/my-movies" element={<MyMovies />} />
            <Route path="/movie" element={<MoviePage />} />
            <Route path="/share" element={<MoviePage />} />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/refund" element={<Refund />} />
            <Route path="/cookies" element={<CookiePolicy />} />
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    )
}

export default websiteRoutes