import movieArgs from 'routes/app/utils/movieArgs.js';
import {getData, finish} from '../utils/authUtils.jsx'
import { getAuth, signInWithCustomToken } from "firebase/auth";

const Email = ({email, setEmail, setWait, setView, setErr}) => {
    
    const handleSubmit = async(evt)=>{
        if(movieArgs.data && email==movieArgs.data.email) finish(setView);
        else{
            setWait(true);
            try{
                evt.preventDefault();
                const token = await getData(email);
                await signInWithCustomToken(getAuth(), token);
                finish(setView);
            }
            catch(err){
                setErr(err.message)
                setWait(false);
            }
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="flex flex-wrap gap-6">
                <input
                    type="email"
                    name="email"
                    className="w-full border bg-white rounded-full sm:w-0 flex-grow shadow-shadow outline-none border-blue-default px-5 md:px-9 md:text-xl h-[59px] lg:h-[82px]"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <button
                    type="submit"
                    className="btn h-[59px] lg:h-[82px] w-full sm:w-[197px] text-md md:text-xl"
                    style={{
                        fontWeight:'400'
                    }}
                >
                    Submit
                </button>
            </div>
        </form>
    )
}

export default Email