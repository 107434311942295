import {useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import zebra from "assets/webp/anime/zebra.webp";
import Layout from "routes/website/components/Layout";
import PostContent from "./components/PostContent";
import BlogsBottom from "./components/BlogsBottom";
import ReadNext from "./components/ReadNext";
import posts from "./posts";

const BlogPost = () => {
    const { slug } = useParams();
    const [data, setData] = useState();
    const [curSlug, setCurSlug] = useState(slug)

    useEffect(() => {
		posts.map((post) => post.slug === slug && setData(post));
        setCurSlug(slug);
	}, [slug]);

	return (
		<Layout>
			<div className="relative pb-[150px] sm:pb-[50px]">
				<PostContent data={data}/>
                <ReadNext slug={curSlug}/>
				<BlogsBottom />
				<div className="absolute bottom-0 lg:-bottom-12 overflow-hidden left-0 w-full h-[366px] z-[11]">
					<img
						src={zebra}
						alt=""
						className="absolute right-0 bottom-0 z-10 w-[133px] lg:w-auto"
					/>
				</div>
			</div>
		</Layout>
	);
};

export default BlogPost;
