import { useState } from "react";
import Config from "utils/Config";
import utils from "utils/utils";
import editorUtils from "routes/app/utils/editorUtils";
import movieArgs from "routes/app/utils/movieArgs";
import Paddle from "./Paddle";
import Promo from "./Promo";
import Loader from "components/Loader";
import MakePrev from "./MakePrev";
import Total from "./Total";

const Inner = ({wait, uid, mid, email, transactionId, setBackDisabled}) => {
    const invite = movieArgs.invite;
    const [showPromo, setShowPromo] = useState(false);
    const src = Config.storageDomain+'/users/'+uid+'/movies/'+mid+'/assets/thumb.jpg?ver='+movieArgs.prev;
    return (
        <div>
            <div className="w-[95%] max-w-[624px] mx-auto">
                <div className="p-5 md:p-10 rounded-[40px] border border-blue-default text-center relative w-full max-w-[624px] mx-auto my-5 bg-white">
                    <h1 className="text-center mb-5 text-[30px] leading-[43px] sm:text-4xl md:text-6xl md:text-[40px] mx-auto sm:max-w-[700px]">
                        {invite?'Matching Personalized Animated Movie':'Full 02:30 Minutes HQ Movie'}
                    </h1>
                <hr className="my-5"/>
                <ul className="font-jua ml-5 list-disc flex flex-col items-start text-xl md:text-2xl leading-[33px] gap-2">
                    <li className="text-start">Only <span className="text-2xl md:text-3xl">$4.99</span> For a Limited Time!</li>
                    <li className="text-start">30-Day Money Back Guarantee!</li>
                </ul>
                </div>
                <img
                    src={src}
                    className="w-full rounded-[40px] shadow-shadow mb-9 border border-blue-default"
                    alt=""
                />
                {(!utils.getVidPrevMade() || editorUtils.isSolo) && <MakePrev/>}
                <div className="shadow-shadow rounded-[40px] border border-blue-default bg-white p-10">
                    <Total/>
                    <hr className="my-5"/>
                    {wait?
                        <Loader/>:
                        <>
                            {!showPromo && <Paddle uid={uid} mid={mid} email={email} transactionId={transactionId} setBackDisabled={setBackDisabled}/>}
                            <Promo showPromo={showPromo} setShowPromo={setShowPromo} uid={uid} mid={mid} email={email}/>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default Inner