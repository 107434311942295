import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import stepShape1 from "assets/webp/steps/step-shape1.webp";
import stepShape2 from "assets/webp/steps/step-shape2.webp";
import cloud1 from "assets/webp/steps/cloud1.webp";
import cloud2 from "assets/webp/steps/cloud2.webp";
import cloud3 from "assets/webp/steps/cloud3.webp";
import stepHeaderBg2 from "assets/webp/steps/step-header-bg-2.webp";
import stepHeaderBgColor from "assets/webp/steps/step-header-bg-color.webp";
import stepHeaderBg from "assets/webp/steps/step-header-bg.webp";
import Description from './Description';

const Steps = () => {
	return (
		<section className="relative z-10">
			<div
				className="relative translate-y-[-40px] md:translate-y-[-82px] z-[111]"
				style={{
					filter: "drop-shadow(5px 18px 30px rgba(0, 0, 0, 0.08))",
				}}
			>
				<div className="absolute top-0 right-0 h-full w-full z-10">
					<div
						className="absolute h-full w-full left-0 -top-2 sm:top-0 sm:hidden"
						style={{
							WebkitMask: `url(${stepHeaderBg2}) no-repeat center center / 100% 100%`,
							background: `url(${stepHeaderBgColor}) no-repeat center center / cover`,
						}}
					></div>
					<div
						className="absolute h-full w-full left-0 top-0 hidden sm:block"
						style={{
							WebkitMask: `url(${stepHeaderBg}) no-repeat center center / 100% 100%`,
							background: `url(${stepHeaderBgColor}) no-repeat center center / cover`,
						}}
					></div>
					<img
						src={cloud2}
						className="absolute right-0 top-[40%] -translate-y-1/2 hidden sm:block"
						alt=""
					/>
					<img
						src={cloud3}
						className="absolute right-0 top-[40%] -translate-y-1/2 sm:hidden"
						alt=""
					/>
				</div>
				<img
					src={cloud1}
					className="absolute left-0 top-[60%] -translate-y-1/2 max-w-[40%]"
					alt=""
				/>
				<Description/>
			</div>
            <div className='relative text-center mb-10'>
                <div className='relative z-10'>
                    <h2 className="mb-[18px] text-[38px] leading-[48px] sm:text-6xl lg:text-[56px] lg:leading-[65px]">
                        Want to Make Your Kid's Special Day Totally Unforgettable?
                    </h2>
                    <p className="text-lg md:text-2xl lg:text-[30px] lg:leading-[68px] max-sm:max-w-[287px] mx-auto">
                        We’ve got you covered with just 3 easy steps!
                    </p>
                </div>      
                <img
                    src={stepShape1}
                    alt="confetti-1"
                    className="absolute left-0 sm:top-[-165px] top-[-128px] max-sm:max-w-[160px]"
                />
                <img
                    src={stepShape2}
                    alt="confetti-2"
                    className="absolute hidden sm:block sm:right-0 top-[-100px] sm:top-[-140px] max-sm:max-w-[189px]"
                />
            </div>
			<Step1/>
			<Step2/>
			<Step3/>
		</section>
	);
};

export default Steps;
