const ImageSelected = ({imgSrc}) => {
  return (
    <>
        <img src={imgSrc} className="w-full " alt="Uploaded face" />
        <div className="absolute lef-0 top-0 w-full h-full z-10 pointer-events-none rounded-[42px] bg-blue-950 bg-opacity-25">
            <div className="flex justify-center items-end p-8">
                <div className="bg-white border border-danger-400 text-danger-400 rounded-full px-5 py-[6px]">
                    Select new picture
                </div>
            </div>
        </div>
    </>
  )
}

export default ImageSelected