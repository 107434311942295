import React from 'react'

const Total = () => {
  return (
    <div className="w-full mx-auto p-4 bg-white rounded">
        {/* Item Description and Price */}
        <div className="flex justify-between items-center mb-2">
            <span className="text-gray-700">1 Personalized Birthday Animation:</span>
            <span className="font-semibold text-gray-900">$4.99</span>
        </div>

        {/* Total Price */}
        <div className="flex justify-between items-center border-t pt-2">
            <span className="text-gray-800 font-semibold">Total to pay:</span>
            <span className="font-bold text-green-600">$4.99</span>
        </div>
    </div>
  )
}

export default Total