import React from "react";
import mobile from "assets/images/divider/mobile-divider-1.webp";
import web from "assets/images/divider/wave-divider-1.webp";
import useScreen from "components/useScreen";
const WaveDivider1 = () => {
	const screen = useScreen();
	return (
		<div className="absolute top-full -translate-y-1/2 md:-translate-y-1/4 left-0 w-full overflow-hidden">
			<img
				src={screen >= 500 ? web : mobile}
				className="min-w-full"
				alt=""
			/>
		</div>
	);
};

export default WaveDivider1;
