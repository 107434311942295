import React from "react";
import aboutBanner from "assets/images/about-banner-bg.webp";
import aboutBannerMobile from "assets/images/about-banner-mobile.webp";
import WaveDivider1 from "routes/website/components/WaveDivider1.jsx";
import useScreen from "components/useScreen";
const BlogsBanner = () => {
	const screen = useScreen();
	return (
		<section
			className="pb-9 md:pb-[108px] pt-[130px] sm:pt-[263px] min-h-[450px] sm:min-h-[540px] xl:min-h-[580px] relative"
			style={{
				background: `url(${
					screen >= 640 ? aboutBanner : aboutBannerMobile
				}) no-repeat center center / cover`,
			}}
		>
			<div className="relative flex flex-col justify-center items-center">
				<div className="relative text-center z-10">
					<h1 className="text-[10.5vw] sm:text-6xl lg:text-7xl mb-[25px]">
						Toon Kids <br className="sm:hidden" /> Birthday Blog
					</h1>
					<p className="text-md md:text-2xl max-sm:max-w-[338px] max-sm:mx-auto">
						Dive into Magical Tales, Tips, and Party Ideas!
					</p>
				</div>
                <div className="w-[70%] h-[200%] absolute bg-white blur-2xl opacity-80"/>
			</div>
			<WaveDivider1 />
		</section>
	);
};

export default BlogsBanner;
