import {useEffect, useState} from 'react'

const Dots = () => {
    const [dotCount, setDotCount] = useState(0);
    
    useEffect(()=>{
        const interval = setInterval(() => {
            setDotCount((prevCount) => (prevCount + 1) % 4);
        }, 500);
        return () => {
            clearInterval(interval);
        };
    },[])
    
    return (
        <span style={{ display: 'inline-block', width: '1.5em', textAlign: 'left' }}>
            {'.'.repeat(dotCount)}
        </span>
    )
}

export default Dots