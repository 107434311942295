import { useState, useEffect, useRef } from "react";
import playIcon from "assets/images/play.svg";
import poster from "assets/images/home/trailer.webp";
import videoUrl from "assets/videos/trailer.mp4";

const Trailer = () => {
    const vidRef = useRef()
    const [play, setPlay] = useState(false);
    useEffect(()=>{
        if(play) vidRef.current.play()
        else vidRef.current.pause()
    },[play])

    return (
        <div className="cursor-pointer w-full max-w-[838px] relative z-10 mx-auto shadow-shadow overflow-hidden rounded-[40px] video-player">
            <div className={` ${play ? "visible" : "invisible"}`}>
                <video
                    ref={vidRef}
                    src={videoUrl}
                    width="100%"
                    controls
                    preload="auto"
                    poster={poster}
                />
            </div>
            {!play && (
                <>
                    <div
                        className="group absolute inset-0 flex justify-center items-center cursor-pointer z-10 rounded-[inherit] border border-blue-default"
                        style={{
                            background: `url(${poster}) no-repeat center center / cover`,
                        }}
                        onClick={() => setPlay(true)}
                    >
                        <img src={playIcon} className="group-hover:scale-[110%] w-[20%] transform transition-transform duration-300" alt="play" />
                    </div>
                </>
            )}
        </div>
    )
}

export default Trailer