import React from "react";
import Layout from "routes/website/components/Layout";
import AboutBanner from "./components/AboutBanner";
import AboutText from "./components/AboutText";
import webBg from "assets/images/faqs/faq-bg.webp";

const About = () => {
	return (
		<Layout>
			<div className="overflow-hidden relative z-10" style={{
                background: `url('${webBg}') no-repeat center`,
                backgroundSize: 'cover'
            }}>
				{/* <AboutBanner /> */}
				<AboutText />
			</div>
		</Layout>
	);
};

export default About;
