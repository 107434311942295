import { GoogleOAuthProvider } from '@react-oauth/google';
import utils from 'utils/utils';
import keys from 'utils/keys';

import Title from "./Title";
import Email from "./Email";
import Google from "./Google";

const clientId = (utils.isDev() || utils.isLocal())?keys.google.dev:keys.google.prod;

const Login = ({email, setEmail, setWait, setView, setErr}) => {
  return (
    <div className="w-full max-w-[700px] mx-auto">
        <Title/>
        <Email email={email} setEmail={setEmail} setWait={setWait} setView={setView} setErr={setErr}/>
        <GoogleOAuthProvider clientId={clientId}>
            <Google setWait={setWait} setView={setView} setErr={setErr}/>
        </GoogleOAuthProvider>
    </div>
  )
}

export default Login