import { useEffect } from "react";
import { Link } from "react-router-dom"
import Config from "utils/Config";
import movieArgs from "routes/app/utils/movieArgs";
import editorUtils from 'routes/app/utils/editorUtils'

const Taste = ({setBackDis, setNextDis}) => {
    useEffect(()=>{
        setBackDis(false);
        setNextDis(false);
    },[])
    
    const src = Config.storageDomain+'/users/'+movieArgs.data.uid+'/movies/'+movieArgs.data.mid+'/assets/thumb.jpg?ver='+movieArgs.prev;
    
    return(
        <div>
            <div className="text-center">
                <h1 className="text-[30px] leading-[43px] sm:text-4xl md:text-6xl md:text-[40px] mx-auto sm:max-w-[700px]">
                    Here’s a little taste!
                </h1>
                <p className="sm:text-md lg:text-xl max-w-[793px] mx-auto">
                    To make changes click the "Back" button.
                </p>
            </div>
            <div className="max-w-[624px] mx-auto my-5">
                <div className="flex flex-wrap justify-center items-center gap-1 md:gap-5">
                    <Link
                        className="text-center download-btn flex-grow w-[180px] transform transition-transform duration-300 hover:scale-105"
                        to={editorUtils.getCheckoutQuery()}
                    >
                        Get Full 02:15 Movie! ($4.99)
                    </Link>
                    <Link
                        className="text-center download-btn success flex-grow w-[180px] transform transition-transform duration-300 hover:scale-105"
                        to="/birthday-movie-maker/short-preview"
                    >
                        Get Preview 00:05 Movie! (Free)
                    </Link>
                </div>
                <img
                    src={src}
                    className="w-full rounded-[40px] shadow-shadow mt-9 border border-blue-default"
                    alt=""
                />
            </div>
        </div>
    )
}

export default Taste