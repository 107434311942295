const Title = ()=>{
    return(
        <>
            <h1 className="text-center text-[30px] leading-[43px] sm:text-4xl md:text-6xl md:text-[40px] mx-auto sm:max-w-[700px]">
                Last Step!
            </h1>
            <p className="text-center sm:text-md lg:text-xl mx-auto mb-10">
                Enter your email to continue.
                <br />
                You will need access to this email to receive the movie.
            </p>
        </>        
    )
}
export default Title;