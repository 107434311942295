import React from "react";
import Layout from "routes/website/components/Layout";

const Refund = () => {
	return (
		<Layout>
			<div className="pb-9 md:pb-[108px] pt-[161px] lg:pt-[223px]">
				<div className="container">
					<h1 className="text-center text-[42px] leading-[1.3] sm:text-6xl lg:text-7xl mb-[30px]">
						Toon Kids Refund Policy
					</h1>
					<div className="md:text-[22px] md:leading-[33px] max-w-[843px] mx-auto">
						<ul className="flex flex-col gap-5 md:gap-[30px]">

							<li>
								<p className="mb-6">
									At Toon Kids, we are committed to providing a delightful and
									personalized experience for your child’s special day. We
									understand that circumstances may arise where you may need to
									request a refund. Our refund policy is designed to be clear
									and fair, ensuring customer satisfaction while maintaining the
									quality of our service.
								</p>
							</li>

							<li>
								<h3 className="text-[30px] leading-[1.3] md:text-[38px] md:leading-[48px] mb-5 md:mb-[30px]">
									Refund Policy
								</h3>
								<h5 className="text-[24px] leading-[1.3] md:text-[34px] md:leading-[42px] mb-4">
									Eligibility for Refunds
								</h5>
								<ul className="flex flex-col gap-5 md:gap-[30px] text-grey pl-5">
									<li className="flex flex-wrap gap-2">
										<span className="w-2 h-2 bg-current rounded mr-1 translate-y-3"></span>
										<span className="w-0 flex-grow">
											Refunds requested within 30 days from the time of purchase
											are automatically granted.
										</span>
									</li>
									<li className="flex flex-wrap gap-2">
										<span className="w-2 h-2 bg-current rounded mr-1 translate-y-3"></span>
										<span className="w-0 flex-grow">
											Refunds requested after that period will be considered if
											the service was not delivered as described or if there were
											significant issues with the animation quality or
											customization.
										</span>
									</li>
								</ul>
							</li>

							<li>
								<h5 className="text-[24px] leading-[1.3] md:text-[34px] md:leading-[42px] mb-4">
									Non-Refundable Cases (for claims made after 30 days from the
									time of purchase)
								</h5>
								<ul className="flex flex-col gap-5 md:gap-[30px] text-grey pl-5">
									<li className="flex flex-wrap gap-2">
										<span className="w-2 h-2 bg-current rounded mr-1 translate-y-3"></span>
										<span className="w-0 flex-grow">
											Customized animations that have been successfully created
											and delivered as per the order specifications.
										</span>
									</li>
									<li className="flex flex-wrap gap-2">
										<span className="w-2 h-2 bg-current rounded mr-1 translate-y-3"></span>
										<span className="w-0 flex-grow">
											Change of mind after the animation has been delivered.
										</span>
									</li>
									<li className="flex flex-wrap gap-2">
										<span className="w-2 h-2 bg-current rounded mr-1 translate-y-3"></span>
										<span className="w-0 flex-grow">
											Issues caused by incorrect information provided by the user
											during the customization process.
										</span>
									</li>
								</ul>
							</li>

							<li>
								<h5 className="text-[24px] leading-[1.3] md:text-[34px] md:leading-[42px] mb-4">
									How to Request a Refund
								</h5>
								<p className="mb-6">
									To request a refund, please contact our customer support team
									at <a href="mailto:support@toonkids.com">support@toonkids.com</a> with your order details,
									the reason for the refund request, and any supporting evidence
									if applicable. Our team will review your request and respond
									within 3-5 business days.
								</p>
							</li>

							<li>
								<h3 className="text-[30px] leading-[1.3] md:text-[38px] md:leading-[48px] mb-5 md:mb-[30px]">
									Refund Process
								</h3>
								<ul className="flex flex-col gap-5 md:gap-[30px] text-grey pl-5">
									<li className="flex flex-wrap gap-2">
										<span className="w-2 h-2 bg-current rounded mr-1 translate-y-3"></span>
										<span className="w-0 flex-grow">
											If your refund request is approved, the refund will be
											processed, and a credit will automatically be applied to
											your original method of payment within 10 business days.
										</span>
									</li>
									<li className="flex flex-wrap gap-2">
										<span className="w-2 h-2 bg-current rounded mr-1 translate-y-3"></span>
										<span className="w-0 flex-grow">
											You will receive an email notification once the refund has
											been processed.
										</span>
									</li>
								</ul>
							</li>

							<li>
								<h5 className="text-[24px] leading-[1.3] md:text-[34px] md:leading-[42px] mb-4">
									Partial Refunds
								</h5>
								<p className="mb-6">
									In some cases, only partial refunds may be granted (if
									applicable), such as for services that were partially
									delivered.
								</p>
							</li>

							<li>
								<h5 className="text-[24px] leading-[1.3] md:text-[34px] md:leading-[42px] mb-4">
									Contact Us
								</h5>
								<p className="mb-6">
									If you have any questions about our refund policy, please
									contact us at <a href="mailto:support@toonkids.com">support@toonkids.com</a>.
								</p>
							</li>

							<li>
								<p className="mb-6">
									We value your satisfaction and are here to ensure your
									experience with Toon Kids is a positive one. Thank you for
									choosing our platform to create memorable birthday animations
									for your children.
								</p>
							</li>
							
						</ul>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default Refund;
