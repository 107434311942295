import {useState} from 'react'
import Config from 'utils/Config';
import Overlay from "./Overlay";
import playImg from 'assets/images/play.svg';

const Mov = ({uid, mid})=>{
    const [showOverlay, setShowOverlay] = useState()
    const thumbUrl = Config.storageDomain+'/users/'+uid+'/movies/'+mid+'/thumb.jpg';
    const handleClick = ()=>{
        setShowOverlay(true);
    }
    return(
        <>
            <div className="relative flex justify-center items-center m-2 w-[400px] max-w-[95%] border border-blue-default rounded-2xl" onClick={handleClick}>
                <img src={playImg} className="z-20 pointer-events-auto w-[20%] absolute cursor-pointer transform transition-transform duration-300 hover:scale-105"/>
                <img src={thumbUrl} className="w-full rounded-[inherit]"/>   
            </div>
            {showOverlay && <Overlay uid={uid} mid={mid} setShowOverlay={setShowOverlay}/>}
        </>  
    )
}

export default Mov