import React from "react";
import webBg from "assets/images/invitation/invitation-bg.webp";
import mobileBg from "assets/images/invitation/invitation-mobile-bg.webp";
import Layout from "routes/website/components/Layout";
import useScreen from "components/useScreen";
import InvitationsBanner from "./components/InvitationsBanner";
const Invitations = () => {
	const screen = useScreen();
	return (
		<Layout>
			<div
				style={{
					background: `url('${
						screen >= 640 ? webBg : mobileBg
					}') no-repeat bottom center / cover`,
				}}
			>
				<InvitationsBanner />
			</div>
		</Layout>
	);
};

export default Invitations;
