import React from "react";
import { useLocation } from "react-router-dom";
import web from "assets/images/divider/footer-wave-divider-2.webp";
import mobile from "assets/images/divider/mobile-divider-1.webp";
import mobile2 from "assets/images/wave2-mobile2.webp";
import { cn } from "utils/cn";
import useScreen from "components/useScreen";
const WaveDivider2 = () => {
	const screen = useScreen();
	const location = useLocation();

	return (
		<div
			className={cn(
				"absolute bottom-full translate-y-[55%] sm:translate-y-[67%] left-0 w-full overflow-hidden z-10",
				{
					"drop--shadow":
						location.pathname === "/" ||
						location.pathname === "/pricing" ||
						location.pathname === "/terms-of-use" ||
						location.pathname === "/invitations" ||
						location.pathname === "/faqs",
				}
			)}
		>
			<img
				src={
					screen >= 500
						? web
						: location.pathname === "/" ||
						  location.pathname === "/pricing" ||
						  location.pathname === "/terms-of-use" ||
						  location.pathname === "/invitations" ||
						  location.pathname === "/faqs"
						? mobile
						: mobile2
				}
				className="min-w-full"
				alt=""
			/>
		</div>
	);
};

export default WaveDivider2;
