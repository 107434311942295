import React from "react";
import { Link } from "react-router-dom";
import headerShape from "assets/webp/header-shape.svg";
import logo from "assets/webp/logo.webp";
import { Icons } from "./Icons";

const menu = [
	{
		name: "About",
		url: "/about",
	},
	{
		name: "Pricing",
		url: "/pricing",
	},
	{
		name: "Blog",
		url: "/blog",
	},
	{
		name: "Faq",
		url: "/faqs",
	},
	{
		name: "Free",
		url: "/invitations",
	},
];

const Menu = () => {
	return (
		<>
			<ul className="flex flex-col lg:flex-row justify-center gap-4 xl:gap-[34px] font-jua">
				{menu.map((item, index) => (
					<li key={index}>
						<Link
							to={item.url}
							className="text-blue-default hover:text-blue-hover active:text-blue-pressed lg:px-4 py-2 transition-colors duration-300 uppercase text-sm lg:text-[20px] leading-[25px]"
						>
							{item.name}
						</Link>
					</li>
				))}
			</ul>
		</>
	);
};

const Header = () => {
	const [open, setOpen] = React.useState(false);
	return (
		<>
			<div
				className={`fixed lg:hidden top-0 pt-[100px] left-0 max-h-full overflow-y-auto w-full bg-bodyBg border-r shadow-md pb-8 duration-300 z-[99] ${
					open
						? "visible opacity-100"
						: "invisible opacity-0 -translate-y-1/2"
				}`}
			>
				<div className="container px-8">
					<nav className="flex flex-col justify-center h-full w-full gap-8 pt-8">
						<Menu />
						<div>
							<a href="/birthday-movie-maker" target='_self' className="btn py-[8px] px-7 text-sm">
								START NOW
							</a>
						</div>
					</nav>
				</div>
			</div>
			<header className="fixed left-0 top-0 w-full z-[99]">
				<div className="absolute top-0 left-0 min-h-[140px] xl:h-[240px] overflow-hidden w-full pointer-events-none">
					<img
						src={headerShape}
						className="w-[calc(100%+5px)] min-h-[140px] max-h-[260px] absolute top-0 left-0 object-cover object-left-bottom pointer-events-none max-w-[unset]"
						alt=""
					/>
				</div>
				<div className="container relative z-10">
					<div className="flex justify-between items-center h-[101px] xl:h-[173px] xl:-translate-y-2">
						<Link
							to="/"
							className="block w-[100px] lg:w-[131px] xl:translate-y-2"
						>
							<img src={logo} alt="" className="w-full" />
						</Link>
						<nav className="hidden lg:block">
							<Menu />
						</nav>
						<button
							className="lg:hidden"
							type="button"
							onClick={() => setOpen(!open)}
						>
							{open ? Icons.clear : Icons.hamburger}
						</button>
						<div className="hidden lg:block">
							<a href="/birthday-movie-maker" target='_self' className="btn">
								START NOW
							</a>
						</div>
					</div>
				</div>
			</header>
		</>
	);
}


export default Header;
