import Lottie from 'lottie-react';
import celeb from 'assets/lottie/celeb.json';
import love from 'assets/lottie/anim.json';

const Wait = ()=>{
    return(
        <h1 className="text-center text-[30px] leading-[43px] sm:text-4xl md:text-6xl md:text-[40px] mx-auto max-w-[305px] sm:max-w-[897px]">
            Congratulations! <br />
            Your movie is being prepared!
        </h1>
    )
}
const Done = ()=>{
    return(
        <>
            <div className='text-center relative'>
                <h1 className="text-center text-[30px] leading-snug sm:text-4xl md:text-6xl md:text-[40px] mx-auto max-w-[305px] sm:max-w-[897px]">
                    Your Movie is Ready!
                </h1>
                <Lottie
                        animationData={celeb}
                        loop={true}
                        className='absolute right-0 top-0 w-[100px] transform translate-x-[30%] md:translate-x-[100%] -translate-y-[100%] md:-translate-y-[50%]'
                    />
                <Lottie
                    animationData={celeb}
                    loop={true}
                    className='absolute left-0 top-0 w-[100px] transform -translate-x-[30%] md:-translate-x-[100%] -translate-y-[100%] md:-translate-y-[50%] scale-x-[-100%]'
                />
            </div>
            <h1 className="text-center text-[30px] leading-tight sm:text-4xl md:text-6xl md:text-[40px] mx-auto max-w-[305px] sm:max-w-[897px]">
                <b>Download</b> & <b>Share</b> the Love! <br />
            </h1>
            <div className="ml-5 flex justify-center items-center w-20 h-20 overflow-hidden">
                        <Lottie
                            animationData={love}
                            loop={true}
                            style={{ 
                                width: 300,
                                height: 300,
                                position: "absolute"
                            }}
                        />
            </div> 
        </>
        
    )
}

const Title = ({wait})=>{
    return wait ? <Wait/> : <Done/>
}

export default Title