import { useEffect, useRef } from "react";
import rotateLeftIcon from "assets/webp/rotate1.webp";
import rotateRightIcon from "assets/webp/rotate2.webp";

const ImageRotation = ({ imgSrc, rotation, setRotation, setIsDisabled }) => {
    useEffect(()=>{
        setIsDisabled(false)
    },[])
    
    // Handle visual rotation with CSS
    const imgRef = useRef()
    const handleRotate = (direction) => {
        const newRotation = direction === "left" ? rotation - 90 : rotation + 90;  
        setRotation(newRotation);
    };
    
    return (
        <div className="text-center">
            <h2 className="text-lg md:text-2xl mb-4">
                Picture is on its side or upside down? <br /> Use the curved arrows to rotate it.
            </h2>
            <div className="bg-blue-400 relative flex flex-col justify-center items-center w-[90vw] max-w-[400px] h-[90vw] max-h-[400px] mx-auto shadow-2xl rounded-2xl overflow-hidden">
                <img
                    ref={imgRef}
                    src={imgSrc}
                    alt="Uploaded face"
                    style={{
                        transform: `rotate(${rotation}deg)`,
                    }}
                    className="w-full h-full rounded-lg transition-transform duration-300 object-contain"
                />
                <button
                    type="button"
                    className="absolute top-5 left-5 z-50 bg-white rounded-xl p-2 transition-transform duration-300 ease-in-out hover:scale-110"
                    onClick={() => handleRotate("left")}
                >
                    <img src={rotateRightIcon} alt="Rotate Left" />
                </button>
                <button
                    type="button"
                    className="absolute top-5 right-5 z-50 bg-white rounded-xl p-2 transition-transform duration-300 ease-in-out hover:scale-110"
                    onClick={() => handleRotate("right")}
                >
                    <img src={rotateLeftIcon} alt="Rotate Right" />
                </button>
            </div>
        </div>
    );
};

export default ImageRotation;
