import { useEffect, useState } from "react";
import movieArgs from "routes/app/utils/movieArgs.js";
import fairytaleVideo from "assets/videos/video/fairytale.mp4";
import faretale from "assets/videos/video/fairytale.webp";
import fantasyVideo from "assets/videos/video/fantasy.mp4";
import fantasy from "assets/videos/video/fantasy.webp";
import jungleVideo from "assets/videos/video/jungle.mp4";
import jungle from "assets/videos/video/jungle.webp";
import VideoPlayer from "components/VideoPlayer";
import { cn } from "utils/cn";
import MovieMakerLayout from "../../components/MovieMakerLayout";
import playImg from 'assets/images/play.svg';
import vImg from 'assets/images/v.svg';

const data = [
	{
		title: "Fairytale Magic",
		video: fairytaleVideo,
		id: "fairytale",
		poster: faretale,
	},
	{
		title: "Knights & Dragons",
		video: fantasyVideo,
		id: "fantasy",
		poster: fantasy,
	},
	{
		title: "Jungle Adventures",
		video: jungleVideo,
		id: "jungle",
		poster: jungle,
	},
	{
		title: "Happy Pirates",
		video: "",
		id: "Pirates",
		poster: "",
	},
	{
		title: "Funny Monsters",
		video: "",
		id: "Monsters",
		poster: "",
	},
	{
		title: "Mermaids Party",
		video: "",
		id: "Party",
		poster: "",
	},
]

const Item = ({item, activeId, setActiveId})=>{
    const url = item.video
	const [openVideo, setOpenVideo] = useState(false);

    return(
        <div
            className={cn(
            	"group rounded-[40px] bg-white border border-blue-default cursor-pointer duration-300",
            	{
            		"cursor-auto": !item.video,
            		"bg-blue-default text-white": activeId === item.id,
            	}
            )}
            key={item.id}
            onClick={() => (item.id && item.video) && setActiveId(item.id)}
		>
            {item.video && (
            	<div
            		className="relative m-[-1px] rounded-[inherit] overflow-hidden flex flex-col justify-center items-center"
            	>
            		<img
            			src={item.poster}
            			className="w-full aspect-[407/266] object-cover rounded-[inherit] duration-200 group-hover:scale-105"
            			alt=""
            		/>
                    <img
                        src={playImg}
                        className="absolute hover:scale-105 pointer-events-auto w-[20%]"
                        style={{ zIndex: 10 }}
                        onClick={(e) => {
                            e.stopPropagation();
                            setOpenVideo(true);
                        }}
                        alt="Play"
                    />
            		<div
            			className={cn(
            				"w-9 h-9 rounded-full border border-blue-default bg-white absolute right-4 top-4 z-10 flex justify-center items-center text-blue-default",
            				{
            					"border-2": activeId === item.id,
            				}
            			)}
            		>
            			{activeId === item.id && <img src={vImg}/>}
            		</div>
            	</div>
            )}
            <div className="text-center px-5 pb-5 pt-8">
            	<h4 className={"text-2xl pb-4 "+(activeId === item.id?"text-white":"")}>{item.title}</h4>
            	{!item.video && (
            		<button
            			type="button"
            			className="text-[22px] h-[58px] w-full rounded-full border border-blue-default font-medium text-[#979797]"
            			disabled
            		>
            			coming soon
            		</button>
            	)}
            </div>
            {item.video && <VideoPlayer open={openVideo} setOpen={setOpenVideo} url={url} poster={item.poster}/>}
        </div>
    )
}

const ChooseStory = () => {
	const [activeId, setActiveId] = useState(movieArgs.type);

    useEffect(()=>{
        movieArgs.type = activeId;
        movieArgs.avatar.avatars = 0;
    },[activeId])

	return (
		<MovieMakerLayout
			step={1}
			nextDisabled={!activeId}
			error="Please choose a story"
		>
			<div>
				<div className="text-center">
					<h1 className="text-[30px] leading-[43px] sm:text-4xl md:text-6xl md:text-[40px] mb-[25px] md:mb-[30px] mx-auto sm:max-w-[700px]">
						First let's choose a story!
					</h1>
				</div>
				<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-[26px] mx-auto max-w-[1280px]">
					{data.map((item, index) => <Item key={index} item={item} activeId={activeId} setActiveId={setActiveId} />)}
				</div>
			</div>
		</MovieMakerLayout>
	);
};

export default ChooseStory;
