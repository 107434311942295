const clear = (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		className="h-6 w-6"
		fill="none"
		viewBox="0 0 24 24"
		stroke="currentColor"
	>
		<path
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			d="M6 18L18 6M6 6l12 12"
		/>
	</svg>
);
const hamburger = (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		className="size-8"
		fill="none"
		viewBox="0 0 24 24"
		stroke="currentColor"
	>
		<path
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			d="M4 6h16M4 12h16m-7 6h7"
		/>
	</svg>
);
const star = (
	<svg
		width="123"
		height="19"
		viewBox="0 0 123 19"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M10.9116 0.2436L13.908 6.00509L20.6063 6.92921C20.7797 6.95359 20.9235 7.06878 20.9775 7.22653C21.0315 7.38428 20.9864 7.55735 20.861 7.67325L16.0151 12.157L17.1592 18.4893C17.1893 18.6526 17.1186 18.8179 16.9769 18.9155C16.8353 19.0131 16.6473 19.026 16.4922 18.9488L10.5007 15.9597L4.50919 18.9497C4.3542 19.0271 4.16609 19.0144 4.02434 18.9169C3.88258 18.8193 3.8119 18.6539 3.84215 18.4906L4.9863 12.157L0.138953 7.67325C0.0135841 7.55735 -0.0315455 7.38428 0.0224676 7.22653C0.0764806 7.06878 0.220312 6.95359 0.393716 6.92921L7.09197 6.00509L10.0898 0.2436C10.1661 0.0946227 10.3257 0 10.5007 0C10.6757 0 10.8353 0.0946227 10.9116 0.2436Z"
			fill="#3975E3"
		/>
		<path
			d="M36.392 0.2436L39.2457 6.00509L45.625 6.92921C45.7902 6.95359 45.9272 7.06878 45.9786 7.22653C46.03 7.38428 45.9871 7.55735 45.8677 7.67325L41.2524 12.157L42.3421 18.4893C42.3708 18.6526 42.3034 18.8179 42.1685 18.9155C42.0336 19.0131 41.8545 19.026 41.7068 18.9488L36.0007 15.9597L30.2945 18.9497C30.1469 19.0271 29.9677 19.0144 29.8327 18.9169C29.6977 18.8193 29.6304 18.6539 29.6592 18.4906L30.7489 12.157L26.1323 7.67325C26.0129 7.55735 25.97 7.38428 26.0214 7.22653C26.0728 7.06878 26.2098 6.95359 26.375 6.92921L32.7543 6.00509L35.6093 0.2436C35.682 0.0946227 35.834 0 36.0007 0C36.1673 0 36.3193 0.0946227 36.392 0.2436Z"
			fill="#3975E3"
		/>
		<path
			d="M61.9116 0.2436L64.908 6.00509L71.6063 6.92921C71.7797 6.95359 71.9235 7.06878 71.9775 7.22653C72.0315 7.38428 71.9864 7.55735 71.861 7.67325L67.0151 12.157L68.1592 18.4893C68.1893 18.6526 68.1186 18.8179 67.9769 18.9155C67.8353 19.0131 67.6473 19.026 67.4922 18.9488L61.5007 15.9597L55.5092 18.9497C55.3542 19.0271 55.1661 19.0144 55.0243 18.9169C54.8826 18.8193 54.8119 18.6539 54.8421 18.4906L55.9863 12.157L51.139 7.67325C51.0136 7.55735 50.9685 7.38428 51.0225 7.22653C51.0765 7.06878 51.2203 6.95359 51.3937 6.92921L58.092 6.00509L61.0898 0.2436C61.1661 0.0946227 61.3257 0 61.5007 0C61.6757 0 61.8353 0.0946227 61.9116 0.2436Z"
			fill="#3975E3"
		/>
		<path
			d="M87.392 0.2436L90.2457 6.00509L96.625 6.92921C96.7902 6.95359 96.9272 7.06878 96.9786 7.22653C97.03 7.38428 96.9871 7.55735 96.8677 7.67325L92.2524 12.157L93.3421 18.4893C93.3708 18.6526 93.3034 18.8179 93.1685 18.9155C93.0336 19.0131 92.8545 19.026 92.7068 18.9488L87.0007 15.9597L81.2945 18.9497C81.1469 19.0271 80.9677 19.0144 80.8327 18.9169C80.6977 18.8193 80.6304 18.6539 80.6592 18.4906L81.7489 12.157L77.1323 7.67325C77.0129 7.55735 76.97 7.38428 77.0214 7.22653C77.0728 7.06878 77.2098 6.95359 77.375 6.92921L83.7543 6.00509L86.6093 0.2436C86.682 0.0946227 86.834 0 87.0007 0C87.1673 0 87.3193 0.0946227 87.392 0.2436Z"
			fill="#3975E3"
		/>
		<path
			d="M112.912 0.2436L115.908 6.00509L122.606 6.92921C122.78 6.95359 122.924 7.06878 122.978 7.22653C123.032 7.38428 122.986 7.55735 122.861 7.67325L118.015 12.157L119.159 18.4893C119.189 18.6526 119.119 18.8179 118.977 18.9155C118.835 19.0131 118.647 19.026 118.492 18.9488L112.501 15.9597L106.509 18.9497C106.354 19.0271 106.166 19.0144 106.024 18.9169C105.883 18.8193 105.812 18.6539 105.842 18.4906L106.986 12.157L102.139 7.67325C102.014 7.55735 101.968 7.38428 102.022 7.22653C102.076 7.06878 102.22 6.95359 102.394 6.92921L109.092 6.00509L112.09 0.2436C112.166 0.0946227 112.326 0 112.501 0C112.676 0 112.835 0.0946227 112.912 0.2436Z"
			fill="#3975E3"
		/>
	</svg>
);
const plus = (
	<svg
		width="15"
		height="17"
		viewBox="0 0 15 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M7.50391 1V15.4351"
			stroke="#170F49"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M1 8.2168H14.0078"
			stroke="#170F49"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);
const minus = (
	<svg
		width="20"
		height="3"
		viewBox="0 0 20 3"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M1.16797 1.32812H18.5159"
			stroke="#2B2B2B"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);

const play = (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		fill="currentColor"
		className="w-full h-full"
	>
		<path
			fillRule="evenodd"
			d="M4.5 5.653c0-1.427 1.529-2.33 2.779-1.643l11.54 6.347c1.295.712 1.295 2.573 0 3.286L7.28 19.99c-1.25.687-2.779-.217-2.779-1.643V5.653Z"
			clipRule="evenodd"
		/>
	</svg>
);

const check = (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		fill="currentColor"
		className="size-6"
	>
		<path
			fillRule="evenodd"
			d="M19.916 4.626a.75.75 0 0 1 .208 1.04l-9 13.5a.75.75 0 0 1-1.154.114l-6-6a.75.75 0 0 1 1.06-1.06l5.353 5.353 8.493-12.74a.75.75 0 0 1 1.04-.207Z"
			clipRule="evenodd"
		/>
	</svg>
);

const google = (
	<svg
		width="50"
		height="49"
		viewBox="0 0 50 49"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M42.2936 24.7906C42.2936 23.5022 42.1779 22.2634 41.9632 21.0742H24.8516V28.1022H34.6297C34.2085 30.3733 32.9284 32.2975 31.0042 33.5859V38.1446H36.876C40.3115 34.9816 42.2936 30.3238 42.2936 24.7906Z"
			fill="#4285F4"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M24.8534 42.5448C29.759 42.5448 33.8717 40.9179 36.8779 38.143L31.006 33.5843C29.3791 34.6744 27.298 35.3186 24.8534 35.3186C20.1213 35.3186 16.1159 32.1226 14.6872 27.8281H8.61719V32.5355C11.6068 38.4734 17.7511 42.5448 24.8534 42.5448Z"
			fill="#34A853"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14.6861 27.8283C14.3227 26.7382 14.1163 25.5737 14.1163 24.3762C14.1163 23.1787 14.3227 22.0143 14.6861 20.9242V16.2168H8.61609C7.38557 18.6696 6.68359 21.4444 6.68359 24.3762C6.68359 27.308 7.38557 30.0829 8.61609 32.5356L14.6861 27.8283Z"
			fill="#FBBC05"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M24.8534 13.4332C27.5209 13.4332 29.9159 14.3499 31.7989 16.1503L37.01 10.9392C33.8635 8.00739 29.7507 6.20703 24.8534 6.20703C17.7511 6.20703 11.6068 10.2785 8.61719 16.2164L14.6872 20.9237C16.1159 16.6293 20.1213 13.4332 24.8534 13.4332Z"
			fill="#EA4335"
		/>
	</svg>
);

const successIcon = (
	<svg
		width="130"
		height="130"
		viewBox="0 0 130 130"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g filter="url(#filter0_d_429_5564)">
			<circle cx="59" cy="54" r="30" fill="#FFFDEB" />
			<circle cx="59" cy="54" r="21" fill="#FFFACC" />
			<circle cx="59" cy="54" r="12" fill="#FFF7AF" />
			<path
				d="M49.3155 48.4304C47.5952 47.8695 46.2484 49.9499 47.4725 51.2775L56.6516 61.2324C57.3243 61.962 58.4706 61.9913 59.18 61.297L77.6555 43.2171C79.1476 41.7569 77.2881 39.3882 75.5039 40.4763L58.5756 50.8008C58.1357 51.0691 57.5997 51.1316 57.1094 50.9717L49.3155 48.4304Z"
				fill="#3975E3"
			/>
		</g>
		<defs>
			<filter
				id="filter0_d_429_5564"
				x="0"
				y="0"
				width="130"
				height="130"
				filterUnits="userSpaceOnUse"
				color-interpolation-filters="sRGB"
			>
				<feFlood flood-opacity="0" result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feMorphology
					radius="5"
					operator="dilate"
					in="SourceAlpha"
					result="effect1_dropShadow_429_5564"
				/>
				<feOffset dx="6" dy="11" />
				<feGaussianBlur stdDeviation="15" />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_429_5564"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_429_5564"
					result="shape"
				/>
			</filter>
		</defs>
	</svg>
);

export const Icons = {
	clear,
	hamburger,
	star,
	play,
	check,
	google,
	successIcon,
};
export const faqIcons = {
	plus,
	minus,
};
