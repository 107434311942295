import React from "react";
import Banner from "routes/website/pages/home/components/Banner";
import Layout from "routes/website/components/Layout";
import Clients from "./components/Clients";
import Newsletter from "./components/Newsletter";
import ShareWithFriends from "./components/ShareWithFriends";
import Steps from "./components/Steps";

const Home = () => {
	return (
		<Layout>
			<Banner />
			<Steps />
			<ShareWithFriends />
			<Clients />
			<Newsletter />
		</Layout>
	);
};

export default Home;
