import movieArgs from "routes/app/utils/movieArgs";

const Button = ({type, number, data, action, main, globals})=>{
    const getPos = (type)=>{
        let margin = '20px';
        if (window.innerWidth < 768) margin = '10px';
        const top = margin;
        const bottom = margin;
        const left = margin;
        const right = margin;
        
        const positions = {
            hair: {top, left},
            top: {top, right},
            skin: {bottom, left},
            clothes: {bottom, right}
        }
        
        return positions[type];
    }
    const HairButton = ()=>{
        return(
            <>
                <img src={globals.images.hair.front[ data.hairColor ][ number ].src} className="absolute w-full z-20"/>
                <img src={globals.images.hair.head.src} className="absolute w-full z-10"/>
                <img src={globals.images.hair.back[ data.hairColor ][ number ].src} className="absolute w-full z-0"/>
            </>
        )
    }
    let src;
    if(type=='clothes') src = globals.images[type][movieArgs.type][data.skin][number].src
    else if(type=='hairColor') src = globals.images.hair.colors[number].src;
    else if(type!='hair') src = globals.images[type][number].src
    return(
        <div className={
                (main?'absolute ':'relative m-2 ')+
                ((!main && (type=='hair' || type=='hairColor'))?" w-[60px] h-[60px]":" w-[80px] h-[80px]")+
                " md:w-[100px] md:h-[100px]  flex flex-col justify-center items-center cursor-pointer border border-blue-default rounded-xl bg-white"
            }
            style={main?getPos(type):null}
            onClick={action}
        >
            {type=='hair' ? <HairButton/>:<img src={src} className="w-full"/>}
        </div>
    )
}

export default Button;