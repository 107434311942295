import { useState } from "react";

const EditControls = ({ editMode, setEditMode, resetFunctionRef }) => {
    const [showResetConfirmation, setShowResetConfirmation] = useState(false);

    const handleReset = () => {
        resetFunctionRef.current()
        setShowResetConfirmation(false);
    };

    return (
      <>
        {editMode ? (
          <>
            <div className="text-base md:text-xl max-w-[780px] mx-auto">
              Use the handlers to adjust the mask. To cancel the changes, click the
              "Reset" button. When you're done, click the "Save" button.
            </div>
            <div className="flex flex-wrap justify-center items-center gap-1 md:gap-5 my-4">
              <button
                className="download-btn flex-grow max-w-[190px]"
                type="button"
                onClick={() => setShowResetConfirmation(true)}
              >
                Reset
              </button>
              <button
                className="download-btn success flex-grow max-w-[190px]"
                type="button"
                onClick={() => setEditMode(false)}
              >
                Save
              </button>
            </div>
          </>
        ) : (
          <div className="text-base md:text-xl max-w-[780px] mx-auto">
            Face should fill the mask, without gaps around it.
            If you need to make changes, click the mask.
          </div>
        )}

        {/* Overlay for reset confirmation */}
        {showResetConfirmation && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full text-center">
              <h2 className="text-lg mb-4">Are you sure you want to reset your changes?</h2>
              <div className="flex justify-center gap-4">
                <button
                  className="bg-red-500 text-white rounded-full px-6 py-2 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-300 transition"
                  onClick={() => handleReset()}
                >
                  Yes
                </button>
                <button
                  className="bg-green-500 text-white rounded-full px-6 py-2 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-300 transition"
                  onClick={() => setShowResetConfirmation(false)}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    );
};

export default EditControls;
