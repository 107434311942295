import React from "react";
import webBg from "assets/images/faqs/faq-bg.webp";
import Layout from "routes/website/components/Layout";
import useScreen from "components/useScreen";
import PricingSection from "./components/PricingSection";
const Pricing = () => {
	const screen = useScreen();
	return (
		<Layout>
			<div
				style={{
					background: `url('${webBg}') no-repeat ${
						screen >= 640 ? "bottom center" : "top center"
					} / cover`,
				}}
				className="faq-page"
			>
				<PricingSection />
			</div>
		</Layout>
	);
};

export default Pricing;
