import { useEffect, useState } from "react";
import movieArgs from "routes/app/utils/movieArgs.js";
import Input from "components/Input";
import vImg from 'assets/images/v.svg';
import MovieMakerLayout from "routes/app/components/MovieMakerLayout.jsx";

import cake1 from "assets/webp/cake/1.webp";
import cake2 from "assets/webp/cake/2.webp";
import cake3 from "assets/webp/cake/3.webp";

const cakes = [
    cake1,
    cake2,
    cake3
]
const ages = [
	...Array.from({ length: 120 }, (_, i) => (i + 1)),
]
const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
const days = [
	...Array.from({ length: 31 }, (_, i) => (i + 1)),
]
const hours = [
	...Array.from({ length: 12 }, (_, i) => (i + 1)),
]
const minutes = [
	...Array.from({ length: 59 }, (_, i) => (i + 1)),
]
const ampms = ['AM', 'PM']

const Cake = ({index, cake, cakeSelected, setCakeSelected})=>{
    return(
        <div
            className={((index === cakeSelected)?"border-dashed border-[3px]":'border-[1px]')+" border-blue-default shadow-lg max-w-[142px] mx-auto relative cursor-pointer rounded-xl pt-3 pb-2"}
            onClick={() => setCakeSelected(index)}
        >
            <div className="face-select-icon top-2">
                {index === cakeSelected && <img src={vImg} className="w-[50%]"/>}
            </div>
            <img
                src={cake}
                alt="cake"
                className="w-full object-contain"
            />
        </div>
    )
}

const InviteInfo = () => {
	const [name, setName] = useState(movieArgs.name || '');
	const [age, setAge] = useState(movieArgs.age || 'Age');
    const [day, setDay] = useState(movieArgs.invite.day || 'Day')
    const [month, setMonth] = useState(movieArgs.invite.month || 'Month')
    const [hour, setHour] = useState(movieArgs.invite.hour || 'Hour')
    const [minute, setMinute] = useState(movieArgs.invite.minute || 'Minutes')
    const [ampm, setAmpm] = useState(movieArgs.invite.ampm || 'AM/PM')
    const [adrsA, setAdrsA] = useState(movieArgs.invite.adrsA || '');
    const [adrsB, setAdrsB] = useState(movieArgs.invite.adrsB || '');
    const [comntA, setComntA] = useState(movieArgs.invite.comntA || '');
    const [comntB, setComntB] = useState(movieArgs.invite.comntB || '');
	const [cakeSelected, setCakeSelected] = useState(movieArgs.cake);
    const [error, setError] = useState()
    
    useEffect(()=>{
        if (!name) {
            setError("Please enter your kids name.");
        }
        else if (age=='Age') {
            setError("Please choose your kids age.");
        }
        else if (day=='Day') {
            setError("Please choose a day.");
        }
        else if (month=='Month') {
            setError("Please choose a month.");
        }
        else if (hour=='Hour') {
            setError("Please choose an hour.");
        }
        else if (minute=='Minutes') {
            setError("Please choose minutes.");
        }
        else if (ampm=='AM/PM') {
            setError("Please choose AM or PM.");
        }
        else if (adrsA=='') {
            setError("Please fill Address 1.");
        }
        else if (adrsB=='') {
            setError("Please fill Address 2.");
        }
        else if (comntA=='') {
            setError("Please fill Comment 1.");
        }
        else if (comntB=='') {
            setError("Please fill Comment 2.");
        }
        else if (cakeSelected===null) {
            setError("Please select a cake.");
        }
        movieArgs.name = name;
        movieArgs.age = age;  
        movieArgs.invite.day = day;
        movieArgs.invite.month = month;
        movieArgs.invite.hour = hour;
        movieArgs.invite.minute = minute;
        movieArgs.invite.ampm = ampm;
        movieArgs.invite.adrsA = adrsA;
        movieArgs.invite.adrsB = adrsB;
        movieArgs.invite.comntA = comntA;
        movieArgs.invite.comntB = comntB;
        movieArgs.cake = cakeSelected;
    },[name, age, cakeSelected, day, month, hour, minute, ampm, adrsA, adrsB, comntA, comntB])

	return (
		<MovieMakerLayout
			step={4}
			nextDisabled={!name || age=='Age' || cakeSelected===null}
			error={error}
		>
			<div>
				<div className="text-center mb-10">
					<h1 className="text-[30px] leading-[43px] sm:text-4xl md:text-6xl md:text-[40px] mx-auto sm:max-w-[800px]">
						Almost done!
					</h1>
					<div className="text-sm md:text-xl">
						Enter the party details!
					</div>
				</div>
				<div className="flex flex-col space-y-4 py-10 px-6 max-w-[624px] rounded-[40px] border border-blue-default relative mx-auto">
                    <div className="flex gap-6">
							<div className="md:text-lg w-[60%] flex-grow">
								<Input
									type="text"
									placeholder="Name"
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
							</div>
							<div className="md:text-lg w-[40%] flex-grow">
								<Input
									placeholder="Age"
									value={age}
									onChange={(e) => setAge(e.target.value)}
									options={ages}
								/>
							</div>
					</div>
                    <div className="flex gap-6">
                        <Input
                            placeholder="Day"
                            value={day}
                            onChange={(e) => setDay(e.target.value)}
                            options={days}
                        />
                        <Input
                            placeholder="Month"
                            value={month}
                            onChange={(e) => setMonth(e.target.value)}
                            options={months}
                        />
                    </div>
                    <div className="flex gap-6">
                        <Input
                            placeholder="Hour"
                            value={hour}
                            onChange={(e) => setHour(e.target.value)}
                            options={hours}
                        />
                        <Input
                            placeholder="Minutes"
                            value={minute}
                            onChange={(e) => setMinute(e.target.value)}
                            options={minutes}
                        />
                        <Input
                            placeholder='AM/PM'
                            value={ampm}
                            onChange={(e) => setAmpm(e.target.value)}
                            options={ampms}
                        />
                    </div>
                    <Input
						type="text"
						placeholder="Address 1"
						value={adrsA}
						onChange={(e) => setAdrsA(e.target.value)}
					/>
                    <Input
						type="text"
						placeholder="Address 2"
						value={adrsB}
						onChange={(e) => setAdrsB(e.target.value)}
					/>
                    <Input
						type="text"
						placeholder="Comment 1"
						value={comntA}
						onChange={(e) => setComntA(e.target.value)}
					/>
                    <Input
						type="text"
						placeholder="Comment 2"
						value={comntB}
						onChange={(e) => setComntB(e.target.value)}
					/>
					<div className="grid grid-cols-3 gap-4 mt-10">
						{cakes.map((cake, index) => <Cake key={index} index={index} cake={cake} cakeSelected={cakeSelected} setCakeSelected={setCakeSelected}/>)}
					</div>
				</div>
			</div>
		</MovieMakerLayout>
	);
};

export default InviteInfo;
