import { MdAddPhotoAlternate } from 'react-icons/md';
import { FaRegImage } from 'react-icons/fa';
import { MdAddAPhoto } from 'react-icons/md';
import { IoImageOutline } from 'react-icons/io';
import { HiOutlinePhotograph } from 'react-icons/hi';
import { BsImage } from 'react-icons/bs';

const FileSelector = () => {
    return (
        <>
            <BsImage size={60} className="text-gray-400" />
            <p className="font-jua text-lg text-gray-500 mt-2">Select a picture</p>
        </>
    )
};

export default FileSelector;
