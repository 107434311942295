import logo from "assets/webp/logo.webp";

const Logo = ()=>{
    return(
        <a
			href="/" target='_blank'
			className="block mx-auto w-[121px] md:w-[253px]"
		>
			<img src={logo} className="w-full" alt="logo" />
		</a>
    )
}
export default Logo