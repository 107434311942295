import { useEffect, useState } from "react";
import useScreen from "components/useScreen";

import rightSideBalloon from "assets/webp/anime/ballon-right-side.webp";
import book from "assets/webp/anime/book.webp";
import redBalloon from "assets/webp/anime/red-ballon.webp";
import shape1 from "assets/webp/banner/shape1.webp";
import shape3 from "assets/webp/banner/shape3.webp";
import dividerMobile from "assets/webp/wave/wave-divider-mobile.webp";
import divider from "assets/webp/wave/wave-divider.webp";


const PostContent = ({data}) => {  
    const screen = useScreen();
    const [src, setSrc] = useState(data && data.img)
    useEffect(()=>{
        if(data){
            setSrc(null);
            setTimeout(()=>{
                setSrc(data.img);
            },100)
        }
    },[data])
    
    if(data) return (
        <section className="pt-[161px] lg:pt-[260px] overflow-hidden relative">
            <div className="container relative z-10">
                <div className="max-w-[892px] px-6 mx-auto text-center pb-[55px] md:pb-[98px]">
                    <h1 className="text-[42px] max-w-[480px] mx-auto sm:max-w-full leading-[1.3] sm:text-6xl lg:text-7xl m-0">
                        {data.title}
                    </h1>
                </div>
            </div>
            <div className="relative">
                <div className="container">
                    <img
                        src={src}
                        className="w-full min-h-[339px] object-cover rounded-[40px] mx-auto"
                        alt=""
                    />
                </div>
                {screen >= 500 ? (
                    <img
                        src={divider}
                        alt=""
                        className="absolute bottom-0 w-full translate-y-1/2 z-10"
                    />
                ) : (
                    <img
                        src={dividerMobile}
                        alt=""
                        className="absolute bottom-0 w-full translate-y-1/2 z-10"
                    />
                )}
            </div>
            <div className="relative">
                <div className="container relative">
                    <div className="pt-[120px] lg:pt-[200px] flex flex-col gap-[45px] md:gap-[54px] max-w-[843px] mx-auto text-center md:text-start">
                        {data.details.map((item, index) => (
                            <div key={index}>
                                <h2 className="text-5xl font-normal mb-5">
                                    {item.title}
                                </h2>
                                {item.text && (
                                    <p
                                        className="text-grey2 text-[16px] md:text-[22px] md:leading-[38px]"
                                        dangerouslySetInnerHTML={{
                                            __html: item.text,
                                        }}
                                    ></p>
                                )}
                                {item?.info?.length > 0 && (
                                    <ul className="list-disc pl-7 max-w-[700px] text-grey2 pt-4 flex flex-col gap-2">
                                        {item.info.map((info, index) => (
                                            <li
                                                key={index}
                                                className="text-[16px] md:text-[21px] md:leading-[38px]"
                                            >
                                                {info}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        ))}
                    </div>
                    <div className="pb-[55px] md:pb-[98px] md:border-b"></div>

                    <img
                        src={shape1}
                        className="absolute top-8 md:-top-16 left-0 md:left-6 w-[170px] lg:w-[497px]"
                        alt=""
                    />
                    <img
                        src={shape3}
                        className="absolute top-[84px] lg:top-[128px] -right-16 md:-right-1 w-[175px] lg:w-[272px]"
                        alt=""
                    />
                </div>
                <img
                    src={redBalloon}
                    alt=""
                    className="absolute left-0 z-10 hidden lg:block top-[580px] max-xl:w-24"
                />
                <img
                    src={rightSideBalloon}
                    alt=""
                    className="absolute right-0 z-10 hidden lg:block top-[770px] max-xl:w-[160px]"
                />
                <img
                    src={book}
                    alt=""
                    className="absolute left-0 z-10 hidden xl:block top-[1900px]"
                />
            </div>
            <img
                src={shape1}
                className="absolute top-8 md:top-2 left-0 md:left-6 w-[170px] lg:w-[497px]"
                alt=""
            />
            <img
                src={shape3}
                className="absolute top-[84px] lg:top-[128px] -right-16 md:-right-1 w-[175px] lg:w-[272px]"
                alt=""
            />
        </section>
    );
}

export default PostContent;
