import { getStorage, ref, uploadBytesResumable, uploadString } from "firebase/storage";
import imageUtils from "routes/app/utils/imageUtils";
import movieArgs from "routes/app/utils/movieArgs";

const makeUpload = async(changes)=>{
    const uploadFile = async(ref, obj)=>{
        return new Promise((resolve, reject)=>{
            try{
                if(typeof obj === 'string' || obj instanceof String){ //https://firebase.google.com/docs/reference/js/storage.md#uploadstring_277829d
                    uploadString(ref, window.btoa(obj), "base64", {
                        contentType: "application/json"
                    }).then(
                        (snapshot)=>{
                            resolve()
                        }
                    )
                }
                else{
                    const uploadTask = uploadBytesResumable(ref, obj);
                    uploadTask.on('state_changed', 
                        (snapshot) => {
                            const progress = (snapshot.bytesTransferred / snapshot.totalBytes)*100;
                            console.log(Math.round(progress)+'% - '+Math.round(snapshot.bytesTransferred/1000)+'kb / '+Math.round(snapshot.totalBytes/1000)+'kb');
                            switch (snapshot.state) {
                            case 'paused':
                                // console.log('Upload is paused');
                                break;
                            case 'running':
                                // console.log('Upload is running');
                                break;
                            }
                        }, 
                        (error) => { throw error }, 
                        () => { resolve() }
                    );
                }
            }
            catch(err){
                reject(err);
            }
        })      
    }
    try{
        const path = "users/"+movieArgs.data.uid+"/movies/"+movieArgs.data.mid+"/assets";
        
        // upload args
        if(changes.args){
            console.log("upload args");
            const argsRef = ref(getStorage(), path+"/args.json");
            const argsObj = {
                family: movieArgs.type,
                head: movieArgs.head,
                clothes: Number(movieArgs.avatar.avatars)+1,
                hair: {
                    type: Number(movieArgs.avatar.hair)+1,
                    color: Number(movieArgs.avatar.hairColor)+1
                },
                tops: Number(movieArgs.avatar.tops)+1,
                colors: Number(movieArgs.avatar.colors)+1,
                cake: Number(movieArgs.cake)+1,
                age: Number(movieArgs.age),
                name: movieArgs.name,
                uid: movieArgs.data.uid,
                mid: movieArgs.data.mid,
                email: movieArgs.data.email,
                invite: movieArgs.invite
            }
            await uploadFile(argsRef, JSON.stringify(argsObj));    
        }
        
        // upload face
        if(changes.face){
            console.log("upload face");
            const headRef = ref(getStorage(), path+"/head.png");
            const headBlob = await imageUtils.canv2blob(movieArgs.head.image);
            await uploadFile(headRef, headBlob);
        }
              
        // upload start flag
        // if(window.autoStart){ //set in /assets/modules/Config.js
        //     console.log("upload start flag");
        //     const startFlagRef = ref(getStorage(), path+"/start.flag");
        //     await uploadFile(startFlagRef, "start");
        // }

        return Promise.resolve();
    }
    catch(err){
        console.log(err);
        return Promise.reject(err);
    }
}

export default makeUpload;
