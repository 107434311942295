import React from "react";

const SectionTitle = ({ title, text, children }) => {
	return (
		<div className="mx-auto max-w-[706px] text-center my-10 section-title">
			<h2 className="mb-5 sm:mb-3 text-[32px] leading-[40px] sm:text-6xl sm:leading-[65px] max-sm:max-w-[280px] mx-auto">
				{title}
			</h2>
			<p className="sm:text-[22px] sm:leading-[37px] text-grey">{text}</p>
			{children}
		</div>
	);
};
export default SectionTitle;
