import { useState, useRef } from "react";
import { getFunctions, httpsCallable } from 'firebase/functions';
import { GoogleOAuthProvider } from '@react-oauth/google';
import utils from 'utils/utils';
import keys from 'utils/keys';

import Google from "./Google";
import Modal from "components/Modal";
import Loader from "components/Loader";
import Lottie from 'lottie-react';
import animationData from 'assets/lottie/email.json';

const clientId = (utils.isDev() || utils.isLocal())?keys.google.dev:keys.google.prod;

const Form = ({setEmailSent})=>{
    const sendLoginLink = httpsCallable(getFunctions(), 'sendLoginLink');
    const inputRef = useRef();
    const [wait, setWait] = useState();
    const [err, setErr] = useState();

    const handleSubmit = async(e) => {
        e.preventDefault();
        setWait(1);
        try{
            await sendLoginLink({email:inputRef.current.value})
            setEmailSent(true);
        }
		catch(err){
            setErr(err.message)
        }
        setWait(false); 
	}

    return(
        <>
            <form onSubmit={handleSubmit}>
                <div>
                    <input
                        ref={inputRef}
                        type="email"
                        name='email'
                        className="w-full border bg-white rounded-full shadow-shadow outline-none border-blue-default px-5 md:px-9 h-[59px] lg:h-[73px] mb-4 text-md"
                        placeholder="Enter your email"
                        required
                        disabled={wait}
                    />
                    <button
                        type="submit"
                        className="btn h-[59px] w-full max-md:text-md"
                        disabled={wait}
                    >
                        Submit
                        {wait==1 && <div className="absolute"><Loader size={10}/></div>}
                    </button>
                    
                </div>
            </form>
            <GoogleOAuthProvider clientId={clientId}>
                <Google setWait={setWait} setErr={setErr}/>
            </GoogleOAuthProvider>
            {err && <div className="mt-5 w-full text-center text-xl text-red-600">{err}</div>}
        </>
    )
}

const Done = ()=>{
    return(
        <div className="flex flex-col justify-center items-center">
            <div className="text-center">
            	We’ve sent you an email with a login link.
            </div>
            <Lottie
                animationData={animationData}
                loop={true}
                style={{ 
                    width: 200
                }}
            />
	    </div>
    )
}

const LoginModal = ({ open, setOpen }) => {
	const [emailSent, setEmailSent] = useState();

	return (
		<Modal
			open={open}
			setOpen={setOpen}
			className={emailSent ? "xl:py-10" : "xl:pt-10"}
		>
			<div className="text-center text-2xl border-b pb-4 mb-7">Login</div>
			<div className="max-w-[401px] mx-auto text-xl">
				{!emailSent ? <Form setEmailSent={setEmailSent}/> : <Done/>}
			</div>
		</Modal>
	);
};

export default LoginModal;
