import Movie from "routes/website/components/Movie";

const Overlay = ({uid, mid, setShowOverlay})=>{
    
    const handleClick = ()=>{
        setShowOverlay(false)
    }
    
    return(
        <div className="fixed top-0 left-0 w-[100vw] h-screen flex justify-center items-center bg-white bg-opacity-40 z-[1000] backdrop-blur-md">
            <div className="relative w-[800px] max-w-[95%] rounded-3xl px-2 py-4 md:px-10 md:py-10 flex justify-center items-center bg-white border border-blue-default z-[10]">
                <Movie uid={uid} mid={mid}/>
                <div className="w-10 h-10 flex justify-center items-center font-jua text-xl font-bold cursor-pointer absolute top-0 right-0 bg-white border-2 border-blue-default rounded-full translate-x-2 -translate-y-2" onClick={handleClick}>
                    X
                </div>
            </div>
            <div className="absolute top-0 left-0 w-full h-full" onClick={handleClick}/>
        </div> 
    )  
}

export default Overlay