import React from "react";
import Layout from "routes/website/components/Layout";

const PrivacyPolicy = () => {
	return (
		<Layout>
			<div className="pb-9 md:pb-[108px] pt-[161px] lg:pt-[223px]">
				<div className="container">
					<h1 className="text-center text-[42px] leading-[1.3] sm:text-6xl lg:text-7xl mb-[67px] lg:mb-[90px]">
						Privacy Policy
					</h1>
					<div className="md:text-[22px] md:leading-[33px] max-w-[843px] mx-auto">
						<ul className="flex flex-col gap-5 md:gap-[30px]">
							<li>
								<h3 className="text-[30px] leading-[1.3] md:text-[38px] md:leading-[48px] mb-5 md:mb-[30px]">
									Introduction
								</h3>
								<p className="mb-6">
									This Privacy Policy (the “Policy”) is part of our Terms of
									Use, and both documents should be read together. We process
									and collect personal data in accordance with the General Data
									Protection Regulation (GDPR) and other applicable data
									protection laws. At all times, you have control over your
									information and may withdraw consent to data processing. The
									entity responsible for your data (the “data controller”) is
									TOON KIDS LLC (the “Operator”), as further detailed below.
								</p>
                                <p className="mb-6">
									By using this service, you consent to the collection, use, and
									storage of your personal information as described in this
									Privacy Policy. If you do not agree with any part of this
									Policy, please do not use our services.
								</p>
							</li>

							<li>
								<h3 className="text-[30px] leading-[1.3] md:text-[38px] md:leading-[48px] mb-5 md:mb-[30px]">
									Key Points
								</h3>
								<ul className="flex flex-col gap-5 md:gap-[30px] text-grey pl-5">
									<li className="flex gap-2">
										<span className="w-2 h-2 bg-current rounded mr-1 translate-y-3"></span>
										<span className="w-0 flex-grow">
											<strong>Data Collection:</strong> We collect personal data
											that you provide (e.g., name, email) as well as non-personal
											data (e.g., IP address, device data).
										</span>
									</li>
									<li className="flex gap-2">
										<span className="w-2 h-2 bg-current rounded mr-1 translate-y-3"></span>
										<span className="w-0 flex-grow">
											<strong>User Rights:</strong> Under GDPR, you have rights
											to access, modify, delete, and transfer your data, among
											others.
										</span>
									</li>
									<li className="flex gap-2">
										<span className="w-2 h-2 bg-current rounded mr-1 translate-y-3"></span>
										<span className="w-0 flex-grow">
											<strong>Data Usage:</strong> We use your data to enhance
											services, perform research, and communicate about offers,
											among other purposes.
										</span>
									</li>
									<li className="flex gap-2">
										<span className="w-2 h-2 bg-current rounded mr-1 translate-y-3"></span>
										<span className="w-0 flex-grow">
											<strong>Data Sharing:</strong> With your consent, we may
											share your data with trusted third-party partners.
										</span>
									</li>
									<li className="flex gap-2">
										<span className="w-2 h-2 bg-current rounded mr-1 translate-y-3"></span>
										<span className="w-0 flex-grow">
											<strong>Data Security:</strong> We use security measures to
											protect your data but cannot guarantee complete immunity
											from breaches.
										</span>
									</li>
								</ul>
							</li>

							<li>
								<h3 className="text-[30px] leading-[1.3] md:text-[38px] md:leading-[48px] mb-5 md:mb-[30px]">
									Data Collection
								</h3>
								<p className="mb-6">
									During your use of the Website, we collect:
								</p>
								<ul className="flex flex-col gap-5 md:gap-[30px] text-grey pl-5">
									<li className="flex gap-2">
										<strong>Personal Information:</strong> Information you provide
										voluntarily, such as your name and email.
									</li>
									<li className="flex gap-2">
										<strong>User Content:</strong> Content you submit to us.
									</li>
									<li className="flex gap-2">
										<strong>Non-Personal Information:</strong> Location data, IP
										address, and device information gathered through cookies or
										pixels.
									</li>
								</ul>
							</li>

							<li>
								<h3 className="text-[30px] leading-[1.3] md:text-[38px] md:leading-[48px] mb-5 md:mb-[30px]">
									Your Rights Regarding Your Personal Information
								</h3>
								<p className="mb-6">
									Under GDPR, you have the following rights, subject to certain
									exemptions:
								</p>
								<ul className="flex flex-col gap-5 md:gap-[30px] text-grey pl-5">
									<li>
										<strong>Access:</strong> Receive a copy of your personal data.
									</li>
									<li>
										<strong>Rectification:</strong> Correct inaccurate
										information.
									</li>
									<li>
										<strong>Erasure:</strong> Request deletion of your data
										(“right to be forgotten”).
									</li>
									<li>
										<strong>Restriction:</strong> Limit processing of your data.
									</li>
									<li>
										<strong>Data Portability:</strong> Obtain your data or have it
										transmitted to another controller.
									</li>
									<li>
										<strong>Withdrawal of Consent:</strong> Revoke consent to data
										processing at any time.
									</li>
								</ul>
							</li>

							<li>
								<h3 className="text-[30px] leading-[1.3] md:text-[38px] md:leading-[48px] mb-5 md:mb-[30px]">
									Data Usage and Purposes
								</h3>
								<p className="mb-6">
									Your data is stored in our secure database and used for:
								</p>
								<ul className="flex flex-col gap-5 md:gap-[30px] text-grey pl-5">
									<li>Enabling seamless access and use of our Website.</li>
									<li>Improving services and content.</li>
									<li>Enforcing our Terms and communicating offers.</li>
									<li>
										Providing research data to third parties (anonymously).
									</li>
								</ul>
							</li>

							<li>
								<h3 className="text-[30px] leading-[1.3] md:text-[38px] md:leading-[48px] mb-5 md:mb-[30px]">
									Data Sharing
								</h3>
								<p className="mb-6">
									With your consent, we may share data with third parties:
								</p>
								<ul className="flex flex-col gap-5 md:gap-[30px] text-grey pl-5">
									<li>
										<strong>Trusted Providers:</strong> Business partners and
										service providers that help us improve functionality.
									</li>
									<li>
										<strong>Legal Requirements:</strong> To comply with legal
										obligations, we may share data as required.
									</li>
								</ul>
							</li>

							<li>
								<h3 className="text-[30px] leading-[1.3] md:text-[38px] md:leading-[48px] mb-5 md:mb-[30px]">
									Use of Cookies
								</h3>
								<p className="mb-6">
									We use cookies to enhance your online experience, storing data
									on your device to personalize services. Essential cookies are
									required for core website functionality.
								</p>
							</li>

							<li>
								<h3 className="text-[30px] leading-[1.3] md:text-[38px] md:leading-[48px] mb-5 md:mb-[30px]">
									Data Security
								</h3>
								<p className="mb-6">
									We implement measures to secure your data but cannot guarantee
									complete protection from unauthorized access. We will notify
									you in line with applicable law if a security breach occurs.
								</p>
							</li>

							<li>
								<h3 className="text-[30px] leading-[1.3] md:text-[38px] md:leading-[48px] mb-5 md:mb-[30px]">
									Minors
								</h3>
								<p className="mb-6">
									We do not knowingly collect information from children under 18
									without parental consent. Upon discovery, we will delete such
									data promptly.
								</p>
							</li>

							<li>
								<h3 className="text-[30px] leading-[1.3] md:text-[38px] md:leading-[48px] mb-5 md:mb-[30px]">
									Amendments and “Last Updated” Notice
								</h3>
								<p className="mb-6">
									Toon Kids may amend this Privacy Policy at any time, with
									changes effective immediately upon posting. We encourage you to
									check for updates. The latest update to this Privacy Policy was
									on <strong>November 2, 2024</strong>.
								</p>
							</li>

							<li>
								<h3 className="text-[30px] leading-[1.3] md:text-[38px] md:leading-[48px] mb-5 md:mb-[30px]">
									Contact Us
								</h3>
								<p className="mb-6">
									For questions about this Privacy Policy, please contact us at{" "}
									<a href="mailto:support@toonkids.com">support@toonkids.com</a>.
								</p>
							</li>
						</ul>
					</div>
					<h2 className="text-center text-[42px] leading-[1.3] sm:text-6xl mt-[61px] lg:mt-[108px]">
						End of Privacy Policy
					</h2>
				</div>
			</div>
		</Layout>
	);
};

export default PrivacyPolicy;
