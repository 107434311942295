import movieArgs from "routes/app/utils/movieArgs";
import FileSelector from "./imageComps/FileSelector";
import ImageSelected from "./imageComps/ImageSelected";
import { useEffect } from "react";

const ImageSelect = ({ imgSrc, setImgSrc, setView, setIsDisabled, setError }) => {
    
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                movieArgs.head.init = false;
                setImgSrc(reader.result);
                setIsDisabled(false);
                setView(2);
            };
            reader.readAsDataURL(file);
        }
    }

    const openFileDialog = () => {
        document.getElementById('fileInput').click();
    }

    useEffect(()=>{
        if(imgSrc) setIsDisabled(false);
        else setIsDisabled(true);
        setError('Please select a picture :)');
    },[])

    return (
        <div className="flex flex-col justify-center items-center">
            <h1 className="text-[30px] leading-[43px] sm:text-4xl md:text-6xl md:text-[40px] mb-[25px] md:mb-[30px] mx-auto sm:max-w-[700px] text-center">
                    Great! <br className="block sm:hidden" /> Now let’s add a face!
            </h1>
            <div
                className="relative w-full max-w-[300px] aspect-square flex flex-col items-center justify-center border-[4px] border-dashed border-gray-500 rounded-[40px] cursor-pointer bg-gray-100 hover:bg-gray-200 shadow-shadow overflow-hidden"
                onClick={openFileDialog}
            >
                {imgSrc?<ImageSelected imgSrc={imgSrc}/>:<FileSelector/>}
            </div>
            <input
                id="fileInput"
                type="file"
                accept="image/*"
                className="hidden"
                onChange={handleFileChange}
            />
        </div>
        
    );
};

export default ImageSelect;
