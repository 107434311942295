import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getFunctions, httpsCallable } from 'firebase/functions';
import editorUtils from "routes/app/utils/editorUtils";
import movieArgs from "routes/app/utils/movieArgs";
import Inner from "./components/Inner";
import MovieMakerLayout from "routes/app/components/MovieMakerLayout";
import SoloLayout from "routes/app/components/SoloLayout.jsx";

const getTransId = httpsCallable(getFunctions(), 'getTransId');
let transactionId;

const Checkout = () => {
    const nav = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const uid = queryParams.get('uid');
    const mid = queryParams.get('mid');
    const email = queryParams.get('email');

    const [backDisabled, setBackDisabled] = useState();
    const [wait, setWait] = useState(true)

    useEffect(()=>{
        const init = async()=>{
            if(!uid || !mid || !email) nav('/');
            else if(editorUtils.isSolo){
                movieArgs.data = {uid, mid, email}
                try{
                    const res = await getTransId({
                        uid: uid,
                        mid: mid
                    })
                    transactionId = res.data.transactionId;
                }
                catch(err){
                    console.error(err);
                }
            }
            else transactionId = movieArgs.data.transactionId;
            setWait(false);
        }
        init()
    },[])
	
    const inner = <Inner wait={wait} uid={uid} mid={mid} email={email} transactionId={transactionId} setBackDisabled={setBackDisabled} />
    
    if(editorUtils.isSolo) return(
        <SoloLayout>
            {inner}
        </SoloLayout>
    )
    else return(
		<MovieMakerLayout
            step={6}
            nextDisabled={true}
            backDisabled={backDisabled}
            error="Please use the checkout form."
        >
			{inner}
		</MovieMakerLayout>
	)
};
export default Checkout;
