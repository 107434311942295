import { useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import { getAuth, GoogleAuthProvider, signInWithCredential } from "firebase/auth";
import { Icons } from 'routes/website/components/Icons';

const Google = ({setWait, setErr})=>{
    const nav = useNavigate();
    const handleLoginSuccess = async(response) => {
        try{
            const accessToken = response['access_token'];
            const credential = GoogleAuthProvider.credential(null, accessToken);
            const userCredential = await signInWithCredential(getAuth(), credential); //https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#signinwithcredential
            const uid = userCredential.user.uid;
            nav('/my-movies?uid='+uid);
        }
        catch(err){
            setErr(err.message)   
        }
        setWait(false);
    }

    const handleLoginFailure = (err) => {
        setErr(err.message);
        setWait(false);
    };

    const login = useGoogleLogin({
        onSuccess: handleLoginSuccess,
        onError: handleLoginFailure
    });

    const handleGoogle = ()=>{
        setWait(true);
        login();
    }   

    return(
        <>
            <div className="flex items-center text-[#979797] gap-10 mt-7 mx-auto max-w-[387px]">
                <span className="h-[1px] bg-current opacity-30 w-0 flex-grow"></span>
                <span className="text-dark">Or</span>
                <span className="h-[1px] bg-current opacity-30 w-0 flex-grow"></span>
            </div>
            <div className="mt-7">
                <button
                    type="button"
                    className="flex justify-center gap-3 items-center h-[59px] lg:h-[80px] rounded-full border border-blue-default w-full shadow-shadow bg-white transform transition-transform duration-300 hover:scale-105"
                    onClick={handleGoogle}
                >
                    {Icons.google}
                    <span className="text-md lg:text-xl text-[inherit]">Sign in with Google</span>
                </button>
            </div>
        </>
    )
}

export default Google;