import { useState } from "react";
import { getFunctions, httpsCallable } from 'firebase/functions';
import Modal from "components/Modal";
import Input from "components/Input";
import Loader from "components/Loader";
import Error from "components/Error";
import Lottie from 'lottie-react';
import animationData from 'assets/lottie/love.json';

const sendShareEmail = httpsCallable(getFunctions(), 'sendShareEmail');

const EmailModal = ({uid, mid, setEmailModalOpen})=>{
    const [wait, setWait] = useState();
    const [err, setErr] = useState();
    const [sent, setSent] = useState();

    const [emailInfo, setEmailInfo] = useState({
		name: "",
		recipientName: "",
		recipientEmail: "",
		message: "",
	});
    
    const handleSubmit = async(e)=>{
        e.preventDefault();
        setWait(true);
        setErr(null)
        try{
            await sendShareEmail({
                uid: uid,
                mid: mid,
                fromName: emailInfo.name,
                toName: emailInfo.recipientName,
                email: emailInfo.recipientEmail,
                msg: emailInfo.message
            })
            setSent(true);
        }
        catch(err){
            setErr(err.message)
        }
        setWait(false);
    }

    return(
        <Modal open={true} setOpen={setEmailModalOpen}>
            {sent?
            <div className="flex flex-col justify-center items-center md:p-10">
                <div className="w-full font-jua text-3xl text-center leading-snug">
                    Your Personalized Birthday Animation Has Been Sent!
                </div>
                <Lottie
                    animationData={animationData}
                    loop={true}
                    style={{ 
                        width: 200
                    }}
                />
            </div>
            
            :
            <form className='p-0 md:p-10' onSubmit={handleSubmit}>
                <Input
                    placeholder="Your Name"
                    value={emailInfo.name}
                    className="mb-5 lg:mb-8"
                    onChange={(e) =>
                        setEmailInfo({ ...emailInfo, name: e.target.value })
                    }
                    disabled={wait}
                />
                <Input
                    placeholder="Recipient Name"
                    value={emailInfo.recipientName}
                    className="mb-5 lg:mb-8"
                    onChange={(e) =>
                        setEmailInfo({
                            ...emailInfo,
                            recipientName: e.target.value,
                        })
                    }
                    disabled={wait}
                />
                <Input
                    placeholder="Recipient Email"
                    value={emailInfo.recipientEmail}
                    className="mb-5 lg:mb-8"
                    onChange={(e) =>
                        setEmailInfo({
                            ...emailInfo,
                            recipientEmail: e.target.value,
                        })
                    }
                    disabled={wait}
                />
                <Input
                    textarea
                    placeholder="Your Message"
                    value={emailInfo.message}
                    className="mb-5 lg:mb-8"
                    onChange={(e) =>
                        setEmailInfo({ ...emailInfo, message: e.target.value })
                    }
                    disabled={wait}
                />
                <div className="relative flex justify-center items-center">
                                    <button
                                        type="submit"
                                        className="btn h-[59px] w-full max-md:text-md"
                                        disabled={wait}
                                    >
                                        {wait?'Sending':'Send'}
                                    </button>
                                    {wait && <div className="absolute"><Loader/></div>}
                                </div>
                <Error err={err}/>
            </form>}
        </Modal>
    )
}

export default EmailModal