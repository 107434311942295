import imageUtils from "routes/app/utils/imageUtils";

const canvas = document.createElement("canvas");
const tmpImg = new Image();
const finalImg = new Image();

export const loadFaceApi = async()=>{
    const loadScript = (path)=>{
        return new Promise((resolve,reject)=>{
            const script  = document.createElement("script");
            script.onload = ()=>{
                resolve();
            }
            script.onerror = (err)=>{
                reject(err);
            }
            script.src = path;
            document.head.appendChild(script);
        })
    }
    // face-api library docs - https://justadudewhohacks.github.io/face-api.js/docs/index.html
    await loadScript("/face-api/face-api.js");
    const modelsPath = '/face-api/models';
    await window.faceapi.loadTinyFaceDetectorModel(modelsPath);
    await window.faceapi.loadFaceLandmarkModel(modelsPath);
    console.log('Face api loaded.');
    window.faceApiLoaded = true;
    return Promise.resolve();
}
export const applyImageRotation = (imgSrc, rotation) => {
    return new Promise((resolve, reject)=>{
        if(rotation){
            tmpImg.onload = () => {
                const ctx = canvas.getContext("2d");
    
                // Swap canvas dimensions based on the current rotation
                if (rotation % 180 !== 0) {
                    canvas.width = tmpImg.height;
                    canvas.height = tmpImg.width;
                } else {
                    canvas.width = tmpImg.width;
                    canvas.height = tmpImg.height;
                }
    
                // Apply rotation on the canvas
                ctx.translate(canvas.width / 2, canvas.height / 2);
                ctx.rotate((rotation * Math.PI) / 180);
                ctx.drawImage(tmpImg, -tmpImg.width / 2, -tmpImg.height / 2);
    
                // DO NOT use canvas.toDataURL() here as it blocks memory on large images.
                const callback = async(blob)=>{
                    const newImgSrc = window.URL.createObjectURL(blob);
                    finalImg.onload = ()=>{
                        resolve({
                            newImgSrc,
                            finalImg
                        })
                    }
                    finalImg.src = newImgSrc;
                }
                canvas.toBlob(callback);
            }
            tmpImg.src = imgSrc;    
        }
        else{
            finalImg.onload = ()=>{
                resolve({
                    newImgSrc: imgSrc,
                    finalImg
                })
            }
            finalImg.src = imgSrc;
        }
    })
}
export const makeFace = (faceData)=>{    
    const makeThumb = (box)=>{
        const factor = 200/box.height; // restrict thumb height to 200px
        const width = box.width*factor;
        const height = box.height*factor;
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(finalImg,box.x,box.y,box.width,box.height,0,0,width,height);
        return canvas.toDataURL(); // canvas.toDataURL() can be used here because it's small images, DO NOT use in faceRotation.js -> rotateImage()
    }
    const thumb = makeThumb(faceData.alignedRect.box);
    return {
        thumb: thumb, // this is used in the next stage where the user chooses a face to use.
        data: faceData // this is used in /face_editor/faceEditor.js (after user chooses a face to use)
    }
}