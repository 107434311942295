import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Auth from "./components/Auth";
import Upload from "./components/Upload";
import Taste from "./components/Taste";
import Download from "routes/app/invites/download/Download";
import MovieMakerLayout from "routes/app/components/MovieMakerLayout";
import movieArgs from "routes/app/utils/movieArgs";

const Preview = () => {
    const invite = movieArgs.invite;
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const qView = queryParams.get('view');
    
    const [view, setView] = useState(qView?Number(qView):0);
    const [backDis, setBackDis] = useState();
    const [nextDis, setNextDis] = useState();
    const [error, setError] = useState();
    
    const views = [
        <Auth setView={setView} setBackDis={setBackDis} setNextDis={setNextDis} setError={setError}/>,
        <Upload setView={setView} setBackDis={setBackDis} setNextDis={setNextDis} setError={setError}/>,
        invite?<Download setBackDis={setBackDis} setNextDis={setNextDis}/>:<Taste setBackDis={setBackDis} setNextDis={setNextDis}/>
    ]
    
    return(
        <MovieMakerLayout
            step={5}
            backDisabled={backDis}
            nextDisabled={nextDis}
            error={error}
            handleBack={view==0?null:()=>{setView(0)}}
            handleNext={view==2?null:()=>{setView(1)}}
        >
            {views[view]}
        </MovieMakerLayout>
    )
};
export default Preview;
