import { useEffect, useState } from "react";
import { getDatabase, ref, onValue } from "firebase/database";
import Config from "utils/Config";
import utils from "utils/utils";
import Count from "./movieComps/Count";
import Vid from "./movieComps/Vid";
import Buttons from "./movieComps/Buttons";

const Movie = ({uid, mid, setWaitState}) => {
    const vidUrl = Config.storageDomain+'/users/'+uid+"/movies/"+mid+'/final.mp4';
    const thumbUrl = Config.storageDomain+'/users/'+uid+'/movies/'+mid+'/thumb.jpg';
    const [wait, setWait] = useState(true);
    const [startTime, setStartTime] = useState()

    useEffect(()=>{
        const init = async()=>{
            if( await utils.checkUrlExists(vidUrl) ) setWait(false);
            else{
                // started listener
                const startedRef = ref(getDatabase(), 'movies/'+uid+'/'+mid+'/started');
                const unsubStarted = onValue(startedRef, async(snapshot) => {
                    const startedFlag = snapshot.val();
                    if(startedFlag){
                        const res = await fetch(Config.storageDomain+'/users/'+uid+'/movies/'+mid+'/start.time');
                        const txt = await res.text();
                        console.log(txt);
                        setStartTime(Number(txt)*1000);
                        unsubStarted();
                    }
                });
                // finished listener
                const finishedRef = ref(getDatabase(), 'movies/'+uid+'/'+mid+'/finished');
                const unsubFinished = onValue(finishedRef, (snapshot) => {
                    const finished = snapshot.val();
                    if(finished){
                        setWait(false);
                        unsubFinished();
                    }
                });
            }
        }
        init();
    },[])
    useEffect(()=>{
        if(setWaitState) setWaitState(wait)
    },[wait])

    return (
        <div className="relative w-[95%] max-w-[700px] flex flex-col justify-center items-center">
            {wait?<Count startTime={startTime}/>:<Vid vidUrl={vidUrl} thumbUrl={thumbUrl}/>}
            <div className="my-5"/>
            <Buttons uid={uid} mid={mid} wait={wait}/>
        </div>
    )
}
export default Movie