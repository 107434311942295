const title = 'Make Every Birthday Unforgettable with Toon Kids’ Personalized Birthday eCards!';
const text = 'Toon Kids turns your child into the hero of their own custom birthday animated video adventure. Personalize each animated birthday card with their name, age, photo, and a unique avatar for a one-of-a-kind birthday experience. Start creating a memorable birthday animation today and make their special day truly unforgettable!';

const Description = () => {
  return (
    <div className="container relative z-10">
        <div className="max-w-[812px] mx-auto text-center pt-[75px] pb-[78px] lg:pt-[175px] lg:pb-[117px]">
            <h2 className="mb-5 sm:mb-3 text-[32px] leading-[40px] sm:text-6xl sm:leading-[65px] max-sm:max-w-[280px] mx-auto">
                {title}  
            </h2>
            <p className="sm:text-[22px] sm:leading-[37px] text-grey">
                {text}
            </p>
        </div>
    </div>
  )
}

export default Description