import { useRef, useState, useEffect } from "react";
import Poster from "./Poster";

const Vid = ({vidUrl, thumbUrl})=>{     
    const [play, setPlay] = useState()
    const vidRef = useRef()
    
    useEffect(()=>{
        if(play) vidRef.current.play();
    },[play])

    return(
        <div className="relative w-full flex flex-col justify-center items-center rounded-[40px]">
            {!play && <Poster thumbUrl={thumbUrl} setPlay={setPlay}/>}
            <video
                ref={vidRef}
                src={vidUrl}
                poster={thumbUrl}
                className="absolute w-full rounded-[inherit]"
                preload="auto"
                onContextMenu={(e) => e.preventDefault()}
                controls
            />
            <canvas width="1920" height="1080" className="w-full"/>
        </div>
    )
}

export default Vid;