import { useState, useEffect } from 'react';
import Loader from './Loader';

const ProgressBar = ({duration, style}) => {
    const [progress, setProgress] = useState(10);
    const [wait, setWait] = useState();

    useEffect(() => {
        // Calculate the interval time based on the duration and desired updates (in milliseconds)
        const intervalTime = (duration * 1000) / 100; // 100 updates over the duration
        const interval = setInterval(() => {
        setProgress((prev) => {
            // Increment the progress, but do not exceed 100
            if (prev < 100) return prev + 1;
            else {
                clearInterval(interval);
                setWait(true);
                return 100;
            }
        });
        }, intervalTime);

        // Cleanup the interval when the component unmounts
        return () => clearInterval(interval);
    }, [duration]);

    if(wait) return <Loader style={style}/>
    return (
        <div className={(style?"bg-gray-800":"bg-gray-200")+" w-full rounded-full h-4"}>
            <div
                className={(style?"bg-green-500":"bg-blue-600")+" h-4 rounded-full"}
                style={{ width: `${progress}%` }}
            />
            <p className={(style?"text-gray-300":"")+" mt-2 text-center"}>{progress}%</p>
        </div>
    )
};

export default ProgressBar;
