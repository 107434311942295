import { useState } from "react";
import { getFunctions, httpsCallable } from 'firebase/functions';
import ShareModal from "./ShareModal";
import EmailModal from "./EmailModal";
import Button from "./Button"
import download from "assets/webp/icons/download.svg";
import share from "assets/webp/icons/share.svg";
import Error from "components/Error";

const getMovDownloadLink = httpsCallable(getFunctions(), 'getMovDownloadLink');

const Buttons = ({uid, mid, wait})=>{
    const [emailModalOpen, setEmailModalOpen] = useState(false);
	const [shareModalOpen, setShareModalOpen] = useState(false);
    const [waitB, setWaitB] = useState()
    const [err, setErr] = useState()

    const handleDown = async()=>{
        setWaitB(true)
        try{
            const res = await getMovDownloadLink({uid: uid, mid:mid});
            const url = res.data.url;
            window.open(url,'_self');
        }
        catch(err){
            setErr(err)
        }
        setWaitB(false)
    }
    const handleShare = ()=>{        
        setShareModalOpen(true)
    }

    return(
        <div className="w-full flex flex-row flex-wrap justify-center items-center gap-1 md:gap-4">
            <Button label={'Download'} wait={wait} waitB={waitB}  action={handleDown} img={download}/>
            <Button label={'Share'} wait={wait} action={handleShare} img={share}/>
            {shareModalOpen && <ShareModal uid={uid} mid={mid} setShareModalOpen={setShareModalOpen} setEmailModalOpen={setEmailModalOpen}/>}
            {emailModalOpen && <EmailModal uid={uid} mid={mid} setEmailModalOpen={setEmailModalOpen}/>}
            <Error err={err}/>
        </div>
    )
}
export default Buttons;