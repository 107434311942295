import faceNotFound from "assets/webp/face-not-found.webp";
import { cn } from "utils/cn";
import vImg from 'assets/images/v.svg';
import { useEffect } from "react";

const FaceButton = ({ face, selectedFace, setSelectedFace, isNotFound }) => (
    <button
        type="button"
        className={cn(
            "relative rounded-[24px] shadow-shadow w-[calc(50%-10px)] max-w-[167px] md:max-w-[192px] overflow-hidden aspect-square border-[4px] border-dashed border-grey3 m-[-2px]",
            {
                "border-blue-default": selectedFace === face,
            }
        )}
        onClick={() => setSelectedFace(face)}
    >
        <img
            src={isNotFound ? faceNotFound : face.thumb}
            alt={isNotFound ? "Face not found" : "Selected face"}
            className="w-full h-full object-cover rounded-[inherit]"
        />
        <span className="face-select-icon">
            {selectedFace === face && <img className='w-[70%]' src={vImg}/>}
        </span>
    </button>
)

const FaceSelect = ({ faceList, setSelectedFace, selectedFace, setNextDisabled, setBackDisabled, setError }) => {
    useEffect(()=>{
        setError('Please select a face :)')
        setBackDisabled(false);
        setNextDisabled(selectedFace?false:true);
    },[selectedFace])
    return (
        <div>
            <h2 className="text-lg text-center md:text-2xl">
                Beautiful! Choose the face you want to use!
            </h2>
            <div className="text-sm text-center md:text-lg mb-5 md:mb-10">
                Can’t see the face you want? Click the ‘?’ box.
            </div>
            <div className="w-full max-w-[624px] bg-white rounded-[40px] border border-blue-default py-10 px-[28px] shadow-shadow mx-auto">
                <div className="flex flex-wrap gap-5 md:gap-6 justify-center">
                    {/* Face not found button */}
                    <FaceButton
                        face="not-found"
                        selectedFace={selectedFace}
                        setSelectedFace={setSelectedFace}
                        isNotFound={true}
                    />
                    {/* Dynamic face list */}
                    {faceList.map((face, index) => (
                        <FaceButton
                            key={index}
                            face={face}
                            selectedFace={selectedFace}
                            setSelectedFace={setSelectedFace}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default FaceSelect;
