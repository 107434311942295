import { Link } from "react-router-dom"
import React from 'react'

const MakePrev = () => {
  return (
    <div className="p-5 md:p-10 rounded-[40px] border border-blue-default text-center relative w-full max-w-[624px] mx-auto my-5 bg-white">
        <h1 className="mb-5 text-[30px] leading-[43px] sm:text-4xl md:text-6xl md:text-[40px] mx-auto sm:max-w-[700px]">
            Not sure yet?
        </h1>
        <Link
            className="text-lg md:text-xl font-semibold download-btn success transform transition-transform duration-300 hover:scale-105"
            to="/birthday-movie-maker/short-preview"
        >
            Get a FREE Preview Clip!
        </Link>
    </div>
  )
}

export default MakePrev