import Modal from "components/Modal";
import Config from "utils/Config";
import CopyButton from "./CopyButton";

import { MdEmail } from 'react-icons/md'
import { FaFacebook } from 'react-icons/fa';
import { FaWhatsapp } from 'react-icons/fa';
import { FaTwitter } from 'react-icons/fa'

const types = ['f','w','t']

const Button = ({type, url})=>{ 
    const icons = {
        [types[0]]: <FaFacebook size={30} style={{fill:'white'}}/>,
        [types[1]]: <FaWhatsapp size={30} style={{fill:'white'}}/>,
        [types[2]]: <FaTwitter size={30} style={{fill:'white'}}/>
    }
    const labels = {
        [types[0]]: 'Facebook',
        [types[1]]: 'WhatsApp',
        [types[2]]: 'Twitter'
    }
    const links = {
        [types[0]]: 'https://www.facebook.com/sharer/sharer.php?u=',
        [types[1]]: 'https://wa.me/?text=',
        [types[2]]: 'https://twitter.com/intent/tweet?text='
    }
    const doShare = ()=>{
        const link = links[type]+encodeURIComponent(url);
        window.open(link,'_blank');
    }
    return(
        <button type="button" className="btn w-full gap-2" onClick={doShare}>
            <span className="text-[inherit]">{labels[type]}</span>
            {icons[type]}
        </button>
    )
}

const ShareModal = ({uid, mid, setShareModalOpen, setEmailModalOpen})=>{
    
    const url = `${Config.domain}/share?uid=${uid}&mid=${mid}`;
    
    return <Modal setOpen={setShareModalOpen}>
        <div className="w-full flex flex-col gap-5 md:gap-8 text-white p-1 md:p-10">
            <button
                type="button"
                className="btn w-full gap-2"
                onClick={() => {
                    setEmailModalOpen(true);
                    setShareModalOpen(false);
                }}
            >
                <span className="text-[inherit]">Email</span>
                <MdEmail size={30} style={{fill:'white'}}/>
            </button>
            {types.map((type, index)=><Button key={index} type={type} url={url}/>)}
            <CopyButton textToCopy={url}/>
        </div>
    </Modal>
}
export default ShareModal;