const keys = {
    google:{
        prod:'472671633231-kdh2i30im7nksrf2itmcmvcue0j9jr5d.apps.googleusercontent.com',
        dev:'274910613203-c62kl5abnpd97ler012a7ueji0qjrnad.apps.googleusercontent.com'
    },
    firebase:{
        prod:{
            apiKey: "AIzaSyCU-f-2vP88YG6oTKxfdbpLtmj4FhFvNIE",
            authDomain: "toonkids.com",
            databaseURL: "https://toon-kids-prod.firebaseio.com",
            projectId: "toon-kids-prod",
            storageBucket: "storage.toonkids.com",
            messagingSenderId: "472671633231",
            appId: "1:472671633231:web:3c3bb65a570a5e994c0f3a"
        },
        dev:{
            apiKey: "AIzaSyCzT9f2bYxBVMs1OeFevB0LgCfzo6f5pfI",
            authDomain: "dev.toonkids.com",
            databaseURL: "https://toon-kids-dev.firebaseio.com",
            projectId: "toon-kids-dev",
            storageBucket: "storage-dev.toonkids.com",
            messagingSenderId: "274910613203",
            appId: "1:274910613203:web:8f02cd15b6f4e23e458e26"
        }
    }
}
export default keys;