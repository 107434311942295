const Title = ()=>{
    return(
        <div className="text-center mb-5">
            <h1 className="text-[30px] leading-[43px] sm:text-4xl md:text-6xl md:text-[40px] mx-auto sm:max-w-[800px]">
            	Perfect! Now let’s choose an avatar!
            </h1>
            <div className="text-sm md:text-xl">
            	Click the buttons at the corners to see the different options.
            </div>
		</div>
    )
}
export default Title;