import React from "react";
import BlogCard from "./BlogCard";
import posts from "../posts";

const BlogPosts = () => {
	return(
		<section className="pb-[86px] lg:pb-[152px] pt-[100px] lg:pt-[200px]">
			<div className="container ">
				<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
					{posts.map((post, index) => (
						<BlogCard key={index} post={post} />
					))}
				</div>
			</div>
		</section>
	)
};

export default BlogPosts;
