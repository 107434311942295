import { Link } from "react-router-dom";

const BlogCard = ({ post }) => {
	return (
		<Link to={`/blog/${post.slug}`} className="bg-white rounded-[40px] shadow-shadow group flex flex-col">
			<div className="rounded-[40px] overflow-hidden">
				<img
					src={post.img}
					className="w-full rounded-[40px] duration-300 group-hover:scale-105 aspect-[421/338] object-cover"
					alt=""
				/>
			</div>
			<div className="py-8 px-6 flex-grow flex flex-col">
				<div className="text-xl text-center mx-auto max-w-[347px] font-medium mb-9">
					{post.title}
				</div>
				<div className="mt-auto">
					<div
						to={`/blog/${post.slug}`}
						className="btn-outline w-full h-12"
					>
						Learn More
					</div>
				</div>
			</div>
		</Link>
	);
};

export default BlogCard;