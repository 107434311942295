const canvas = document.createElement('canvas');

const loadImage = (path)=>{
    return new Promise((resolve,reject)=>{
        const img  = new Image();
        img.onload = ()=>{
            resolve(img)
        }
        img.onerror = (err)=>{
            reject(err);
        }
        img.src = path;
    })
}
const canv2img = async(canvas)=>{
    return new Promise((resolve, reject)=>{
        try{
            const callback = (blob)=>{
                const newImgSrc = window.URL.createObjectURL(blob);
                const img = new Image();
                img.onload = ()=>{
                    resolve(img)
                }
                img.src = newImgSrc;
            }
            canvas.toBlob(callback);
        }
        catch(err){
            return reject(err)
        }
    })   
}
const blob2Hash = async(blob)=>{
    try{
        const arrayBuffer = await blob.arrayBuffer();
        const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
        return Promise.resolve(hashHex);
    }
    catch(err){
        return Promise.reject(err)
    }
}
const getCanvasHash = async(canvas)=>{
    return new Promise((resolve, reject) => {
        canvas.toBlob(async (blob) => {
            if (!blob) {
                reject("Failed to generate blob from canvas.");
                return;
            }
            try {
                const hashHex = await blob2Hash(blob)
                resolve(hashHex);
            } catch (error) {
                reject("Error generating hash: " + error);
            }
        });
    });
}

const img4src = (src)=>{
    return new Promise((resolve,reject)=>{
        const img  = new Image();
        img.onload = ()=>{
            resolve(img)
        }
        img.onerror = (err)=>{
            console.log(err);
            reject(err);
        }
        img.src = src;
    })
}
const img2data = (img,hq=true)=>{
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext("2d");
    ctx.resetTransform();
    ctx.clearRect(0,0,canvas.width,canvas.height);
    ctx.drawImage(img,0,0);
    var data;
    if(hq) data = canvas.toDataURL('image/png');
    else data = canvas.toDataURL('image/jpeg',0.8);
    ctx.clearRect(0,0,canvas.width,canvas.height);
    canvas.width = 0;
    canvas.height = 0;
    return data;
}
const img2blob = (img, quality)=>{
    return new Promise((resolve, reject)=>{
        try{
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext("2d");
            ctx.resetTransform();
            ctx.clearRect(0,0,canvas.width,canvas.height);
            ctx.drawImage(img,0,0);
            if(quality) canvas.toBlob(resolve,"image/jpeg",quality); // https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob
            else canvas.toBlob(resolve); // png is default
        }
        catch(err){
            reject(err);
        }
    })  
}
const canv2blob = (canvas, quality)=>{
    return new Promise((resolve, reject)=>{
        try{
            if(quality) canvas.toBlob(resolve,"image/jpeg",quality); // https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob
            else canvas.toBlob(resolve); // png is default
        }
        catch(err){
            reject(err);
        }
    })  
}
const src2blob = (src, quality)=>{
    return new Promise((resolve, reject)=>{
        try{   
            const img = new Image;
            img.onload = ()=>{
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext("2d");
                ctx.resetTransform();
                ctx.clearRect(0,0,canvas.width,canvas.height);
                ctx.drawImage(img,0,0);
                if(quality) canvas.toBlob(resolve,"image/jpeg",quality); // https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob
                else canvas.toBlob(resolve); // png is default
            };
            img.src = src;
        }
        catch(err){
            reject(err);
        }
    })  
}

const imageUtils = {loadImage, canv2img, img4src, img2data, img2blob, canv2blob, src2blob, getCanvasHash, blob2Hash}

export default imageUtils;