import movieArgs from "routes/app/utils/movieArgs";

const headExportData = movieArgs.head;
const drawStage = async(data)=>{
    const {stageRef, globals, hairColor, hairType, skin, clothes, top} = data;
    const canvas = stageRef.current;
    const headSize = 400;
    const factor = headSize/movieArgs.head.masterHeadSize;
    const headWidth = headExportData.width; // received from faceEditor.js -> makeFaceExport()
    const headYPos = 145;
    const earPosY = headExportData.eyes; // received from faceEditor.js -> makeFaceExport()
    //stage
    var x,y,width,height,img;
    const shiftY = 0;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0,0,canvas.width,canvas.height);
    //hair back
    img = globals.images.hair.back[ hairColor ][ hairType ];
    const hairSize = headWidth*1.1;
    const hairShift = (hairSize/3.4);
    x = (canvas.width/2)-(hairSize/2);
    y = headYPos-hairShift;
    width = height = hairSize;
    ctx.drawImage(img,x,y-shiftY,width,height);
    //avatar
    img = globals.images.clothes[movieArgs.type][ skin ][clothes];
    width = height = img.naturalWidth;
    x = (canvas.width/2)-(width/2);
    y = canvas.height-height;
    ctx.drawImage(img,x,y-shiftY,width,height);
    //left ear
    img = globals.images.earsL[skin];
    width = height = headSize/5;
    x = (canvas.width/2)-((headWidth*factor)/2)-(width/1.4);
    y = headYPos+(earPosY*factor)-(height/5);
    ctx.drawImage(img,x,y-shiftY,width,height);
    //right ear
    img = globals.images.earsR[skin];
    width = height = headSize/5;
    x = (canvas.width/2)+((headWidth*factor)/2)-(width/3.8);
    y = headYPos+(earPosY*factor)-(height/5);
    ctx.drawImage(img,x,y-shiftY,width,height);
    //head
    img = movieArgs.head.image; // FinalFaceImg is received from faceEditor.js -> makeFaceExport()
    x = (canvas.width/2)-(headSize/2);
    y = headYPos;
    width = height = headSize;
    ctx.drawImage(img,x,y-shiftY,width,height);
    //hair front
    img = globals.images.hair.front[ hairColor ][ hairType ];
    x = (canvas.width/2)-(hairSize/2);
    y = headYPos-hairShift;
    width = height = hairSize;
    ctx.drawImage(img,x,y-shiftY,width,height);
    //top
    img = globals.images.top[top];
    width = height = headWidth*0.6;
    const topShift = (height/2);
    x = (canvas.width/2)-(width/2);
    y = headYPos-topShift;
    ctx.drawImage(img,x,y-shiftY,width,height);
}

export default drawStage;