import {useEffect} from "react";
import movieArgs from "routes/app/utils/movieArgs";
import editorUtils from "routes/app/utils/editorUtils";
import utils from "utils/utils";
import {loadFaceApi, applyImageRotation, makeFace} from './findUtils/findUtils'
import Loader from "components/Loader";

if(utils.isEditor() && !editorUtils.isSolo) setTimeout(loadFaceApi,[10*1000])

const FindFaces = ({ imgSrc, rotation, setFaceList, setImgSrc, setRotation, setSelectedFace, setFinalImage, setView, setNextDisabled, setBackDisabled }) => {
    useEffect(()=>{
        const init = async()=>{
            // apply rotation
            const {finalImg, newImgSrc} = await applyImageRotation(imgSrc, rotation, setImgSrc);
            
            // check changed
            let imageChanged = !movieArgs.head.init || rotation;
            movieArgs.head.imageChanged = imageChanged;
            movieArgs.head.init = true;

            if(imageChanged){
                setRotation(0);
                setFinalImage(finalImg);
                setImgSrc(newImgSrc);
                setSelectedFace(false);
                while(!window.faceApiLoaded) await utils.sleep(0.5);
                const facesDataArray = await window.faceapi.detectAllFaces( finalImg,new window.faceapi.TinyFaceDetectorOptions() ).withFaceLandmarks();
                const faces = facesDataArray.map( faceData => makeFace(faceData) );            
                setFaceList(faces);
            }

            // set view
            setView(4);
        }
        setNextDisabled(true);
        setBackDisabled(true);
        init();
    },[])

    return(
        <div className="text-center">
            <h2 className="text-lg text-center md:text-2xl mb-4">Finding faces...</h2>
            <div className="bg-blue-400 relative flex flex-col justify-center items-center w-[90vw] max-w-[400px] h-[90vw] max-h-[400px] mx-auto shadow-2xl rounded-2xl overflow-hidden">
                <img
                    src={imgSrc}
                    alt="Uploaded face"
                    className="w-full h-full rounded-lg object-contain"
                    style={{
                        transform: `rotate(${rotation}deg)`,
                    }}
                />

                <div className="absolute inset-0 flex flex-col justify-center items-center bg-black bg-opacity-50">
                    {/* Use the Loader component */}
                    <Loader />
                    <h2 className="text-white text-xl font-semibold mt-4">Finding faces...</h2>
                </div>
            </div>
        </div>
        
    )
};

export default FindFaces;
