import Trailer from "./Trailer";
import rainbowPresents2 from "assets/webp/steps/step-3/rainbow-presents-2.webp";
import rainbowPresents1 from "assets/webp/steps/step-3/rainbow-presents.webp";
import step3shape1 from "assets/webp/steps/step-3/shape1.webp";
import step3shape2 from "assets/webp/steps/step-3/shape2.webp";
import step3shape3 from "assets/webp/steps/step-3/shape3.webp";
import step3shape4 from "assets/webp/steps/step-3/shape4.webp";
import SectionTitle from "./SectionTitle";

const title = "Step 3: Sit Back and Enjoy"
const text = "Leave the Magic to Us! In just minutes, we’ll create a high-quality animated movie starring your child. You’ll receive a link to your custom masterpiece by email, ready to download and share with loved ones!"

const Step3 = () => {    
    return (
        <div className="relative pt-[98px] lg:pt-[105px] pb-[120px] md:pb-[239px]">
            <img
                src={step3shape2}
                alt=""
                className="absolute left-0 2xl:left-9 top-0 max-xl:h-[388px] md:block hidden"
            />
            <img
                src={step3shape3}
                alt=""
                className="absolute left-0 top-0 md:hidden"
            />
            <img
                src={step3shape1}
                alt=""
                className="absolute right-0 top-0 max-lg:h-[288px] max-xl:h-[388px] translate-y-[-25%] sm:block hidden"
            />
            <img
                src={step3shape4}
                alt=""
                className="absolute top-[-37px] right-0 sm:top-0 max-lg:h-[288px] max-xl:h-[388px] translate-y-[-25%] sm:hidden"
            />
            <div className="container z-10 relative">
                <div className="step-3">
                    <SectionTitle title={title} text={text}/>
                </div>
                <div className="relative w-full m-auto max-w-[700px]">
                    <Trailer/>
                </div> 
                <div className="flex justify-center mt-16 lg:mt-20">
                    <a href="/birthday-movie-maker" target='_self'
                        className="btn uppercase btn-grow text-xl max-w-[500px] text-center"
                    >
                        Make Your Movie Now!
                    </a>
                </div>
            </div>
            <img
                src={rainbowPresents1}
                alt=""
                className="absolute top-full translate-y-[-75%] w-full presets-1"
            />
            <img
                src={rainbowPresents2}
                alt=""
                className="absolute sm:hidden top-full translate-y-[-55%] w-full presets-2"
            />
        </div>
    )
}

export default Step3