import good from "assets/images/edit/green.webp";
import bad from "assets/images/edit/red.webp";

const Examples = () => {
  return (
    <div className="flex flex-col justify-between w-[95px] md:w-[140px]">
        <img src={bad} alt="Bad" className="w-full rounded-xl md:rounded-3xl" />
        <img src={good} alt="Good" className="w-full rounded-xl md:rounded-3xl" />
    </div>
  );
};

export default Examples;
