import { errorToast } from "utils/toasts";
import Loader from "components/Loader";

const Button = ({label, wait, waitB, action, img})=>{
    const handleClick = ()=>{
        if(wait) errorToast('This will be enabled when the movie is ready.')
        else if(!waitB) action()
    }
    return(
        <button
            className={((wait || waitB)?'bg-gray-400':'bg-blue-default hover:bg-blue-700')+" relative flex justify-center items-center shadow-shadow download-btn flex-grow w-12 md:w-[180px] md:text-lg"}
            onClick={handleClick}
        >
            {waitB?'Downloading...':label}{" "}
            <img className="w-5 sm:w-auto" src={img} alt={label}/>
            {waitB && <div className="absolute"><Loader/></div>}
        </button>
    )
}
export default Button;