import React from "react";
import Layout from "routes/website/components/Layout";

const CookiePolicy = () => {
	return (
		<Layout>
			<div className="pb-9 md:pb-[108px] pt-[161px] lg:pt-[223px]">
				<div className="container">
					<h1 className="text-center text-[42px] leading-[1.3] sm:text-6xl lg:text-7xl mb-[67px] lg:mb-[90px]">
						Cookie Policy
					</h1>
					<div className="md:text-[22px] md:leading-[33px] max-w-[843px] mx-auto">
						<ul className="flex flex-col gap-5 md:gap-[30px]">
							<li>
								<h3 className="text-[30px] leading-[1.3] md:text-[38px] md:leading-[48px] mb-5 md:mb-[30px]">
									What are Cookies?
								</h3>
								<p className="mb-6">
									Cookies are small text files that are stored on your device
									(computer, smartphone, tablet) when you visit a website. They
									enable us to recognize your device, store your preferences, and
									enhance your experience on our Website.
								</p>
							</li>

							<li>
								<h3 className="text-[30px] leading-[1.3] md:text-[38px] md:leading-[48px] mb-5 md:mb-[30px]">
									How We Use Cookies
								</h3>
								<p className="mb-6">
									We use cookies to provide a better browsing experience,
									understand site usage, and deliver targeted advertising. Our
									site utilizes Cookie Script to manage user consent, allowing
									you to control your cookie preferences effectively. You can
									update your consent choices or disable cookies at any time
									using the Cookie Script banner available on our website.
								</p>
							</li>

							<li>
								<h3 className="text-[30px] leading-[1.3] md:text-[38px] md:leading-[48px] mb-5 md:mb-[30px]">
									Types of Cookies We Use
								</h3>
								<ul className="flex flex-col gap-5 md:gap-[30px] text-grey pl-5">
									<li className="flex gap-2">
										<span className="w-2 h-2 bg-current rounded mr-1 translate-y-3"></span>
										<span className="w-0 flex-grow">
											<strong>Essential Cookies:</strong> Necessary for the
											operation of the Website, enabling core features like
											security and accessibility. These cookies cannot be
											disabled.
										</span>
									</li>
									<li className="flex gap-2">
										<span className="w-2 h-2 bg-current rounded mr-1 translate-y-3"></span>
										<span className="w-0 flex-grow">
											<strong>Performance and Analytics Cookies:</strong> These
											cookies help us analyze how visitors interact with the
											Website, enhancing overall functionality and user
											experience.
										</span>
									</li>
									<li className="flex gap-2">
										<span className="w-2 h-2 bg-current rounded mr-1 translate-y-3"></span>
										<span className="w-0 flex-grow">
											<strong>Functional Cookies:</strong> These enable
											personalized features by remembering your preferences,
											such as language settings.
										</span>
									</li>
									<li className="flex gap-2">
										<span className="w-2 h-2 bg-current rounded mr-1 translate-y-3"></span>
										<span className="w-0 flex-grow">
											<strong>Targeting and Advertising Cookies:</strong> We use
											these cookies to display relevant ads and measure the
											effectiveness of our campaigns. They may also be used by
											third parties for ad personalization on other sites.
										</span>
									</li>
								</ul>
							</li>

							<li>
								<h3 className="text-[30px] leading-[1.3] md:text-[38px] md:leading-[48px] mb-5 md:mb-[30px]">
									Third-Party Cookies
								</h3>
								<p className="mb-6">
									We may allow third-party service providers, like Google
									Analytics, to set cookies on your device to help analyze
									Website performance and deliver targeted ads. These cookies are
									governed by the third parties' privacy policies.
								</p>
							</li>

							<li>
								<h3 className="text-[30px] leading-[1.3] md:text-[38px] md:leading-[48px] mb-5 md:mb-[30px]">
									How You Can Manage Cookies
								</h3>
								<p className="mb-6">
									You can manage your cookie preferences using the Cookie Script
									banner on our Website, where you can accept or reject
									different types of cookies.
								</p>
								<ul className="flex flex-col gap-5 md:gap-[30px] text-grey pl-5">
									<li className="flex gap-2">
										<span className="w-2 h-2 bg-current rounded mr-1 translate-y-3"></span>
										<span className="w-0 flex-grow">
											<strong>Browser Settings:</strong> Most browsers allow you
											to block cookies or delete them after visiting a website.
											Check your browser's settings to manage cookies.
										</span>
									</li>
									<li className="flex gap-2">
										<span className="w-2 h-2 bg-current rounded mr-1 translate-y-3"></span>
										<span className="w-0 flex-grow">
											<strong>Cookie Consent Tool:</strong> Upon visiting our
											Website, you may be presented with a banner to accept or
											customize your cookie preferences.
										</span>
									</li>
									<li className="flex gap-2">
										<span className="w-2 h-2 bg-current rounded mr-1 translate-y-3"></span>
										<span className="w-0 flex-grow">
											<strong>Opting Out of Third-Party Cookies:</strong> You may
											opt out of Google Analytics cookies by visiting their
											opt-out page. For targeted ads, consult the Digital
											Advertising Alliance’s opt-out platform.
										</span>
									</li>
								</ul>
							</li>

							<li>
								<h3 className="text-[30px] leading-[1.3] md:text-[38px] md:leading-[48px] mb-5 md:mb-[30px]">
									Changes to This Cookie Policy
								</h3>
								<p className="mb-6">
									We may update this Cookie Policy to reflect changes in our
									practices or for legal, technical, or regulatory reasons.
									Significant changes will be posted on our Website.
								</p>
							</li>

							<li>
								<h3 className="text-[30px] leading-[1.3] md:text-[38px] md:leading-[48px] mb-5 md:mb-[30px]">
									Contact Us
								</h3>
								<p className="mb-6">
									If you have any questions about our Cookie Policy or our data
									practices, please contact us at{" "}
									<a href="mailto:contact@toonkids.com">contact@toonkids.com</a>.
								</p>
							</li>
						</ul>
					</div>
					<h2 className="text-center text-[42px] leading-[1.3] sm:text-6xl mt-[61px] lg:mt-[108px]">
						End of Cookie Policy
					</h2>
				</div>
			</div>
		</Layout>
	);
};

export default CookiePolicy;
