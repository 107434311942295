import { useEffect, useState } from "react";
import movieArgs from "routes/app/utils/movieArgs";
import Chosen from "./authComps/Chosen";
import Login from "./authComps/Login";
import Wait from "./authComps/Wait";

const Auth = ({setView, setBackDis, setNextDis, setError}) => {
    const [email, setEmail] = useState('');
    const [wait, setWait] = useState();
    const [chosen, setChosen] = useState(false);
    const [err, setErr] = useState()
    
    useEffect(()=>{
        if(movieArgs.data){
            setEmail(movieArgs.data.email);
            setChosen(true);
        }
    },[])

    useEffect(()=>{
        setNextDis(!chosen);
    },[chosen])

    useEffect(()=>{
        setBackDis(wait);
        setError(wait?'Please wait..':'Please use the Submit or Google buttons.');
    },[wait])

    return (
        <div className="flex flex-col items-center relative">
            {chosen?
                <Chosen email={email} setChosen={setChosen}/>
                :
                (wait?
                    <Wait/>
                    :
                    <Login email={email} setEmail={setEmail} setWait={setWait} setView={setView} setErr={setErr}/>
                )
            }
            {err&&<div className="text-red-600 text-md md:text-lg text-center w-full">{err}</div>}
        </div>
    )
}

export default Auth