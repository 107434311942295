import stepShape5 from "assets/webp/steps/step-shape5.webp";
import fairy from 'assets/images/home/fairy.webp'
import jungle from 'assets/images/home/jungle.webp'
import fantasy from 'assets/images/home/fantasy.webp'
import SectionTitle from "./SectionTitle";

const title = 'Step 1: Pick a Story'
const text = "Is Your Child a Brave Dragon Rider or a Magical Fairy Princess? Let your child’s dreams come true with Toon Kids' personalized birthday adventures! From daring dragon riders to magical fairy princesses, we offer a variety of stories to create a unique birthday experience that celebrates your child’s imagination."
const Step1 = ()=>{
    return(
        <div className="relative z-10">
				<img
					src={stepShape5}
					alt=""
					className="absolute right-0 sm:hidden top-[-70px] sm:top-[-140px]"
				/>
				<div className="max-w-[1100px] w-[90%] m-auto relative z-10">
					<div className="step-1">
						<SectionTitle title={title} text={text} />
					</div>
					<div className="grid grid-cols-3 gap-2 lg:gap-5">
                        <img src={fairy} className="w-full" style={{ filter: 'drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.3))' }}/>
                        <img src={fantasy} className="w-full" style={{ filter: 'drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.3))' }}/>
                        <img src={jungle} className="w-full" style={{ filter: 'drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.3))' }}/>
					</div>
				</div>
			</div>
    )
}

export default Step1