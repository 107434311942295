import React from "react";
import img2 from "assets/images/wave2-mobile.webp";
import img1 from "assets/images/wave2.svg";
import client1 from "assets/webp/clients/1.webp";
import client2 from "assets/webp/clients/2.webp";
import client3 from "assets/webp/clients/3.webp";
import clientsBg from "assets/webp/clients/client-bg.webp";
import clouds from "assets/webp/clients/clouds.webp";
import wave1 from "assets/webp/clients/wave1.webp";
import { Icons } from "routes/website/components/Icons";
import useScreen from "components/useScreen";
const Clients = () => {
	const screen = useScreen();
	return (
		<section
			className="relative z-10"
			style={{
				background: `url(${clientsBg}) no-repeat center center / cover`,
			}}
		>
			<img
				src={wave1}
				className="absolute w-full -top-4 md:-top-10 lg:-top-16 left-0"
				alt=""
			/>
			<div className="relative overflow-hidden py-20 sm:py-[120px] md:py-[145px] lg:pt-[205px] lg:pb-[220px]">
				<img
					src={clouds}
					alt=""
					className="absolute top-[142px] md:top-[157px] right-0 lg:block max-w-[70%]"
				/>
				<img
					src={screen >= 575 ? img1 : img2}
					className="absolute w-full -bottom-12 md:-bottom-3 left-0"
					alt=""
					style={{
						filter: "drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.25))",
						WebkitFilter: "drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.25))",
					}}
				/>
				<div className="container relative z-10">
					<h2 className="max-w-[933px] mb-10 lg:mb-[87px] text-[38px] leading-[48px] sm:text-6xl lg:text-[56px] sm:leading-[65px] text-center md:text-start max-sm:mx-auto max-sm:max-w-[370px]">
						See what parents are saying about Toon Kids Animated Birthday
						eCards:
					</h2>
					<div className="grid grid-cols-1 gap-10 md:gap-5 md:grid-cols-2 lg:grid-cols-3">
						{data.map((item, index) => (
							<div key={index} className="text-center flex flex-col">
								<p className="text-sm sm:text-normal font-medium sm:leading-[31px] leading-[31px] text-grey py-8 px-6 rounded-[40px] rounded-br-none bg-white flex-grow before-clip-quote">
									{item.quote}
								</p>
								<div className="flex items-center justify-end gap-3 mt-5 text-right pr-6">
									<div>
										<h6 className="font-poppins text-md sm:text-lg lg:leading-[37px] font-semibold m-0">
											{item.name}
										</h6>
										{Icons.star}
									</div>
									<img
										src={item.img}
										className="w-[78px] aspect-square object-cover rounded-full"
										alt=""
									/>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</section>
	);
};
const data = [
	{
		quote: `“I ordered a personalized birthday ecard for my son's 5th birthday and it  was amazing! He was thrilled to see himself as the hero of the story  and the animation was top-notch. I highly recommend Toon Kids for anyone  looking for a unique and memorable way to celebrate their child's  birthday.”`,
		name: "Sarah W. - UK",
		img: client1,
	},
	{
		quote: `"I can't say enough good things about Toon Kids. Their birthday ecards  are a huge hit with my kids and their friends. They love seeing  themselves as the hero of the story and the messages are always positive  and uplifting. We've used Toon Kids for the past three birthdays and  plan on using them for many more!"`,
		name: "David K. - UK",
		img: client2,
	},
	{
		quote: `"My daughter loves Toon Kids! We've used them for her past two birthdays and she still talks about how much she loved seeing herself this way. The animations are so fun and engaging and the  messages are perfect for young children. I highly recommend Toon Kids to anyone looking for a special way to celebrate their child's birthday."`,
		name: "Emily L. -USA",
		img: client3,
	},
];
export default Clients;
