import { Routes, Route, Navigate } from "react-router-dom";
import movieArgs from "./utils/movieArgs";

import BirthdayMovieMaker from "./pages/BirthdayMovieMaker/BirthdayMovieMaker";
import ChooseStory from "./pages/ChooseStory/ChooseStory";
import AddFace from "./pages/AddFace/AddFace";
import ChooseAvatar from "./pages/ChooseAvatar/ChooseAvatar";
import NameAndAge from "./pages/NameAndAge/NameAndAge";
import Preview from "./pages/Preview/Preview";
import VidPrev from "./pages/VidPrev/VidPrev";
import Checkout from "./pages/Checkout/Checkout";

import InviteMaker from "./invites/welcome/InviteMaker";
import ChooseCard from "./invites/choose/ChooseCard";
import InviteInfo from "./invites/info/InviteInfo";

const invite = window.location.pathname.includes('invitation');
if(!invite) movieArgs.invite = false;

const appRoutes = ()=>{
    return(
        <Routes>
            <Route path="/birthday-movie-maker" element={<BirthdayMovieMaker />} />
            <Route path="/birthday-movie-maker/choose-a-story" element={<ChooseStory />} />
            <Route path="/birthday-movie-maker/add-a-face" element={<AddFace />} />
            <Route path="/birthday-movie-maker/choose-an-avatar" element={<ChooseAvatar />} />
            <Route path="/birthday-movie-maker/name-age" element={<NameAndAge />} />
            <Route path="/birthday-movie-maker/preview" element={<Preview/>} />
            <Route path="/birthday-movie-maker/short-preview" element={<VidPrev />} />
            <Route path="/birthday-movie-maker/get-full-movie" element={<Checkout />} />
            
            <Route path="/birthday-party-invitation-maker" element={<InviteMaker/>} />
            <Route path="/birthday-party-invitation-maker/choose-a-story" element={<ChooseCard/>} />
            <Route path="/birthday-party-invitation-maker/add-a-face" element={<AddFace />} />
            <Route path="/birthday-party-invitation-maker/choose-an-avatar" element={<ChooseAvatar />} />
            <Route path="/birthday-party-invitation-maker/name-age" element={<InviteInfo/>} />
            <Route path="/birthday-party-invitation-maker/preview" element={<Preview/>} />
            <Route path="/birthday-party-invitation-maker/short-preview" element={<VidPrev />} />
            <Route path="/birthday-party-invitation-maker/get-full-movie" element={<Checkout />} />
            
            <Route path="*" element={invite?<Navigate to="/birthday-party-invitation-maker" />:<Navigate to="/birthday-movie-maker" />} />
        </Routes>
    )
}

export default appRoutes