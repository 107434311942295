import { useEffect, useState } from "react";
import BlogCard from "./BlogCard";
import posts from "../posts";

const ReadNext = ({slug}) => {    
	const currentBlog = posts.find((post) => post.slug === slug);
	const [nextPosts, setNextPosts] = useState([]);
	
    useEffect(() => {
		const currentIndex = posts.indexOf(currentBlog);
		const nextPosts = [];
		for (let i = 1; i <= 3; i++) {
			const nextBlog = posts[(currentIndex + i) % posts.length];
			nextPosts.push(nextBlog);
		}
		setNextPosts(nextPosts);
	}, [slug]);

	return (
		<section className="pb-[86px] lg:pb-[152px] pt-[80px]">
			<div className="container ">
				<h2 className="text-[10.5vw] sm:text-6xl lg:text-7xl mb-[25px] lg:mb-[80px] text-center">
					What to read next
				</h2>
				<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
					{nextPosts.map((post, index) => (
						<BlogCard key={index} post={post} />
					))}
				</div>
			</div>
		</section>
	);
}

export default ReadNext;
