import { useRef, useState } from "react";
import { getFunctions, httpsCallable } from 'firebase/functions';
import Input from "components/Input";
import { errorToast } from "utils/toasts";

const Button = ({showPromo, setShowPromo})=>{
    return(
        <div className="mt-2 text-center text-md">
            <button
                type="button"
                onClick={()=>{setShowPromo(!showPromo)}}
            >
                {showPromo?'Back to payment':'I have a promo code'}
            </button>
        </div>
    )
}

const Form = ({uid, mid, email})=>{
    const inputRef = useRef()
    const [wait, setWait] = useState()
    const handleSubmit = async (evt)=>{
        evt.preventDefault();
        setWait(true);
        try{
            const checkPromCode = httpsCallable(getFunctions(), 'checkPromCode');
            const data = {
                code: inputRef.current.value,
                uid: uid,
                mid: mid
            }
            await checkPromCode(data);
            window.open("/congrats?uid="+uid+"&mid="+mid+"&email="+email, "_self");
        }
        catch(e){
            if(e.message=="Error: no-field") errorToast("This code has expired or been used.");
            else errorToast(e.message);
        }
        setWait(false);
    }
    return(
        <form onSubmit={handleSubmit}>
            <div className="mb-6 md:mb-8">
                <Input refT={inputRef} disabled={wait} placeholder="Enter Your Promo Code"/>
            </div>
            <div className="mb-6 md:mb-8">
                <button type="submit" className="btn w-full" disabled={wait}>
                    {wait?'Sending..':'Send'}
                </button>
            </div>
        </form>
    )
}

const Promo = ({showPromo, setShowPromo, uid, mid, email}) => {
    return(
        <div>
            {showPromo && <Form setShowPromo={setShowPromo} uid={uid} mid={mid} email={email}/>}
            <Button showPromo={showPromo} setShowPromo={setShowPromo}/>
        </div>
    )
}

export default Promo