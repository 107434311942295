import { useEffect } from "react";
import { Link } from "react-router-dom"
import Config from "utils/Config";
import movieArgs from "routes/app/utils/movieArgs";
import editorUtils from 'routes/app/utils/editorUtils'
import utils from "utils/utils";
import { HiDownload } from 'react-icons/hi';

const Img = ({type})=>{
    const handleDown = ()=>{
        utils.pushData("invite-download");
        const link = document.createElement('a');
        link.target = '_self';
        link.href = movieArgs.inviteDownLinks[type];
        link.click();
    }

    const src = `${Config.storageDomain}/users/${movieArgs.data.uid}/movies/${movieArgs.data.mid}/invites/${type}.jpg?ver=${movieArgs.prev}`;
    
    return(
        <div className="w-full relative cursor-pointer pointer-events-auto" onClick={handleDown}>
            <div className="absolute top-3 right-3 md:top-5 md:right-5 p-2 rounded-full bg-white border border-blue-default">
                <HiDownload size={50} className="fill-blue-500 hidden md:block"/>
                <HiDownload size={20} className="fill-blue-500 md:hidden"/>
            </div>          
            <img
                src={src}
                className="w-full rounded-[40px] shadow-shadow mt-9 border border-blue-default"
                alt="landscape"
            />
        </div>
    )
}
const Download = ({setBackDis, setNextDis}) => {
    useEffect(()=>{
        setBackDis(false);
        setNextDis(false);
    },[])
    
    return(
        <div>
            <div className="text-center">
                <h1 className="text-[30px] leading-[43px] sm:text-4xl md:text-6xl md:text-[40px] mx-auto sm:max-w-[700px]">
                    Your Party Invitations are ready!
                </h1>
                <p className="sm:text-md lg:text-xl max-w-[793px] mx-auto">
                    Click on an image to download it.<br/>
                    To make changes click the "Back" button.
                </p>
            </div>
            <div className="max-w-[624px] mx-auto my-5">
                <div className="flex flex-wrap justify-center items-center gap-1 md:gap-5">
                    <Link
                        className="text-center download-btn flex-grow w-[180px] transform transition-transform duration-300 hover:scale-105"
                        to={editorUtils.getCheckoutQuery()}
                    >
                        Get a Matching Personalized Animation Clip! ($4.99)
                    </Link>
                </div>
                <Img type="landscape"/>
                <Img type="portrait"/>
            </div>
        </div>
    )
}

export default Download