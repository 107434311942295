import {useEffect, useState} from "react";
import { getDatabase, ref, onValue } from "firebase/database";
import { getFunctions, httpsCallable } from 'firebase/functions';
import utils from "utils/utils";
import editorUtils from "routes/app/utils/editorUtils";
import movieArgs from "routes/app/utils/movieArgs";
import Wait from "./components/Wait";
import Ready from "./components/Ready";
import MovieMakerLayout from "routes/app/components/MovieMakerLayout";
import SoloLayout from "routes/app/components/SoloLayout.jsx";


const makeVidPrev = httpsCallable(getFunctions(), 'makeVidPrev');
const database = getDatabase();
let uid, mid;

const VidPrev = () => {
    const [wait, setWait] = useState(true)
    
    useEffect(()=>{
        const init = async()=>{
            uid = movieArgs.data.uid;
            mid = movieArgs.data.mid;            
            makeVidPrev({
                uid: uid,
                mid: mid
            })
            //register database listeners
            //status
            await utils.sleep(5);
            const statusRef = ref(database, 'prevs/'+uid+"/"+mid+'/status');
            const unsubStatus = onValue(statusRef, (snapshot) => {
                const status = snapshot.val();
                console.log(status);   
            });
            //urls
            const urlsRef = ref(database, 'prevs/'+uid+"/"+mid+'/urls');
            const unsubUrls = onValue(urlsRef, (snapshot) => {
                const urls = snapshot.val();                
                if(urls){
                    unsubUrls();
                    unsubStatus();
                    utils.setVidPrevMade(true);
                    setWait(false);        
                }
            });
        }
        if(!utils.getVidPrevMade()) init();
        else setWait(false);
    },[])
    
    if(editorUtils.isSolo) return(
        <SoloLayout>
            <div className="w-full max-w-[95%] relative flex flex-col justify-center items-center mb-20">
                {wait?<Wait/>:<Ready uid={uid} mid={mid}/>}
            </div>
        </SoloLayout>
    )
	
    return (
		<MovieMakerLayout 
            step={5}
            backDisabled={wait}
            nextDisabled={wait}
            vidprev={true}
            error="Please wait for the preview to finish :)"
        >
            <div className="w-full relative flex flex-col items-center">
                {wait?<Wait/>:<Ready uid={uid} mid={mid}/>}
            </div>
		</MovieMakerLayout>
	);
};
export default VidPrev;
