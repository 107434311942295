import React from "react";
import { Link } from "react-router-dom";
import blueBalloon from "assets/webp/anime/blue-balloon.webp";
import shape1 from "assets/webp/banner/shape1.webp";
import shape3 from "assets/webp/banner/shape3.webp";
import { faqIcons } from "routes/website/components/Icons";
import { cn } from "utils/cn";
const FaqsSection = () => {
	const [active, setActive] = React.useState("1");

	return (
		<>
			<section className="pt-[161px] lg:pt-[223px] overflow-hidden relative pb-[127px] lg:pb-[170px]">
				<div className="container relative z-10">
					<div className="max-w-[892px] px-6 mx-auto text-center pb-[55px] md:pb-[73px]">
						<h1 className="text-[42px] max-w-[480px] mx-auto sm:max-w-full leading-[1.3] sm:text-6xl lg:text-7xl">
							Check out the FAQ section
						</h1>
					</div>
				</div>
				<div className="relative z-10">
					<div className="container">
						<div className="bg-white rounded-2xl lg:rounded-[40px] border border-blue-default px-6 py-10 md:p-14 md:pb-[100px]">
							<div className="grid grid-cols-1">
								{data.map((faq, index) => (
									<div
										key={index}
										className={cn("border-b border-secondary")}
									>
										<button
											type="button"
											className={cn(
												"w-full font-normal py-5 md:py-10 flex justify-between items-center gap-3 text-left duration-300 font-jua text-2xl md:text-[38px] md:leading-[42px]",
												{ "md:pb-6": active === faq.id }
											)}
											onClick={() => {
												active === faq.id
													? setActive("")
													: setActive(faq.id);
											}}
										>
											<span className="w-0 flex-grow">
												{faq.title}
											</span>
											<span>
												{active === faq.id
													? faqIcons.minus
													: faqIcons.plus}
											</span>
										</button>
										<div
											className={cn("overflow-hidden h-0", {
												"h-[100%] duration-300 transition-[height]":
													active === faq.id,
											})}
										>
											<div className="pb-6 sm:pb-10 md:text-[22px] md:leading-[30px] text-grey max-w-[1030px]">
												<p>{faq.text}</p>
											</div>
										</div>
									</div>
								))}
							</div>
						</div>
						<div className="pt-[117px] lg:pt-[125px]">
							<h2 className="m-0 text-[38px] leading-[48px] sm:text-6xl lg:text-[56px] lg:leading-[65px] text-center mx-auto max-w-[980px]">
								Click the button below to make a magical birthday eCard
								for your kid!
							</h2>
							<div className="flex justify-center mt-12 lg:mt-16">
								<a href="/birthday-movie-maker" target='_self'
									className="btn uppercase w-full sm:max-w-[277px] xl:h-[70px] h-[60px]"
								>
									Start Now
								</a>
							</div>
						</div>
					</div>
					<img
						src={blueBalloon}
						alt=""
						className="blue-balloon absolute -bottom-28 left-12 xl:left-[calc(50%-700px)] pointer-events-none max-xl:w-[200px] max-xl:bottom-8"
					/>
				</div>
				<img
					src={shape1}
					className="absolute top-8 md:top-2 left-0 md:left-6 w-[170px] lg:w-[497px]"
					alt=""
				/>
				<img
					src={shape3}
					className="absolute top-[84px] lg:top-[128px] -right-16 md:-right-1 w-[175px] lg:w-[272px]"
					alt=""
				/>
			</section>
		</>
	);
};

const data = [
	{
		id: "1",
		title: "How long does it take to make a movie?",
		text: "Not long at all! You can have your own customized movie in as little as 04:00 minutes. That's shorter than the time it takes to brew a good cup of  coffee!",
	},
	{
		id: "2",
		title: "Do I need any knowledge in movies or animation to make one?",
		text: "Nope, not at all! Our movie maker is designed to be user-friendly and easy-peasy, so anyone can make a movie. You don't need any special skills or knowledge, even your grandma can make one!",
	},
	{
		id: "3",
		title: "Do I need to pay?",
		text: "We don't believe in hidden fees or sneaky charges. You can create a movie for free and watch a short preview on the house! If you want to access the Full Movie with the Share & Download options, it's only $4.99 USD. That's less than a fancy cupcake!",
	},
	{
		id: "4",
		title: "If I lose the copy of my movie and the email, can I still get it?",
		text: `Don't worry, we've got you covered! Simply log in using the "Login" link at the bottom of the page with your email address, and you'll be able to access all of your previously created movies. No need to remember any passwords or other details.`,
	},
	{
		id: "5",
		title: "Can I share my movie on Facebook, Whatsapp, Email Etc.?",
		text: `Absolutely! Once you've created your movie, you can easily share it on social networks, as well as via email and messaging apps like WhatsApp. Just click the share button and choose the platform you want to share it on. Your friends and family will love seeing your personalized movie!`,
	},
	{
		id: "6",
		title: "Do you offer any refunds?",
		text: `Oh, you betcha! If for any reason you're not completely over-the-moon with your personalized movie, just give us a holler within 30 days of purchase, and we'll happily refund your money. We take pride in making sure every single one of our customers is thrilled with their movie-making experience. So, no worries, friend! We've got you covered like a blanket on a chilly night.`,
	},
];

export default FaqsSection;
