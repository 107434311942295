export const renderer = ({ days, hours, minutes, seconds, completed }) => {
	if (!completed) {
		return (
			<div className="text-[inherit]">
				{minutes < 10 ? `0${minutes}` : minutes}:
				{seconds < 10 ? `0${seconds}` : seconds}
			</div>
		)
	}
};
