import Config from "utils/Config";
import utils from "utils/utils";
import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "components/ScrollToTop";
import websiteRoutes from "routes/website/websiteRoutes";
import AppRoutes from "routes/app/AppRoutes";
import Wait from "components/Wait";

const App = () => {
    const [wait, setWait] = useState(true);
    const [routes, setRoutes] = useState();

    useEffect(() => {
        // check routes
        if(utils.isEditor()){
            if(!window.location.pathname.includes('get-full-movie')){
                if(window.location.pathname.includes('movie-maker') && window.location.pathname !== '/birthday-movie-maker') window.open('/birthday-movie-maker','_self');
                else if(window.location.pathname.includes('invitation-maker') && window.location.pathname !== '/birthday-party-invitation-maker') window.open('/birthday-party-invitation-maker','_self');
            }
            setRoutes(AppRoutes);
        }
        else setRoutes(websiteRoutes);

        // When the page is fully loaded, set 'wait' to false
        window.onload = () => {
            setWait(false);
        };

        // Set a timeout as a fallback in case the onload event doesn't fire
        const timeoutId = setTimeout(() => {
            setWait(false);
        }, 3000); // Adjust the timeout duration as needed

        return () => clearTimeout(timeoutId); // Cleanup timeout on unmount
    }, []);

    return (
        <BrowserRouter>
            <ScrollToTop>
                <Toaster
                    position="top-right"
                    toastOptions={{
                        className: "text-md",
                        duration: 2000,
                        style: {
                            paddingInline: "24px",
                            fontWeight: "500",
                        },
                    }}
                />
                    {wait?<Wait />:routes}
            </ScrollToTop>
        </BrowserRouter>
    );
};

export default App;
