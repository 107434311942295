import RangeStyle from "./RangeStyle";

const RangeSlider = ({ type, value, setValue }) => {
  // Handle mouse up and touch end
  const handleMouseUpOrTouchEnd = () => {
    setValue(50);  // Reset value to middle (50) on mouse up or touch end
  };

  return (
    <>
      <RangeStyle type={type}/>
      <input
        type="range"
        min="1"
        max="100"
        value={value}
        className={"range-slider w-[80%] md:w-full my-5 md:my-0 " + (type === 1 ? 'icon-1' : 'icon-2')}
        onChange={(e) => setValue(Number(e.target.value))}
        onMouseUp={handleMouseUpOrTouchEnd}  // Reset slider on mouse up
        onTouchEnd={handleMouseUpOrTouchEnd}  // Reset slider on touch end
      />
    </>
  );
};

export default RangeSlider;
