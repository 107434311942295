import stepShape3 from "assets/webp/steps/step-shape3.webp";
import stepShape4 from "assets/webp/steps/step-shape4.webp";
import step2i1 from "assets/webp/steps/step-2/1.webp";
import step2i2 from "assets/webp/steps/step-2/2.webp";
import step2i3 from "assets/webp/steps/step-2/3.webp";
import step2i4 from "assets/webp/steps/step-2/4.webp";
import SectionTitle from "./SectionTitle";

const data = [
	{
		img: step2i4,
	},
	{
		img: step2i2,
	},
	{
		img: step2i3,
	},
	{
		img: step2i1,
	},
];

const Item  =({item, index})=>{
    return(
        <div
            key={index}
            className="relative rounded-lg xl:rounded-[40px] shadow-shadow2 sm:shadow-shadow bg-white flex flex-col justify-center items-center aspect-[312/425] border border-blue-default"
        >
            <img src={item.img} alt="" className="max-w-full" />
        </div>
    )
}

const title = "Step 2: Customize the Star"
const text = "This is where the Magic happens! Upload your child’s photo and customize their character with unique hairstyles, skin tones, and outfits. Toon Kids brings their birthday adventure to life, making them the hero in a personalized movie they’ll love!"
const Step2 = () => {
  return (
    <div className="relative pt-[95px] lg:pt-[75px] z-[9]">
        <img
        	src={stepShape3}
        	alt=""
        	className="absolute left-0 top-0 max-sm:w-[43.3%] sm:-translate-y-[50px] -translate-y-6"
        />
        <img
        	src={stepShape4}
        	alt=""
        	className="absolute right-0 top-0 max-sm:w-[47%] sm:-translate-y-[50px] -translate-y-6"
        />
        <div className="max-w-[1000px] m-auto relative z-10">
        	<SectionTitle
        		title={title}
        		text={text}
        	/>
        	<div className="max-sm:px-[6px]">
        		<div className="grid grid-cols-4 gap-2 lg:gap-5">
        			{data.map((item, index) => <Item key={index} index={index} item={item}/>)}
        		</div>
        	</div>
        </div>
	</div>
  )
}

export default Step2