import React from "react";

const useScreen = () => {
	const [screen, setScreen] = React.useState(window.innerWidth);
	React.useEffect(() => {
		const handleResize = () => {
			setScreen(window.innerWidth);
		};
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return screen;
};

export default useScreen;
