import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAuth, signInWithCustomToken, onAuthStateChanged, signOut } from "firebase/auth";
import { getStorage, ref, listAll, getMetadata } from "firebase/storage";
import utils from "utils/utils";

import Movies from "./components/Movies";
import Loader from "components/Loader";
import Error from "components/Error";
import Footer from "routes/website/components/Footer";

import additionalPageBg from "assets/webp/additional-page-bg.webp";
import logo from "assets/webp/logo.webp";
import shape1 from "assets/webp/banner/shape1.webp";
import shape3 from "assets/webp/banner/shape3.webp";

let uid, movies;

const MyMovies = () => {
    const [wait, setWait] = useState(true);
    const [err, setErr] = useState();

    useEffect(()=>{
        const init = async()=>{
            const checkIfFileExists = async (filePath) => {
                const storage = getStorage(); // Initialize storage
                const fileRef = ref(storage, filePath); // Reference to file path in the bucket
                try {
                  await getMetadata(fileRef); // Attempt to get file metadata
                  console.log("File exists");
                  return true; // File exists
                } catch (error) {
                  if (error.code === "storage/object-not-found") {
                    console.log("File does not exist");
                    return false; // File does not exist
                  } else {
                    console.error("Error checking file existence:", error);
                    throw error; // Handle other potential errors
                  }
                }
            }
            try{
                movies = [];
                uid = utils.getParam('uid')
                if(!uid){
                    await signOut(getAuth());
                    const token = utils.getParam('token');
                    const userCredential = await signInWithCustomToken(getAuth(), token);
                    uid = userCredential.user.uid;                    
                }
                const listRef = ref(getStorage(), 'users/'+uid+'/movies');
                const list = await listAll(listRef);                
                if(list.prefixes.length==0) throw {message:'No movies available.'};
                var count = 0;                
                list.prefixes.forEach(async (movie) => {
                    const exists = await checkIfFileExists(movie.fullPath+'/final.mp4');
                    if(exists) movies.push(movie.name);
                    count++;
                });
                while(count!=list.prefixes.length) await utils.sleep(0.5);
                setWait(false);                
            }
            catch(err){                
                setErr(err.message)
                setWait(false);
            }
        }    
        const unsubscribe = onAuthStateChanged(getAuth(), (currentUser) => {
            unsubscribe();
            init(); 
        });
    },[])

    return (
		<>
			<div
				className="relative w-full flex flex-col justify-center items-center pt-20 pb-40"
				style={{
					background: `url(${additionalPageBg}) no-repeat bottom center / cover`,
				}}
			>
				<div className="w-full">
					<Link
						to="/"
						className="block mx-auto w-[121px] md:w-[253px] mb-[54px]"
					>
						<img src={logo} className="w-full" alt="logo" />
					</Link>
					{wait?<Loader/>:<Movies movies={movies} uid={uid}/>}
                    <Error err={err}/>
				</div>
				<img
					src={shape1}
					className="absolute top-0 left-0 md:left-6 w-[126px] lg:w-[497px]"
					alt=""
				/>
				<img
					src={shape3}
					className="absolute top-0 right-0 md:-right-1 w-[105px] lg:w-[272px]"
					alt=""
				/>
			</div>
			<Footer />
		</>
	)
}

export default MyMovies