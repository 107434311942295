import React from 'react';

const WaveText = () => {
  const text = "We're on it!";
  // Split text into an array of letters, preserving spaces by adding space elements.
  const letters = text.split('').map((char, index) => (
    char === ' ' ? '\u00A0' : char
  ));

  return (
    <>
      <style>{`
        @keyframes wave {
          0%, 100% {
            transform: translateY(0);
          }
          50% {
            transform: translateY(-10px);
          }
        }
      `}</style>
      <h1 className="text-center text-5xl md:text-6xl leading-[43px]">
        {letters.map((letter, index) => (
          <span
            key={index}
            className="inline-block"
            style={{
              animation: `wave 1.3s ease-in-out ${index * 0.1}s infinite`
            }}
          >
            {letter}
          </span>
        ))}
      </h1>
    </>
  );
};

export default WaveText;
