import React from "react";
import gift1 from "assets/webp/anime/gift-1.webp";
import gift2 from "assets/webp/anime/gift-2.webp";
import Layout from "routes/website/components/Layout";
import BlogPosts from "./components/BlogPosts";
import BlogsBanner from "./components/BlogsBanner";
import BlogsBottom from "./components/BlogsBottom";
const Blogs = () => {
	return (
		<Layout>
			<div className="relative pb-24 md:pb-[80px]">
				<BlogsBanner />
				<BlogPosts />
				<BlogsBottom />
				<div className="absolute bottom-0 md:-bottom-[70px] lg:bottom-[-130px] overflow-hidden left-0 w-full h-[266px] xl:h-[350px] z-[11]">
					<img
						src={gift1}
						alt=""
						className="absolute left-0 bottom-3 md:bottom-5 z-10 w-[70px] md:w-[120px] lg:w-auto"
					/>
					<img
						src={gift2}
						alt=""
						className="absolute -right-8 md:right-0 bottom-1 z-10 w-[137px] md:w-[200px] lg:w-auto"
					/>
				</div>
			</div>
		</Layout>
	);
};

export default Blogs;
