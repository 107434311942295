import { useEffect, useState } from "react"
import utils from "utils/utils"

if(utils.isEditor()) utils.loadScript("https://cdn.paddle.com/paddle/v2/paddle.js");

const Paddle = ({uid, mid, email, transactionId, setBackDisabled})=>{
    const [wait, setWait] = useState();
    
    const eventCallback = (event)=>{
        if (event.name == "checkout.completed"){
            utils.pushData('purchase');
            window.open(`/thanks?uid=${uid}&mid=${mid}&email=${email}`, "_self");
        }
        else if(event.name == 'checkout.payment.initiated') setWait(true);
        else if(event.name == 'checkout.payment.failed' || event.name == 'checkout.error') setWait(false);
    }

    useEffect(()=>{
        setBackDisabled(wait);
    },[wait])
    
    const init = async()=>{
        // init
        while(!window.Paddle) await utils.sleep(0.5)
        let paddleKey = "live_fc9b149dbba78ee7d482c4d2e0e";
        let priceId = "pri_01j0treyfj28ptp2tcjzc2y0t5";
        if( utils.isDev() || utils.isLocal() ){
            paddleKey = "test_425b198d5b08050c87526eb9f61";
            window.Paddle.Environment.set("sandbox");
            priceId = "pri_01hyt1efkr9zqkg7ff6vg4n2dp";
            console.log("Paddle test env loaded.");
        }
        else{
            if( utils.isMe() ) priceId = "pri_01j1vcy3q05rh83v0krkvkcr5z";
            console.log("Paddle prod env loaded.");
        }
        window.Paddle.Initialize({
            token: paddleKey,
            eventCallback: eventCallback
        });
        // checkout settings
        const settings = { //https://developer.paddle.com/paddlejs/methods/paddle-checkout-open
            allowedPaymentMethods:[
                "card",
                "google_pay",
                "paypal",
                "apple_pay"
            ],
            showAddDiscounts: false,
            displayMode: "inline",
            frameTarget: "checkout-container",
            frameInitialHeight: "450",
            variant: "one-page",
            frameStyle: "width: 100%; background-color: transparent; border: none;"
        }
        const checkoutData = {
            settings: settings,
            transactionId: transactionId
        }
        // console.log(JSON.stringify(checkoutData, null, 4));

        // mount 
        // https://developer.paddle.com/paddlejs/methods/paddle-checkout-open
        window.Paddle.Checkout.open(checkoutData);
    } 
    useEffect(() => {
        init();
    }, []);
    
    // render
    return <div className="checkout-container"/>
}

export default Paddle;