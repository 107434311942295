import { useEffect, useState } from "react"
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useNavigate } from "react-router-dom"
import makeUpload from "./utils/makeUpload";
import movieArgs from "routes/app/utils/movieArgs";
import utils from "utils/utils";
import Gnomes from "components/Gnomes";
import Loader from "components/Loader";
import ProgressBar from "components/ProgressBar";
import Dots from "components/Dots";

const makePrevs = httpsCallable(getFunctions(), 'makePrevs');
let oldPoints, oldData, oldEmail;

const checkChange = async()=>{
    const makeData = ()=>{
        const type = movieArgs.type
        const {eyes, width, height} = movieArgs.head;
        const head = {eyes, width, height};
        const avatar = movieArgs.avatar;
        const name = movieArgs.name;
        const age = movieArgs.age;
        const cake = movieArgs.cake;
        const invite = movieArgs.invite;
        const data = {type, head, avatar, name, age, cake, invite}
        return JSON.stringify(data);
    }
    
    // check
    // movieArgs.head.imageChanged is set in findFaces.jsx -> init()
    // movieArgs.head.points is set in MaskEditor.js -> drawStage()
    const newData = makeData();
    const newPoints = JSON.stringify(movieArgs.head.points)
    
    const changes = {
        face: (oldEmail !== movieArgs.data.email || movieArgs.head.imageChanged || oldPoints !== newPoints),
        args: (oldEmail !== movieArgs.data.email || oldData !== newData)
    }
    movieArgs.head.imageChanged = false;

    // assign to old
    oldPoints = newPoints;   
    oldData = newData;
    oldEmail = movieArgs.data.email;
    
    // return
    return Promise.resolve(changes);
}

const Upload = ({setView, setNextDis, setBackDis}) => {
    const nav = useNavigate();
    
    const [wait, setWait] = useState(true);

    useEffect(()=>{
        const init = async()=>{            
            setBackDis(true);
            setNextDis(true);
            const changes = await checkChange();
            if(changes.face || changes.args){
                setWait(false);
                await makeUpload(changes);
                utils.setVidPrevMade(false);
                console.log('make thumb');
                const prevRes = await makePrevs({
                    uid: movieArgs.data.uid,
                    mid: movieArgs.data.mid
                })
                movieArgs.inviteDownLinks = prevRes.data.downLinks;
                movieArgs.prev++;
            }
            else if(utils.getVidPrevMade()) nav('/birthday-movie-maker/short-preview')
            setView(2);
        }    
        init();
    },[])

    if(wait) return <Loader/>
    return (
        <div className="flex flex-col justify-center items-center">
            <div className="text-center mb-5">
                <h1 className="text-[30px] leading-[43px] sm:text-4xl md:text-6xl md:text-[40px] mx-auto sm:max-w-[700px]">
                    Please Wait<Dots/>
                </h1>
                <p className="sm:text-md lg:text-xl  max-w-[326px] sm:max-w-[793px] mx-auto">
                    Getting everything set up for you! 🌈❤️
                </p>
            </div>
            <Gnomes width={300}/>
            <div className="m-5"/>
            <div className="w-full max-w-[500px]">
                <ProgressBar duration={20}/>
            </div>
        </div>
    )
}

export default Upload;