import { post_01 } from "./posts/post_01";
import { post_02 } from "./posts/post_02";
import { post_03 } from "./posts/post_03";
import { post_04 } from "./posts/post_04";
import { post_05 } from "./posts/post_05";
import { post_06 } from "./posts/post_06";
import { post_07 } from "./posts/post_07";
import { post_08 } from "./posts/post_08";

const posts = [
	post_01,
    post_02,
    post_03,
    post_04,
    post_05,
    post_06,
    post_07,
    post_08
]

export default posts;