import { useEffect, useRef, useState } from "react";
import Config from "utils/Config";
import editorUtils from 'routes/app/utils/editorUtils'
import movieArgs from "routes/app/utils/movieArgs";
import { Link } from "react-router-dom";
import playImg from 'assets/images/play.svg';

const Poster = ({setPlay, played})=>{
    const src = Config.storageDomain+'/users/'+movieArgs.data.uid+'/movies/'+movieArgs.data.mid+'/assets/thumb.jpg?ver='+movieArgs.prev;
    return(
        <div className="absolute flex flex-col justify-center items-center w-full">
            {!played && <img src={src} className="z-10 w-full rounded-[40px]"/>}
            <img src={playImg} className="z-20 pointer-events-auto w-[20%] absolute cursor-pointer transform transition-transform duration-300 hover:scale-105"
                    onClick={()=>{setPlay(true)}}
            />
        </div>
    )
}

const Ready = ({uid, mid}) => {
    const [play, setPlay] = useState(false);
    const [played, setPlayed] = useState(false);
    const vidRef = useRef();
    const url = Config.storageDomain+'/users/'+uid+"/movies/"+mid+'/prev.mp4';
    
    useEffect(()=>{
        if(play) vidRef.current.play();
        else vidRef.current.pause();
    },[play])
    
    return (
        <>
            <div className="text-center">
                <h1 className="text-[30px] leading-[43px] sm:text-4xl md:text-6xl md:text-[40px] mx-auto sm:max-w-[700px]">
                    Your short preview is ready!
                </h1>
                <Link to={editorUtils.getCheckoutQuery()}>
                    <p className="sm:text-md lg:text-xl max-w-[763px] mx-auto">
                        To get the <strong>Full High Quality 02:15 Minutes Movie</strong> with <strong>Share & Download options</strong> click the “<strong>Get Full Movie!</strong>” button.
                    </p>
                </Link>
            </div>
            <div className="max-w-[700px] mx-auto">
                <div className="flex flex-wrap justify-center items-center my-5">
                    <Link
                        className="flex-grow font-semibold text-md text-center md:text-xl download-btn px-8 transform transition-transform duration-300 hover:scale-105"
                        to={editorUtils.getCheckoutQuery()}
                    >
                        Get Full High Quality Movie for Only $4.99!
                    </Link>
                </div>
                <div className="relative w-full flex flex-col justify-center items-center">
                    {!play && <Poster setPlay={setPlay} played={played}/>}
                    <video
                        ref={vidRef}
                        src={url}
                        className="absolute w-full rounded-[40px]"
                        loop
                        preload="auto"
                        onClick={()=>{setPlay(!play)}}
                        onPlay={()=>{setPlayed(true)}}
                        onContextMenu={(e) => e.preventDefault()}
                    />
                    <canvas width="1920" height="1080" className="w-full"/>
                </div>
            </div>
        </>
    )
}

export default Ready