// Loader.js
import loaderWebmA from "assets/images/loader/loader_a.webm"
import loaderHevcA from "assets/images/loader/loader_a.mp4"
import loaderWebmB from "assets/images/loader/loader_b.webm"
import loaderHevcB from "assets/images/loader/loader_b.mp4"

const srcs = {
    a:{
        webm: loaderWebmA,
        hevc: loaderHevcA
    },
    b:{
        webm: loaderWebmB,
        hevc: loaderHevcB
    }
}
const Gnomes = ({width, type})=>{
    if(!type) type = "a";
    return (
        <video className="margin-10" autoPlay loop muted playsInline onContextMenu={(evt)=>{evt.preventDefault()}} style={{
            width: (typeof width === Number)?width+"px":width,
            height: "auto"
        }}>
            <source src={srcs[type].hevc} type='video/mp4; codecs="hvc1"'/>
            <source src={srcs[type].webm} type="video/webm"/>
        </video>
    )
}
export default Gnomes;

// end
