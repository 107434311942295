import { useEffect, useState } from "react";
import movieArgs from "routes/app/utils/movieArgs.js";
import Input from "components/Input";
import vImg from 'assets/images/v.svg';
import MovieMakerLayout from "routes/app/components/MovieMakerLayout.jsx";

import cake1 from "assets/webp/cake/1.webp";
import cake2 from "assets/webp/cake/2.webp";
import cake3 from "assets/webp/cake/3.webp";

const cakes = [
    cake1,
    cake2,
    cake3
]
const ages = [
	...Array.from({ length: 120 }, (_, i) => (i + 1)),
];

const Cake = ({index, cake, cakeSelected, setCakeSelected})=>{
    return(
        <div
            className={((index === cakeSelected)?"border-dashed border-[3px]":'border-[1px]')+" border-blue-default shadow-lg max-w-[142px] mx-auto relative cursor-pointer rounded-xl pt-3 pb-2"}
            onClick={() => setCakeSelected(index)}
        >
            <div className="face-select-icon top-2">
                {index === cakeSelected && <img src={vImg} className="w-[50%]"/>}
            </div>
            <img
                src={cake}
                alt="cake"
                className="w-full object-contain"
            />
        </div>
    )
}

const NameAndAge = () => {
	const [name, setName] = useState(movieArgs.name || '');
	const [age, setAge] = useState(movieArgs.age || 'Age');
	const [cakeSelected, setCakeSelected] = useState(movieArgs.cake);
    const [error, setError] = useState()
    
    useEffect(()=>{
        if (!name) {
            setError("Please enter your kids name.");
        } else if (age=='Age') {
            setError("Please enter your kids age.");
        } else if (cakeSelected===null) {
            setError("Please select a cake.");
        }
        movieArgs.name = name;
        movieArgs.age = age;
        movieArgs.cake = cakeSelected;
    },[name, age, cakeSelected])

	return (
		<MovieMakerLayout
			step={4}
			nextDisabled={!name || age=='Age' || cakeSelected===null}
			error={error}
		>
			<div>
				<div className="text-center mb-10">
					<h1 className="text-[30px] leading-[43px] sm:text-4xl md:text-6xl md:text-[40px] mx-auto sm:max-w-[800px]">
						Almost done!
					</h1>
					<div className="text-sm md:text-xl">
						Enter the Name, Age and choose a cake.
					</div>
				</div>
				<div className="py-10 px-6 max-w-[624px] rounded-[40px] border border-blue-default relative mx-auto">
					<div className="">
						<div className="flex gap-6 md:px-10">
							<div className="md:text-lg w-[60%] flex-grow">
								<Input
									type="text"
									placeholder="Name"
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
							</div>
							<div className="md:text-lg w-[40%] flex-grow">
								<Input
									type="number"
									placeholder="Age"
									value={age}
									onChange={(e) => setAge(e.target.value)}
									options={ages}
								/>
							</div>
						</div>
						<div className="grid grid-cols-3 gap-4 mt-10">
							{cakes.map((cake, index) => <Cake key={index} index={index} cake={cake} cakeSelected={cakeSelected} setCakeSelected={setCakeSelected}/>)}
						</div>
					</div>
				</div>
			</div>
		</MovieMakerLayout>
	);
};

export default NameAndAge;
