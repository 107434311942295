import shape1 from "assets/webp/banner/shape1.webp";
import shape3 from "assets/webp/banner/shape3.webp";

import fai_l from "assets/images/invitation/fai_l.webp";
import fai_p from "assets/images/invitation/fai_p.webp";
import unicorn from "assets/images/invitation/unicorn.webp";

import dragon from "assets/images/invitation/dragon.webp";
import fan_l from "assets/images/invitation/fan_l.webp";
import fan_p from "assets/images/invitation/fan_p.webp";

import hipo from "assets/images/invitation/hipo.webp";
import jun_l from "assets/images/invitation/jun_l.webp";
import jun_p from "assets/images/invitation/jun_p.webp";

import useScreen from "components/useScreen";
import { cn } from "utils/cn";

const data = [
	{
		vertical: fai_l,
		horizontal: fai_p,
		icon: unicorn,
		title: "Fairytale Magic",
	},
	{
		vertical: fan_l,
		horizontal: fan_p,
		icon: dragon,
		title: "Knights & Dragons",
	},
	{
		vertical: jun_l,
		horizontal: jun_p,
		icon: hipo,
		title: "Jungle Adventures",
	},
]

const Card = ({index, item})=>{
    return(
        <a
			href="/birthday-party-invitation-maker"
            target='_self'
			className="flex flex-wrap md:flex-nowrap justify-center gap-10 py-[39px] max-md:pt-6 px-8 sm:px-[46px] bg-white rounded-[20px] shadow-shadow border border-blue-default transition-all duration-300 hover:bg-blue-default group"
		>
            <div className="md:w-[569px] flex flex-col md:flex-col-reverse">
                <div
                    className={cn(
                        "flex items-center gap-5 justify-center md:translate-y-5 mb-4 md:mb-0",
                        {
                            "flex-row-reverse": index % 2 === 1,
                        }
                    )}
                >
                    <div className="sm:w-[107px] sm:h-[107px] relative max-md:w-20">
                        <img
                            src={item.icon}
                            alt=""
                            className="md:absolute md:top-1/2 md:left-1/2 md:-translate-x-1/2 md:-translate-y-1/2 max-w-[unset] max-md:h-[95px]"
                        />
                    </div>
                    <h5 className="text-[28px] leading-1 sm:text-[30px] sm:leading-[68px] word-nowrap group-hover:text-white">
                        {item.title}
                    </h5>
                </div>
                <img
                    src={item.vertical}
                    className="max-w-full mx-auto rounded-[20px] shadow-shadow"
                    alt=""
                />
            </div>
            <div className="md:w-[272px]">
                <img
                    src={item.horizontal}
                    className="max-w-full mx-auto rounded-[20px] shadow-shadow"
                    alt=""
                />
            </div>
        </a>
    )
}

const InvitationsBanner = () => {
	const screen = useScreen();
	return (
		<>
			<section className="pt-[161px] lg:pt-[223px] overflow-hidden relative pb-[107px] lg:pb-[240px]">
				<div className="container relative z-10">
					<div className="max-w-[892px] px-6 mx-auto text-center pb-[55px] md:pb-[73px]">
						<h1 className="text-[42px] max-w-[480px] mx-auto sm:max-w-full leading-[1.3] sm:text-6xl lg:text-7xl mb-[25px] md:mb-9">
							Free <br className="sm:hidden" /> Personalized Kids
							Birthday Party Invitation with Name, Face & Age!
						</h1>
						<p className="text-md md:text-xl mb-[25px] md:mb-9 max-w-[484px] mx-auto">
							Lots of clothes, hair styles & skin colors to choose from!
						</p>
						<h5 className="text-[38px] leading-[48px] md:text-[46px] md:leading-[65px]">
							Pick the card you want to personalize:
						</h5>
					</div>
				</div>
				<div className="container relative z-10 px-4 sm:px-6">
					<div className="flex flex-col gap-10 max-w-[975px] mx-auto">
						{data.map((item, index) => <Card key={index} index={index} item={item}/>)}
					</div>
				</div>
				<img
					src={shape1}
					className="absolute top-8 md:top-2 left-0 md:left-6 w-[170px] lg:w-[497px]"
					alt=""
				/>
				<img
					src={shape3}
					className="absolute top-[84px] lg:top-[128px] -right-16 md:-right-1 w-[175px] lg:w-[272px]"
					alt=""
				/>
			</section>
		</>
	);
};

export default InvitationsBanner;
