import React from "react";
import { cn } from "utils/cn";
import { Icons } from "../routes/website/components/Icons";

const Modal = ({ setOpen, className, children }) => {
	return (
		<div className="fixed top-0 left-0 w-full h-full flex flex-wrap justify-center items-center p-6 z-[100000] duration-300">
			<div
				className="absolute inset-0 z-10 bg-white bg-opacity-80"
				onClick={() => {setOpen(false)}}
			></div>
			<div
				className="w-full max-w-[624px] bg-white z-[11] scale-100 duration-300 rounded-[40px]">
				<div
					className={cn(
						"rounded-[40px] border border-blue-default pt-[75px] p-10 max-h-[90vh] overflow-y-auto",
						{ [className]: className }
					)}
				>
					<button
						type="button"
						className="absolute right-5 top-5 md:right-8 md:top-8 text-blue-default border border-blue-default rounded-full"
						onClick={() => {setOpen(false)}}
					>
						{Icons.clear}
					</button>
					{children}
				</div>
			</div>
		</div>
	);
};

export default Modal;
