import React, { useState } from 'react';
import { FiLink } from 'react-icons/fi';

const CopyButton = ({textToCopy}) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        setCopied(true); // Show "Copied!" message

        // Hide the message after 1.5 seconds
        setTimeout(() => setCopied(false), 1500);
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
  };

  return (
    <div className="relative flex justify-center items-center">
      <button className="btn w-full gap-2" onClick={handleCopy}>
            Copy Link <FiLink style={{ stroke: 'white', strokeWidth: 3 }} className=" inline mr-2" />
      </button>
      <span
        className={`absolute select-none pointer-events-none text-white transform text-sm transition-all duration-500 ease-in-out 
          ${copied ? 'opacity-100 translate-x-24' : 'opacity-0 translate-x-10'}`}
      >
        Copied!
      </span>
    </div>
  );
};

export default CopyButton;
