import Header from "./Header";
import EditControls from "./EditControls";
import Examples from "./Examples";
import MaskEditor from "./MaskEditor";

const Normal = ({finalImg, selectedFace, editMode, setEditMode})=>{
    return(
        <div>
            <Header editMode={editMode} />
            <div className="mb-4">
                <EditControls
                    editMode={editMode}
                    setEditMode={setEditMode}
                />
            </div>
            <div className="relative w-full flex flex-row justify-center">
                <Examples />
                <div className="m-1 md:m-2" />
                <MaskEditor
                    finalImg={finalImg}
                    selectedFace={selectedFace}
                    editMode={editMode}
                    setEditMode={setEditMode}
                />
            </div>
        </div>
    )
}

export default Normal