import Footer from "routes/website/components/Footer"
import logo from "assets/webp/logo.webp";
import additionalPageBg from "assets/webp/additional-page-bg.webp";
import shape1 from "assets/webp/banner/shape1.webp";
import shape3 from "assets/webp/banner/shape3.webp";

const SoloLayout = ({children}) => {
  return (
    <div className="relative w-full flex flex-col justify-center items-stretch overflow-hidden" style={{background: `url(${additionalPageBg}) no-repeat bottom center / cover`,}}>
        <div className="flex flex-col items-center pt-10 md:pt-20 pb-40">
            <a href="/" target='_blank' className="block mx-auto w-[121px] md:w-[253px]">
                <img src={logo} className="w-full" alt="logo" />
            </a>
            {children}
        </div>
        <Footer/>
        <img
			src={shape1}
			className="absolute top-0 left-0 md:left-6 w-[126px] lg:w-[497px]"
			alt="confetti_left"
		/>
        <img
			src={shape3}
			className="absolute top-0 right-0 md:-right-1 w-[105px] lg:w-[272px]"
			alt="confetti_right"
		/>
    </div>
  )
}

export default SoloLayout