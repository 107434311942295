import { useEffect, useState } from "react";
import movieArgs from "routes/app/utils/movieArgs.js";
import { cn } from "utils/cn";
import MovieMakerLayout from "../../components/MovieMakerLayout";

import fai_l from "assets/images/invitation/fai_l.webp";
import fai_p from "assets/images/invitation/fai_p.webp";
import unicorn from "assets/images/invitation/unicorn.webp";

import dragon from "assets/images/invitation/dragon.webp";
import fan_l from "assets/images/invitation/fan_l.webp";
import fan_p from "assets/images/invitation/fan_p.webp";

import hipo from "assets/images/invitation/hipo.webp";
import jun_l from "assets/images/invitation/jun_l.webp";
import jun_p from "assets/images/invitation/jun_p.webp";

const data = [
	{
        type:'fairytale',
		vertical: fai_l,
		horizontal: fai_p,
		icon: unicorn,
		title: "Fairytale Magic",
	},
	{
        type:'fantasy',
		vertical: fan_l,
		horizontal: fan_p,
		icon: dragon,
		title: "Knights & Dragons",
	},
	{
        type:'jungle',
		vertical: jun_l,
		horizontal: jun_p,
		icon: hipo,
		title: "Jungle Adventures",
	},
]

const Card = ({index, item, selectedType, setSelectedType})=>{
    const handleClick = ()=>{
        setSelectedType(item.type)
    }
    return(
        <div
			className={((selectedType && selectedType===item.type)?'bg-blue-default border-4 border-dashed border-cyan-300':'bg-white border border-blue-default')+" cursor-pointer flex flex-wrap md:flex-nowrap justify-center gap-10 py-[39px] max-md:pt-6 px-8 sm:px-[46px] rounded-[20px] shadow-shadow border-blue-default transition-all duration-300 hover:bg-blue-default group"}
            onClick={handleClick}
		>
            <div className="md:w-[569px] flex flex-col md:flex-col-reverse">
                <div
                    className={cn(
                        "flex items-center gap-5 justify-center md:translate-y-5 mb-4 md:mb-0",
                        {
                            "flex-row-reverse": index % 2 === 1,
                        }
                    )}
                >
                    <div className="sm:w-[107px] sm:h-[107px] relative max-md:w-20">
                        <img
                            src={item.icon}
                            alt=""
                            className="md:absolute md:top-1/2 md:left-1/2 md:-translate-x-1/2 md:-translate-y-1/2 max-w-[unset] max-md:h-[95px]"
                        />
                    </div>
                    <h5 className={((selectedType && selectedType===item.type)?'text-white':'')+" text-[28px] leading-1 sm:text-[30px] sm:leading-[68px] word-nowrap group-hover:text-white"}>
                        {item.title}
                    </h5>
                </div>
                <img
                    src={item.vertical}
                    className="max-w-full mx-auto rounded-[20px] shadow-shadow"
                    alt=""
                />
            </div>
            <div className="md:w-[272px]">
                <img
                    src={item.horizontal}
                    className="max-w-full mx-auto rounded-[20px] shadow-shadow"
                    alt=""
                />
            </div>
        </div>
    )
}

const ChooseCard = () => {
	const [selectedType, setSelectedType] = useState(movieArgs.type);

    useEffect(()=>{        
        if(selectedType && selectedType!==movieArgs.type){
            movieArgs.type = selectedType;
            movieArgs.avatar.avatars = 0;
        }
    },[selectedType])

	return (
		<MovieMakerLayout
			step={1}
			nextDisabled={!selectedType}
			error="Please choose a card"
		>
			<div>
				<div className="text-center">
					<h1 className="text-[30px] leading-[43px] sm:text-4xl md:text-6xl md:text-[40px] mb-[25px] md:mb-[30px] mx-auto sm:max-w-[700px]">
						First let's choose a card!
					</h1>
				</div>
				<div className="flex flex-col gap-10 max-w-[700px] mx-auto">
                    {data.map((item, index) => <Card key={index} index={index} item={item} selectedType={selectedType} setSelectedType={setSelectedType}/>)}
				</div>
			</div>
		</MovieMakerLayout>
	);
};

export default ChooseCard;
