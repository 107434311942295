import React from "react";
import { Link } from "react-router-dom";
const BlogsBottom = () => {
	return (
		<section className="relative pb-[68px] z-[20]">
			<div className="container">
				<h2 className="m-0 text-[38px] leading-[48px] sm:text-6xl lg:text-[56px] lg:leading-[65px] text-center mx-auto max-w-[1046px]">
					Let's make your child's birthday celebration one they'll never
					forget!
				</h2>
				<div className="flex justify-center mt-12 lg:mt-16">
					<a href="/birthday-movie-maker" target='_self'
						className="btn uppercase w-full sm:max-w-[277px] xl:h-[70px] h-[60px]"
					>
						Start Now
					</a>
				</div>
			</div>
		</section>
	);
};

export default BlogsBottom;
