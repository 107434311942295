import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import MovieMakerLayout from "../../components/MovieMakerLayout";
import MakeMask from "./components/MakeMask";
import FaceSelect from "./components/FaceSelect";
import ImageSelect from "./components/ImageSelect";
import ImageRotation from "./components/ImageRotation";
import FindFaces from "./components/FindFaces";

let imgSrc, finalImg, selectedFace, faceList;

const AddFace = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [view, setView] = useState(1);
    const [FaceSelected, setFaceSelected] = useState(selectedFace);
    const [isDisabled, setIsDisabled] = useState();
    const [backDisabled, setBackDisabled] = useState(false);
    const [error, setError] = useState();
    const [rotation, setRotation] = useState(0);

    const setImgSrc = (img)=>{
        imgSrc = img;
    }
    const setFinalImage = (img)=>{
        finalImg = img;
    }
    const setSelectedFace = (face)=>{
        selectedFace = face;
        setFaceSelected(selectedFace)
    }
    const setFaceList = (faces)=>{
        faceList = faces;
    }

    const handleBack = ()=>{
        switch(view){
            case 2:
                setView(1)
                break;
            case 3:
                break;
            case 4:
                setView(2)
                break;
            case 5:                
                setView(4)
                break;
            default:
                break;
        }
    }
    const handleNext = async()=>{
        switch(view){
            case 1:
                if (!imgSrc) {
                    setError("Please select an image to continue.");
                    return;
                }
                setView(2)
                break;
            case 2:
                setView(3)
                break;
            case 3:
                break;
            case 4:
                if (!selectedFace && faceList.length) {
                    setError("Please select a face to continue.");
                    return;
                }
                setView(5)
                break;
            default:
                break;
        }
    }
    
    useEffect(()=>{
        const viewParam = queryParams.get('view');
        if(viewParam) setView(Number(viewParam));
    },[])

    return(
        <MovieMakerLayout
            step={2}
            handleNext={(view<5)?handleNext:null}
            handleBack={(view>1)?handleBack:null}
            nextDisabled={isDisabled}
            backDisabled={backDisabled}
            error={error}
        >
            <div>
                {view==1 && <ImageSelect
                    imgSrc={imgSrc}
                    setImgSrc={setImgSrc}
                    setView={setView}
                    setIsDisabled={setIsDisabled}
                    setError={setError}
                />}
                {view==2 && <ImageRotation
                    imgSrc={imgSrc}
                    setImgSrc={setImgSrc}
                    rotation={rotation}
                    setRotation={setRotation}
                    setIsDisabled={setIsDisabled}
                />}
                {view==3 && <FindFaces
                    imgSrc={imgSrc}
                    setImgSrc={setImgSrc}
                    setFinalImage={setFinalImage}
                    rotation={rotation}
                    setRotation={setRotation}
                    setFaceList={setFaceList}
                    setSelectedFace={setSelectedFace}
                    setView={setView}
                    setNextDisabled={setIsDisabled}
                    setBackDisabled={setBackDisabled}
                />}
                {view==4 && <FaceSelect
                    faceList={faceList}
                    setSelectedFace={setSelectedFace}
                    selectedFace={FaceSelected}
                    setNextDisabled={setIsDisabled}
                    setBackDisabled={setBackDisabled}
                    setError={setError}
                />}
                {view==5 && <MakeMask
                    finalImg={finalImg}
                    selectedFace={selectedFace}
                    setNextDisabled={setIsDisabled}
                    setBackDisabled={setBackDisabled}
                    setError={setError}
                />}
            </div>
        </MovieMakerLayout>
    )
};

export default AddFace;
