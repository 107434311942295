import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "routes/website/components/Footer";
import Lottie from 'lottie-react';
import confettiAnim from "assets/lottie/confetti.json";
import animationData from 'assets/lottie/anim.json';
import additionalPageBg from "assets/webp/additional-page-bg.webp";
import shape1 from "assets/webp/banner/shape1.webp";
import shape3 from "assets/webp/banner/shape3.webp";
import logo from "assets/webp/logo.webp";

const Thanks = () => {
    const nav = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const uid = queryParams.get('uid');
    const mid = queryParams.get('mid');
    const email = queryParams.get('email');
    const [showConf, setShowConf] = useState(true)
    
    useEffect(()=>{
        if(!uid || !mid || !email) nav('/');
    },[])

    return (
		<>
			<section
				className="relative pt-[66px] lg:pt-[70px] pb-[200px] md:pb-[230px] overflow-hidden"
				style={{
					background: `url(${additionalPageBg}) no-repeat bottom center / cover`,
				}}
			>
				<div className="container">
					<Link
						to="/"
						className="block mx-auto w-[121px] md:w-[253px] mb-[54px]"
					>
						<img src={logo} className="w-full" alt="" />
					</Link>
					<div className="text-center">
						<h1 className="text-[30px] leading-[43px] sm:text-4xl md:text-6xl md:text-[40px] mx-auto max-w-[305px] sm:max-w-[700px]">
							Thank you! <br /> Welcome to the Toon Kids Family :)
						</h1>
                        <div className="flex justify-center items-center w-full h-20 overflow-hidden my-5">
                            <Lottie
                                animationData={animationData}
                                loop={true}
                                style={{ 
                                    width: 400,
                                    height: 400,
                                    position: "absolute"
                                }}
                            />
                        </div>
						<Link className="relative z-50" to={`/congrats?uid=${uid}&mid=${mid}&email=${email}`}>
                            <div className="btn md:h-[59px] w-full max-w-[365px] md:max-w-[400px] cursor-pointer">
                                Continue to movie
                            </div>	
						</Link>
					</div>
				</div>
				<img
					src={shape1}
					className="absolute top-0 left-0 md:left-6 w-[126px] lg:w-[497px]"
					alt=""
				/>
				{/* <img
					src={blueBalloon}
					className="absolute top-[135px] lg:top-[254px] -left-7 lg:left-[110px] max-lg:w-[90px] hidden md:block"
					alt=""
				/> */}
				<img
					src={shape3}
					className="absolute top-0 right-0 md:-right-1 w-[105px] lg:w-[272px]"
					alt=""
				/>
			</section>
			<Footer />
            {showConf && <div className="absolute w-full -top-10 md:-top-56 left-0 ">
                <Lottie
                        animationData={confettiAnim}
                        loop={false}
                        style={{width: '100%'}}
                        onComplete={()=>{setShowConf(false)}}
                />
            </div>}
		</>
	);
};

export default Thanks;
