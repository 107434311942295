import { useEffect } from "react";
import MovieMakerLayout from "routes/app/components/MovieMakerLayout.jsx";
import utils from "utils/utils";
import inviteFont from "assets/fonts/Invite.woff";

import redBalloon from "assets/webp/anime/red-ballon.webp";
import shape1 from "assets/webp/banner/shape1.webp";
import shape3 from "assets/webp/banner/shape3.webp";
import blueBalloon from "assets/webp/editors/blue-balloon.webp";
import fairy from "assets/webp/editors/fairy.webp";
import welcome from "assets/webp/editors/welcome.webp";

if(utils.isEditor()){
    const myFont = new FontFace("Invite", 'url('+inviteFont+')');
    myFont.load().then(()=>{
        document.fonts.add(myFont);
        console.log("invite font loaded.");
    });
}

const InviteMaker = () => {
    useEffect(()=>{
        window.editorInit = true;
    },[])
	return (
		<MovieMakerLayout step={0} backDisabled={true}>
			<div>
				<div className="text-center">
					<h1 className="text-[30px] leading-[43px] sm:text-4xl md:text-6xl md:text-[40px] mb-[25px] md:mb-[30px] mx-auto max-w-[305px] sm:max-w-[700px]">
						Welcome To Our Birthday Party Invitation Maker!
					</h1>
					<div className="sm:text-md lg:text-xl max-sm:mx-auto">
						<div className="max-sm:max-w-[236px] m-auto mb-2">
							Turn your child into the star of their own Birthday Party Invitation!
						</div>
						<div className="sm:mt-4 max-sm:text-[13px]">
							{'Click the “Next” button to start ->'}
						</div>
					</div>
					<img src={welcome} alt="" className="max-w-full mx-auto" />
				</div>
			</div>
			{/* <img
				src={shape1}
				className="absolute top-[161px] lg:top-2 left-0 md:-left-14 w-[126px] lg:w-[497px] z-[-1]"
				alt=""
			/> */}
			<img
				src={blueBalloon}
				className="absolute top-[345px] lg:top-[254px] -left-3 lg:left-[110px] max-lg:w-[120px] z-[-1]"
				alt=""
			/>
			{/* <img
				src={shape3}
				className="absolute top-[161px] lg:top-[128px] right-0 md:-right-1 w-[105px] lg:w-[272px] z-[-1]"
				alt=""
			/> */}
			<img
				src={fairy}
				className="absolute top-[540px] md:top-[135px] max-lg:w-[90px] z-[-1] right-[calc(50%+240px)] max-lg:right-[unset] max-lg:left-2 max-sm:hidden"
				alt=""
			/>
			<img
				src={redBalloon}
				className="absolute top-[370px] lg:top-[300px] max-lg:w-[90px] z-[-1] left-[calc(50%+330px)] lg:left-[calc(50%+430px)] max-lg:left-[unset] max-lg:-right-2"
				alt=""
			/>
		</MovieMakerLayout>
	);
};

export default InviteMaker;
