import React from "react";
import { Link } from "react-router-dom";
import mobileFooterShape from "assets/images/about/about-footer-mobile.webp";
import footerShape from "assets/images/about/about-footer-web.webp";
import flyingCartoon from "assets/webp/anime/flying-cartoon.webp";
import greenBalloon from "assets/webp/anime/green-balloon.webp";
import shape2 from "assets/webp/banner/shape4.webp";
import shape1 from "assets/webp/banner/shape5.webp";
import wave1 from "assets/webp/clients/wave1.webp";
import useScreen from "components/useScreen";
const AboutText = () => {
	const screen = useScreen();
	return (
		<section className="pt-[140px] lg:pt-[220px] relative">
			{/* <div className="w-full absolute -top-4 md:-top-10 lg:-top-16 xl:-top-24 left-0">
				<img
					src={wave1}
					className="absolute w-full top-0 left-0 min-w-[780px] z-10"
					alt=""
				/>
			</div> */}
			<div className="container">
                <h1 className="relative z-10 text-center text-[10.5vw] leading-[1.3] sm:text-6xl lg:text-7xl mb-[25px] md:mb-9">
						Welcome, Amazing Parents!
					</h1>
				<div className="flex flex-col gap-[30px] lg:gap-[60px] mb-[50px] lg:mb-[77px] mx-auto max-w-[375px] sm:max-w-[778px] md:text-[22px] md:leading-[37px] text-center text-[#5C5C5C]">
					<div className="">
						We know the quest: each year, you're searching for that unique
						touch to make your child's birthday stand out. Look no
						further! Our personalized birthday ecard animations are here
						to sprinkle that extra bit of magic—captivating adventures
						without the fuss and muss.
					</div>
					<div className="">
						Crafted with passion and precision, our team of expert
						designers and animators have conjured up ecards that transport
						your child into worlds of wonder. Dive into a treasure trove
						of themes—from enchanting fairytales to thrilling jungles and
						mystical dragons.
					</div>
					<div className="">
						Customization? It's a delightful breeze! Choose an avatar that
						mirrors your little star, and watch as their beaming face
						lights up the animation, making them the true hero of their
						special day.
					</div>
					<div className="">
						Concerned about navigating the digital realm? Fret not. Our
						platform boasts user-friendly charm, ensuring that creating
						magic is a joy for everyone—whether you're tech-savvy or a
						complete newbie. And should you ever find yourself in a maze
						of questions, our top-notch customer service team is on
						standby, ready to guide you with a smile.
					</div>
					<div className="">
						So, why wait? Join us in crafting unforgettable birthday
						moments that will be cherished for years to come.
					</div>
				</div>
				<h2 className="m-0 text-[38px] leading-[48px] sm:text-6xl lg:text-[56px] lg:leading-[65px] text-center mx-auto max-w-[700px]">
					Let’s make this birthday one for the books!
				</h2>
				<div className="flex justify-center mt-12 lg:mt-16">
					<a href="/birthday-movie-maker" target='_self' className="btn uppercase w-full sm:max-w-[277px] xl:h-[70px] h-[60px]">
						Start Now
					</a>
				</div>
			</div>
			<img
				src={screen > 576 ? footerShape : mobileFooterShape}
				className="w-full mt-20 md:mt-0"
				alt=""
			/>
			<img
				src={shape1}
				className="absolute top-8 md:top-20 left-0 md:left-6 w-[160px] lg:w-[309px]"
				alt=""
			/>
			<img
				src={shape2}
				className="absolute top-[84px] lg:top-[128px] -right-16 md:-right-1 w-[165px] lg:w-[272px]"
				alt=""
			/>
			<img
				src={greenBalloon}
				className="absolute lg:top-[420px] xl:top-[640px] left-3 lg:w-[140px] xl:w-auto md:left-auto md:right-[calc(50%+340px)] 2xl:right-[calc(50%+360px)]"
				alt=""
			/>
			<img
				src={flyingCartoon}
				className="absolute lg:top-[420px] right-3 lg:w-[140px] xl:w-auto md:right-auto md:left-[calc(50%+340px)]"
				alt=""
			/>
		</section>
	);
};

export default AboutText;
