import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import editorUtils from "routes/app/utils/editorUtils";
import utils from "utils/utils";
import movieArgs from "routes/app/utils/movieArgs";
import init from "./utils/init";
import drawStage from "./utils/drawStage";
import Title from "./comps/Title";
import Button from "./comps/Button";
import Overlay from "./comps/Overlay";
import MovieMakerLayout from "routes/app/components/MovieMakerLayout";
import ProgressBar from "components/ProgressBar";

let globals;
if(utils.isEditor() && !editorUtils.isSolo){
    setTimeout(()=>{
        init().then((glbs)=>{globals = glbs});
    },[10*1000])
}

const Wait = ()=>{
    return(
        <div className="w-full max-w-[300px] absolute flex flex-col justify-center items-center space-y-4">
            <div className="z-10 w-20 h-20 border-[10px] border-blue-500 border-t-transparent rounded-full animate-spin"/>
            <ProgressBar duration={15}/>
        </div>
    )
}
const ChooseAvatar = () => {
    const stageRef = useRef();
    
    const [clothes, setClothes] = useState(movieArgs.avatar.avatars || 0);
    const [skin, setSkin] = useState(movieArgs.avatar.colors || 0);
    const [hairType, setHairType] = useState(movieArgs.avatar.hair || 1);
    const [hairColor, setHairColor] = useState(movieArgs.avatar.hairColor || 0);
    const [top, setTop] = useState(movieArgs.avatar.tops || 0);
    
    const [wait, setWait] = useState(true);
    const [over, setOver] = useState()
    const navigate = useNavigate();
    const [nextDisabled, setNextDisabled] = useState(true);
    const data = {clothes, skin, hairType, hairColor, top}
    const sets = {
        clothes: setClothes,
        skin: setSkin,
        hair: setHairType,
        hairColor: setHairColor,
        top: setTop
    }

    useEffect(()=>{
        const init = async()=>{
            while(!globals || !globals.images || !stageRef.current) await utils.sleep(1);
            drawStage({stageRef, globals, hairColor, hairType, skin, clothes, top})
            setNextDisabled(false);
            setWait(false);
        }
        init();
    },[]);
    useEffect(()=>{
        movieArgs.avatar.avatars = clothes;
        movieArgs.avatar.colors = skin;
        movieArgs.avatar.hair = hairType;
        movieArgs.avatar.hairColor = hairColor;
        movieArgs.avatar.tops = top;
        //draw stage if mounted already
        if(globals && globals.images && stageRef.current) drawStage({stageRef, globals, hairColor, hairType, skin, clothes, top});
    },[clothes,skin,hairType,hairColor,top])

	const handleBack = () => {
        navigate((movieArgs.invite?'/birthday-party-invitation-maker':'/birthday-movie-maker')+'/add-a-face?view=5')
    }

	return (
		<MovieMakerLayout
            nextDisabled={nextDisabled}
            step={3}
            error="Please wait for the avatars to load."
            handleBack={handleBack}
        >
			<div className="flex flex-col justify-center items-center">
				<Title/>
				<div className="p-5 relative flex flex-col justify-center items-center border border-blue-default bg-white w-full max-w-[500px] rounded-3xl">
                    {over && <Overlay globals={globals} over={over} data={data} sets={sets} setOver={setOver}/>}
                    {wait?
                        <Wait/>:
                        <>
                            <Button globals={globals} main={true} type='hair' number={hairType} data={data} action={()=>{setOver('hair')}}/>
                            <Button globals={globals} main={true} type='top' number={top} data={data} action={()=>{setOver('top')}}/>
                            <Button globals={globals} main={true} type='skin' number={skin} data={data} action={()=>{setOver('skin')}}/>
                            <Button globals={globals} main={true} type='clothes' number={clothes} data={data} action={()=>{setOver('clothes')}}/>
                        </>
                    }
                    <canvas ref={stageRef} width="1000" height="1200" className="w-full" />
				</div>
			</div>
		</MovieMakerLayout>
	)
};

export default ChooseAvatar;
