import SectionTitle from "./SectionTitle";
import Facebook from "assets/webp/social/Facebook.webp";
import Mail from "assets/webp/social/Mail.webp";
import Twitter from "assets/webp/social/Twitter.webp";
import WhatsApp from "assets/webp/social/WhatsApp.webp";
import friends from "assets/webp/steps/friends/friends.webp";
import shape1 from "assets/webp/steps/friends/shape1.webp";
import shape2 from "assets/webp/steps/friends/shape2.webp";
import shape3 from "assets/webp/steps/friends/shape3.webp";

import { MdEmail } from 'react-icons/md'
import { FaFacebook } from 'react-icons/fa';
import { FaWhatsapp } from 'react-icons/fa';
import { FaTwitter } from 'react-icons/fa'

const data = [
	{
		icon: Mail,
		alt: "Mail",
	},
	{
		icon: WhatsApp,
		alt: "WhatsApp",
	},
	{
		icon: Facebook,
		alt: "Facebook",
	},
	{
		icon: Twitter,
		alt: "Twitter",
	},
]

const ShareWithFriends = () => {
	return (
		<section className="relative pt-[120px] md:pt-0">
			<img
				src={shape1}
				alt=""
				className="absolute top-2 left-16 max-w-full hidden lg:block"
			/>
			<img
				src={shape3}
				alt=""
				className="absolute -top-20 left-0 max-w-full lg:hidden"
			/>
			<img
				src={shape2}
				alt=""
				className="absolute right-0 top-0 hidden md:block"
			/>
			<div className="container relative z-10">
				<div className="share-with-friends">
					<SectionTitle
						title="Easily share with friends and family!"
						text="Send it as a birthday party invitation or greeting! Share on Facebook, Whatsapp and Twitter!"
					>
						<div className="flex justify-center gap-6 mt-9 max-sm:pb-6">
                            <MdEmail size={60} />
                            <FaFacebook size={60} />
                            <FaWhatsapp size={60} />
                            <FaTwitter size={60} />
						</div>
					</SectionTitle>
					<img
						src={friends}
						className="w-full rounded-xl xl:rounded-[40px] min-h-[465px] max-w-[1100px] m-auto object-cover"
						alt=""
					/>
				</div>
			</div>
		</section>
	);
};

export default ShareWithFriends;
